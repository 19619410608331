import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import avatarEvent from "assets/images/avatarEvent.png";

class EventText extends Component {

  render() {
    let { item } = this.props;
    let logo = item.event_image_file || avatarEvent;
    return (
      <Link className="list-group-item" key={item.fuzion_event_id} to={`/events/profile/${item.fuzion_event_id}/post-event`}>
        <div className="list-avatar-div">
          <img src={logo} className="list-avatar-image" alt="List Profile" />
        </div>
        <div className="list-info-container">
          <div className="list-info-details">
            <div className="list-title">
              {item.event_name}
            </div>
            <div className="status-div">
              <div>Organization Name: {item.organization_name}</div>
              <div>Event Start Date: {moment.utc(item.event_begin).format("MMM DD, YYYY")}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default EventText;