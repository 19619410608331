import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { notificationMessage } from "fuzion-core-lib";
import saveAs from "file-saver";

import TabList from "components/tabs/tablist";
import TabEventProfiles from "components/tabs/tab_event_profiles";
import TabEventDocuments from "components/tabs/tab_event_documents";
import TabEventSettings from "components/tabs/tab_event_settings";
import EventDocumentAddButton from "./event_document_add_button";
import DocumentList from "components/list/document_list";
import { setNotification } from "actions/notifications";
import { getEventById } from "actions/events";
import {
  getDocuments,
  getDocument,
  downloadDocument,
  saveDocument,
  deleteDocument
} from "actions/documents";

import Header from "components/header/header";
import withAuth from "components/hoc/with_auth";

class EventDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentSaved: false,
      documentDeleted: false,
      documentNoDownloadConfirmation: false
    };
  }

  async componentDidMount() {
    const { fuzion_event_id } = this.props.match.params;
    await this.props.getEventById(fuzion_event_id);
    await this.loadData();
  }

  loadData() {
    let { event } = this.props;
    let eventId = event.fuzion_event_id;
    this.props.getDocuments(0, 1000, eventId); //TODO: Remove old paging params
  }

    downloadDocumentEvent = doc => {
      this.props.downloadDocument(doc, result => {
        if (result.data.size !== 0) {
          saveAs(result.data, doc.document_name); //https://www.npmjs.com/package/file-saver
        }
      });
    }

    onDelete = doc => {
      let { deleteDocument, setNotification} = this.props;
      deleteDocument(doc, () => {
        this.setState({documentDeleted: true});
        this.loadData();
        setNotification(notificationMessage.SUCCESS_DELETE);
      });
    }

    handleNewDocument = e => {
      e.stopPropagation();
      e.preventDefault();
      this.refs.fileUploader.click();
    }

    loadEventDashboard = e => {
	    let { event } = this.props;
     	let eventId = event.fuzion_event_id;
	    this.props.history.push(`/events/profile/${eventId}/pre-event`);
    }

    onChangeFile = e => {
      e.stopPropagation();
      e.preventDefault();
      let { event, user, setNotification } = this.props;
      let file = e.target.files[0];
      let formData = new FormData();
      let fileSizeMB = file.size / (1024*1024);
      formData.append("quant_event_document", file);
      formData.append("content_type", file.type);
      formData.append("document_name", file.name);
      if (fileSizeMB > 10) {
        setNotification(notificationMessage.ERROR_FILE_SIZE_LIMIT);
        return false;
      } else {
        this.props.saveDocument(formData, event.fuzion_event_id, user.quant_user_profile_id, result => {
          this.setState({ documentSaved: true });
          this.loadData();
          setNotification(notificationMessage.SUCCESS_UPDATE);
        });
      }
    }

    render() {
      let { event, documents } = this.props;

      return (
        <div>
          <Header detailView={true} to="/events" />
          <TabList>
            <TabEventProfiles event={event} />
            <TabEventDocuments event={event} isActiveTab="true" />
            <TabEventSettings event={event} />
          </TabList>
          <div className="container-fluid tabs-spacer">
            <h1 className="page-header">Event Documents</h1>
            <div className="sub-header">
              {event.organization_name + ": " + event.event_name}
            </div>
            <form encType="multipart/form-data">
              <EventDocumentAddButton onClick={this.handleNewDocument} />
              <button onClick={this.loadEventDashboard} className="btn btn-default">VIEW EVENT</button>
              <input type="file" name="quant_event_document" id="quant_event_document" style={{ display: "none" }} ref="fileUploader" onChange={this.onChangeFile} />
            </form>
            <div className="body-content-partial">
              <DocumentList
                id="quant_document_id"
                category="Event Document"
                items={documents}
                to={"/events"}
                onClick={this.downloadDocumentEvent}
                listTitle="document_name"
                showAvatar="true"
                dateTitle="Uploaded"
                dateField="last_mod_timestamp"
                onDelete={e => this.onDelete(e)}
              />
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    event: state.event,
    documents: state.documents,
    user: state.activeUser
  };
};

export default connect(mapStateToProps, {
  getEventById,
  getDocuments,
  getDocument,
  downloadDocument,
  saveDocument,
  setNotification,
  deleteDocument
})(withRouter(withAuth(EventDocuments)));
