import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Tippy from "@tippy.js/react";

class ListIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { match, iconImg, iconTo, eventId, tooltip, onClick} = this.props;
    return (
      <Link key={eventId} to={`${match.url}${iconTo}`} className="list-other-items-div" tabIndex="-1">
        <Tippy content={<span id="tooltip-content">{tooltip}</span>}>
          <img src={iconImg} onClick={onClick} className="list-other-items-image" alt="List Item"/>
        </Tippy>
      </Link>
    );
  }
}

const mapStateToProps = state => {
  return {
    match: state.match,
  };
};

export default connect(mapStateToProps, {

})(withRouter(ListIcon));