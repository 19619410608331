import React, { Component } from "react";
import { connect } from "react-redux";
import saveAs from "file-saver";

import DocumentList from "components/list/document_list";
import { setNotification } from "actions/notifications";
import { notificationMessage } from "fuzion-core-lib";
import {
  getDocuments,
  deleteDocument,
  saveDocument,
  downloadDocument
} from "actions/documents";
import EventDocumentAddButton from "components/events/event_document_add_button";
import withAuth from "components/hoc/with_auth";
import Icon from "components/icons/icon";
import DocumentIcon from "assets/images/iconDocumentDark.svg"; 

class DocumentListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { eventId } = this.props;
    this.props.getDocuments(0, 1000, eventId);
  }


    handleNewDocument = e => {
      e.stopPropagation();
      e.preventDefault();
      this.refs.fileUploader.click();
    }

    downloadDocumentEvent = doc => {
      this.props.downloadDocument(doc, result => {
        if (result.data.size !== 0) {
          saveAs(result.data, doc.document_name); //https://www.npmjs.com/package/file-saver
        }
      });
    }

    onChangeFile = e => {
      e.stopPropagation();
      e.preventDefault();
      let { event, user, saveDocument, setNotification } = this.props;
      let file = e.target.files[0];
      let formData = new FormData();
      let fileSizeMB = file.size / (1024*1024);
      formData.append("quant_event_document", file);
      formData.append("content_type", file.type);
      formData.append("document_name", file.name);
      if (fileSizeMB > 10) {
        setNotification(notificationMessage.ERROR_FILE_SIZE_LIMIT);
        return false;
      } else {
        saveDocument(formData, event.fuzion_event_id, user.quant_user_profile_id, result => {
          this.setState({ documentSaved: true });
          this.loadData();
          setNotification(notificationMessage.SUCCESS_ADD);
        });
      }
    }

    onDelete(item) {
      let { deleteDocument, setNotification } = this.props;
      deleteDocument(item, () => {             
        this.setState({ documentDeleted: true });
        this.loadData();              
        setNotification(notificationMessage.SUCCESS_DELETE);
      });
    }

    renderTitle() {
      return (
        <div>
          <h4 className="modal-title">
            {this.props.title}
          </h4>
        </div>
      );
    }

    renderCancelButton() {
      return (
        <button type="button"
          onClick={this.props.onSave}
          name="cancelButton"
          className="btn btn-default pull-right">
                CLOSE
	        </button>
      );
    }

    getDocumentList(label) {
      let { documents } = this.props;
      let documentList = documents.length > 0 ?
        <DocumentList
          id="quant_document_id"
          category="Event Document"
          items={documents}
          onClick={this.downloadDocumentEvent}
          listTitle="document_name"
          showAvatar="true"
          dateTitle="Uploaded"
          dateField="last_mod_timestamp"
          onDelete={item => this.onDelete(item)}
          renderTrashCanDelete="true"
        />
        :
        <div className="event-access-no-content">
          <div>
            <Icon src={DocumentIcon} />
            <div className="event-access-no-content-text">
                        No {label} Added
            </div>
          </div>
        </div>;
      return documentList;
    };

    render() {
      return (
        <div ref={node => {
          this.node = node;
        }} className="main-admin-container">
          {this.renderTitle()}
          <div onSubmit={this.onSubmit} className="modal-form">
            <div className="body-content-partial">
              { this.getDocumentList("Documents") }
            </div>
            <div className="form-group flex-display">
              <form encType="multipart/form-data" className="col-md-12 align-right right-justify-buttons-modal">
                {this.renderCancelButton()}
                <EventDocumentAddButton onClick={this.handleNewDocument} />
                <input type="file" name="quant_event_document" id="quant_event_document" style={{ display: "none" }} ref="fileUploader" onChange={this.onChangeFile} />
              </form>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    event: state.event,
    documents: state.documents,
    user: state.activeUser
  };
};

export default connect(mapStateToProps, {
  getDocuments,
  deleteDocument,
  saveDocument,
  downloadDocument,
  setNotification
})(withAuth(DocumentListForm));