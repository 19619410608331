import types from "types/event_profiles";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_EVENT_PROFILES:
      return action.payload;
    case types.GET_EVENT_PROFILES_FOR_METRICS_VIEW:
      return action.payload;
    default:
      return state;
  }
};
