import React, { useState } from "react";
import { request } from "lib/data/api";
import { useStore } from "react-redux";
import generator from "generate-password";
import { useForm } from "react-hook-form";
import iconCopyPassword from "assets/images/iconCopyPassword.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UserCognitoResults from "./user_cognito_results";
import styles from "./user-settings.module.css";
import UserCognitoSettings from "./user_cognito_settings";

export default function UserCognitoSetPassword(props) {
  let { username } = props;
  const [userPassword, setUserPassword] = useState("");
  const [passwordCopied, setPasswordCopied] = useState(false);

  const [awsCognitoMessage, setAwsCognitoMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [awsCognitoErrorCode, setAwsCognitoErrorCode] = useState("");
  const [awsCognitoErrorMessage, setAwsCognitoErrorMessage] = useState("");
  const [awsCognitoStatusCode, setAwsCognitoStatusCode] = useState("");
  const [awsCognitoError, setAwsCognitoError] = useState(false);

  const store = useStore();

  const { handleSubmit } = useForm({
  });

  async function generateAndSendPassword(generate) {
    console.log(generate);
    const newPassword = generator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      uppercase: true,
      symbols: "^$*.{}()?!@#%&,><':;|_~`[]/",
      strict: true
    });

    const data = {
      username: username,
      password: newPassword
    };
    const response = await request({
      state: store.getState(),
      method: "POST",
      route: "/v1/quant-user-profile/cognitouser/setpassword/",
      data: data
    });
    const payload = response.payload;

    if (payload && payload.error === false) {
      setUserPassword(newPassword);
      setAwsCognitoError(false);
      setAwsCognitoMessage("Password is set.");
      setAwsCognitoStatusCode("");

    } else if (payload) {
      console.log(payload);
      setUserPassword("");
      setAwsCognitoError(true);
      setAwsCognitoMessage("An error has occured");
      setAwsCognitoErrorCode(payload.error_code);
      setAwsCognitoErrorMessage(payload.error_message);
      setAwsCognitoStatusCode(payload.status_code);
    }
    setLoading(false);
  };

  const onSubmit = () => { };

  const handleClick = () => {
    setLoading(true);
    generateAndSendPassword();
  };

  return (
    <div>
      <UserCognitoSettings username={username} setPassword={userPassword}></UserCognitoSettings>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`align-left ${styles.setPasswordContainer}`}>
          <button type="submit"
            className={`btn btn-primary ${styles.setPasswordButton}`}
            disabled={isLoading}
            onClick={!isLoading ? handleClick : null}>
            {isLoading ? "Setting..." : "Set Password"}
          </button>
          <input
            placeholder="Password"
            value={userPassword}
            type="text"
            name="email"
            className={`form-control ${styles.setPasswordInput}`}
            disabled={true}
            readOnly={true}
          />
          <CopyToClipboard text={userPassword}
            onCopy={() => setPasswordCopied(true)}>
            <img src={iconCopyPassword} className={`icon-menu ${styles.iconCopyPassword}`} alt="Sidebar Link Icon" />
          </CopyToClipboard>
          {passwordCopied && (
            <span className={styles.setPasswordCopiedText}>Password is copied.</span>
          )}
        </div>
        <div>
          <UserCognitoResults action="set-password"
            message={awsCognitoMessage}
            error_code={awsCognitoErrorCode}
            error_message={awsCognitoErrorMessage}
            error={awsCognitoError}
            status_code={awsCognitoStatusCode}
          >
          </UserCognitoResults>
        </div>
      </form >
    </div >
  );
}