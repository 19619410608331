import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import renderClassNames from "lib/ui/render_class_names";
import formHelper from "lib/ui/form";
import { required, maxLength50 } from "lib/validation/form_validation_rules";
import InputNonRedux from "components/form/input_non_redux";
import { updateEventProfile, getEventProfiles, saveEventProfile, getEventProfileById } from "actions/event_profiles";

class ProfileSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_name: {
        key: "profile_name",
        value: "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength50], 
      }
    };
  }

  componentDidMount() {
    this.getInitialValue();
  }

  getInitialValue() {
    const { isInsert, eventProfile } = this.props;
    if (!isInsert && eventProfile.profile_name) {
      const newProfileName = Object.assign({}, {...this.state.profile_name, value: eventProfile.profile_name});
      this.setState({profile_name: newProfileName});
    }
  }

    onSubmit = async (e) => {
      e.preventDefault();

      const { fuzion_event_id, quant_event_profile_id } = this.props.match.params;
      const { onClose , updateEventProfile, eventProfile, getEventProfiles, getEventProfileById, isInsert, saveEventProfile, history } = this.props;
      const { profile_name } = this.state;

      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        return this.setState(form.formState);
      }

      const profileNameValue = profile_name.value.replace(/^\s+|\s+$/g, "");
      if (isInsert) {
        const formValues = {};
        formValues.profile_name = profileNameValue;
        formValues.fuzion_event_id = fuzion_event_id;
        formValues.isDefault = false;
        const response = await saveEventProfile(formValues);
        if (response.payload && response.payload.quant_event_profile_id) {
          history.push(`/events/profile/${fuzion_event_id}/post-event/${response.payload.quant_event_profile_id}`);
        } 
      } else {
        if (profileNameValue === eventProfile.profile_name) return onClose();
        const newEventProfile = Object.assign({}, {...eventProfile, profile_name: eventProfile.profile_name});;
        newEventProfile.profile_name = profileNameValue;
        const response = await updateEventProfile(newEventProfile);
        if (response.payload && response.payload.quant_event_profile_id) {
          await getEventProfiles(fuzion_event_id);
          await getEventProfileById(fuzion_event_id, quant_event_profile_id);
        }
      }
      onClose();
    }

    onChange = ({ target: { name, value } }) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onBlur = (e) => {
      let { value, name }  = e.target;
      value = value.replace(/^\s+|\s+$/g, "");
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    renderTitle(isInsert) {
      return (
        <h4 className="modal-title">
          {isInsert ? "Add Profile" : "Profile Settings"}
        </h4>
      );
    }

    getStyle() {
      const profileNameErrStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.profile_name.valid
      });
      return {
        submitStyle: "btn btn-primary",
        otherStyle: "btn btn-default",
        profileNameErrStyle
      };
    }

    renderButtons(isInsert, submitStyle, otherStyle) {
      const { eventProfile, onClose, onOpenDeleteConfirmation } = this.props;
      const showDeleteButton = !eventProfile.is_default;
      return isInsert 
        ? 
        <div className="align-right">
          <button type="button"
            onClick={onClose}
            name="cancelButton"
            className={otherStyle}
          >
                            CANCEL
          </button>
          <button type="buton"
            name="saveEventButton"
            className={submitStyle}
            onClick={this.onSubmit}
          >
                            ADD
          </button>
        </div>
        :
        <div className="align-right">
          {showDeleteButton && <button type="button"
            onClick={onOpenDeleteConfirmation}
            name="cancelButton"
            className={otherStyle}
          >
                            DELETE PROFILE
          </button>}
          <button type="buton"
            name="saveEventButton"
            className={submitStyle}
            onClick={this.onSubmit}
          >
                            DONE
          </button>
        </div>;
            
    }

    render() {
      const style = this.getStyle();
      const { profile_name } = this.state;
      const { isInsert } = this.props;
      return (
        <>
          <div>
            {this.renderTitle(isInsert)}
            <form className="modal-form">
              <div className="form-group">
                <div className="field-label">Profile Name*</div>
                <div>
                  <InputNonRedux
                    name={profile_name.key}
                    value={profile_name.value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    label="Profile name"
                    className={style.profileNameErrStyle}
                  ></InputNonRedux>
                  {!profile_name.valid && (
                    <div className="text-danger">
                      {profile_name.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group flex-display">
                <div className="field-label required-label">
                            * Required
	                    </div>
                {this.renderButtons(isInsert, style.submitStyle, style.otherStyle)}
              </div>
            </form>
          </div>
        </>
      );
    }
}

const mapStateToProps = state => {
  return {
    eventProfile: state.eventProfile,
    eventProfiles: state.eventProfiles
  };
};

export default connect(mapStateToProps, {updateEventProfile, getEventProfiles, saveEventProfile, getEventProfileById})(withRouter(withAuth(ProfileSettingsForm)));