import React, { Component } from "react";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";

import { getPermissions } from "actions/permissions";
import iconDocumentLight from "assets/images/iconDocumentLight.svg";

class DocumentsButton extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

    onClick = () => {
      let { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    }

    render() {
      let { permissions } = this.props;
      if (!permissions || !permissions.canRenderEventProfileDocumentsButton) return null;

      return (
        <Tippy content={<span id="tooltip-content">Documents</span>}>
          <button onClick={this.onClick} className="profileHeaderIcon">
            <img src={iconDocumentLight} alt="Documents" />
          </button>
        </Tippy>
      );
    }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(DocumentsButton);
