import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withAuth from "components/hoc/with_auth";
import renderClassNames from "lib/ui/render_class_names";
import formHelper from "lib/ui/form";
import InputNonRedux from "components/form/input_non_redux";
import TextAreaNonRedux from "components/form/text_area_non_redux";
import { required, maxLength100, maxLength1000 } from "lib/validation/form_validation_rules";
import {updateInsight, addInsight, deleteInsight } from "actions/event_profiles";

class InsightsForm  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insight_header: {
        key: "insight_header",
        value: this.props.insight ? this.props.insight.title : "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength100]
      },
      insight_body: {
        key: "insight_body",
        value: this.props.insight ? this.props.insight.body : "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength1000]
      },            
      created_by: {
        key: "created_by",
        value: this.props.insight ? this.props.insight.created_by : `${this.props.activeUser.first_name} ${this.props.activeUser.last_name}` ,
        valid: true,
        errorMessage: "",
        validation: [required, maxLength100]
      }
    };
  }

    onSubmit = (e, inInsert) => {
      const { activeUser, timeframeLabel, currentInsightId } = this.props;
      e.preventDefault();
      e.stopPropagation();
      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        this.setState(form.formState);
        return;
      }
      const formValues = {};
      formValues.last_mod_timestamp = moment();
      formValues.full_name = `${activeUser.first_name} ${activeUser.last_name}`;
      formValues.timeframe = timeframeLabel;
      formValues.body = form.formState.insight_body.value;
      formValues.title = form.formState.insight_header.value;
      formValues.created_by = form.formState.created_by.value;
      if (inInsert) {
        this.onSaveInsight(formValues, "insert");
      } else {
        formValues.id = currentInsightId;
        this.onSaveInsight(formValues, "update");
      }
    }

    onSaveInsight = (insight, operation) => {
      const { updateInsight, addInsight, eventProfile, timeframeLabel, onClose } = this.props;
      try {
        const formData = {
          fuzion_event_id: eventProfile.fuzion_event_id,
          insight,
          timeframe: timeframeLabel
        };
        switch (operation) {
          case "insert":
            addInsight(eventProfile.quant_event_profile_id, formData);
            break;
          case "update":
            updateInsight(eventProfile.quant_event_profile_id, formData);
            break;
          default:
            throw Error("ERROR: Invalid case at insights_form.onSaveInsight()");
        }
      } catch (e) {
      }
      onClose();
    }

    onDeleteInsight = (insightId) => {
      const { eventProfile, timeframeLabel, deleteInsight, onClose } = this.props;
      const formData = {
        fuzion_event_id: eventProfile.fuzion_event_id,
        insight_id: insightId,
        timeframe: timeframeLabel
      };
      deleteInsight(eventProfile.quant_event_profile_id, formData);
      onClose();
    }

    onChange = (e) => {
      const { target: { name, value } } = e;
      const { valid, errorMessage }  = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, { value, valid, errorMessage }));
    }

    onBlur = (e) => {
      const { target: { name, value } } = e;
      if (!value) return;
      const validation = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, validation));
    }

    renderTitle(isInsert) {
      return (
        <h4 className="modal-title">
          {isInsert ? "Add Insight" : "Edit Insight"}
        </h4>
      );
    }

    getStyle() {
      const insightTitleErrStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.insight_header.valid
      });
      const insightTextErrStyle = renderClassNames({
        "form-control-textarea insert": true,
        "form-control-textarea-error": !this.state.insight_body.valid
      });        
      const createdByStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.created_by.valid
      });
      return {
        submitStyle: "btn btn-primary",
        otherStyle: "btn btn-default",
        insightTitleErrStyle,
        insightTextErrStyle,
        createdByStyle
      };
    }

    render() {
      const style = this.getStyle();
      const { created_by, insight_header, insight_body } = this.state;
      const { operation, onClose, currentInsightId } = this.props;
      const isInsert = operation === "insert";
      return (
        <div>
          {this.renderTitle(isInsert)}
          <form onSubmit={(e) => this.onSubmit(e, isInsert)} className="modal-form">
            <div className="form-group">
              <div className="field-label">Title*</div>
              <div>
                <InputNonRedux
                  name={insight_header.key}
                  value={insight_header.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  label="Insight Title"
                  className={style.insightTitleErrStyle}
                ></InputNonRedux>
                {!insight_header.valid && (
                  <div className="text-danger">
                    {insight_header.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Text*</div>
              <div>
                <TextAreaNonRedux
                  name={insight_body.key}
                  value={insight_body.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  label="Insight Text"
                  className={style.insightTextErrStyle}
                ></TextAreaNonRedux>
                {!insight_body.valid && (
                  <div className="text-danger">
                    {insight_body.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Created By*</div>
              <div>
                <InputNonRedux
                  name={created_by.key}
                  value={created_by.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  label="Created By"
                  className={style.createdByStyle}
                ></InputNonRedux>
                {!created_by.valid && (
                  <div className="text-danger">
                    {created_by.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group flex-display">
              <div className="field-label required-label">
                            * Required
	                    </div>
              <div className="align-right">
                <button type="button"
                  onClick={onClose}
                  name="cancelButton"
                  className={style.otherStyle}
                >
                                CANCEL
	                        </button>
                { operation === "update" &&
                            <button type="button"
                              onClick={() =>  this.onDeleteInsight(currentInsightId, "delete")}
                              name="cancelButton"
                              className={style.otherStyle}
                            >
                                DELETE INSIGHT
	                        </button>
                }
                <button type="submit"
                  name="saveEventButton"
                  className={style.submitStyle}
                >
                  {isInsert ? "ADD" : "SAVE"}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    activeUser: state.activeUser,
    timeframeLabel: state.eventDashboard.timeframeLabel,
    eventProfile: state.eventProfile
  };
};

export default connect(mapStateToProps, {updateInsight, addInsight, deleteInsight})(withAuth(InsightsForm));