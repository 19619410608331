import React, { useState } from "react";
import styles from "./tabs.module.css";
import BulkUpload from "./bulk_upload";


export default function UserImportTabs(props) {
  const types = {
    ADD_USER: "Add Viewer to Event",
    BULK_UPLOAD: "Import Viewers to Event"
  };

  const [active, setActive] = useState(types.ADD_USER);
  const activeStyle = `${styles.tab} ${styles.profileAccessTabActive}`;

  return (
    <>
      <div className={styles.tabContainer}>
        <div className={active === types.ADD_USER ? activeStyle : styles.tab} onClick={() => {
          setActive(types.ADD_USER);
        }}>
          {types.ADD_USER}
        </div>
        <div className={active === types.BULK_UPLOAD ? activeStyle : styles.tab} onClick={() => {
          setActive(types.BULK_UPLOAD);
        }}>
          {types.BULK_UPLOAD}
        </div>
      </div>

      <div>
        {active === types.ADD_USER ? props.add_user : <BulkUpload onClose={props.onClose} roles={props.roles} />}
      </div>
    </>
  );
};
