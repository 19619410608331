import { GET_DOCUMENTS } from "actions/documents";

export default (state = [], action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      let { payload } = action.payload.data;
      return payload;
    default:
      return state;
  }
};
