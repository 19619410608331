import React, { Component } from "react";
import withTheme from "./hoc/with_theme";
import iconLoadingLight from "assets/images/iconLoadingLight.svg";
import iconLoadingDark from "assets/images/iconLoadingDark.svg";

class Loading extends Component {
  render() {
    let { theme } = this.props;
    let image = theme.title === theme.LIGHT ? iconLoadingDark : iconLoadingLight;
    return (
      <div className="loadingContainer">
        <img className="loadingIcon" src={image} alt="Loading Icon" />
      </div>
    );
  }
}


export default withTheme(Loading);