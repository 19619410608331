import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";

import DefaultProfileSelectionForm from "./default_profile_selection_form";
import { getPermissions } from "actions/permissions";
import styles from "./user_settings_form.module.css";

export class UserSettingsForm extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

    onDone = async () => {
      let {
        invalid,
        onSave,
      } = this.props;
      if (invalid) return;
      onSave();
    }

    render() {
      let { handleSubmit } = this.props;
      return (
        <form onSubmit={handleSubmit(this.onDone)}>
          <div>
            <h4 className={`modal-title ${styles.title}`}> 
                        User Settings
            </h4>
          </div>
          <DefaultProfileSelectionForm eventId={this.props.eventId} />
          <div className="right-justify-buttons-modal">
            <button type="submit"
              className="btn btn-primary pull-right">
                            DONE
            </button>
          </div>
        </form>            
      );
    }
}

const mapStateToProps = state => {
  return {
    eventProfiles: state.eventProfiles,
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions
})(reduxForm({
  form: "UserSettingsForm",
  enableReinitialize: true,
})(withRouter(UserSettingsForm)));
