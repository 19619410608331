import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import renderClassNames from "lib/ui/render_class_names";
import formHelper from "lib/ui/form";
import InputNonRedux from "components/form/input_non_redux";
import { updateEventProfile } from "actions/event_profiles";
import { getTimeframeDashboard } from "lib/util/dashboard";

const validatePowerBIURL = (value) => {
  if (!value) return;
  else {
    return /^https:\/\/app.powerbi.com\//g.test(value) ? null : "Invalid Power BI URL";
  }
};

class DashboardSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: {
        key: "url",
        value: "",
        valid: true,
        errorMessage: "",
        validation: [validatePowerBIURL]
      },
      workspace_id: {
        key: "workspace_id",
        value: "",
        valid: true,
        errorMessage: "",
      },
      title: ""
    };
  }

  async componentDidMount() {
    this.getInitialValues();
    this.getTitle();
  }

  getInitialValues() {
    const { eventProfile, timeframeLabel } = this.props;
    const dashboard = getTimeframeDashboard(eventProfile, timeframeLabel);
    let urlValue = "", workspaceIdValue = "";
    if (dashboard) {
      urlValue = dashboard.url || "";
      workspaceIdValue = dashboard.workspace_id || "";
    } else {
      return;
    }
    const { url, workspace_id } = this.state;
    let state = formHelper.mergeNewState(this.state, url.key, { value: urlValue });
    state = formHelper.mergeNewState(state, workspace_id.key, { value: workspaceIdValue });
    this.setState(state);
  }

  getTitle() {
    this.setState({ title: "Dashboard Settings" });
  }

    onSubmit = (e) => {
      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        this.setState(form.formState);
        return;
      }
        
      this.setState(form.formState, async () => {
        const { timeframeLabel , eventProfile } = this.props;
        let formValues = formHelper.getFormValues(this.state);
        const {powerBIUrl, reportId} = this.parsePowerBIUrl(formValues.url);
        const key = `${timeframeLabel}_dashboard`;
        formValues = {
          ...eventProfile,
          [key]: {...formValues, url: powerBIUrl, report_id: reportId}
        };
        await this.props.updateEventProfile(formValues);
        this.props.onClose();
      });
    }

    parsePowerBIUrl(url) {
      if (!url) return "";
      const tempURL = new URL(url);
      tempURL.searchParams.delete("autoAuth");
      return {
        powerBIUrl: tempURL.toString(),
        reportId: tempURL.searchParams.get("reportId")
      };
    }

    onChange = ({ target: { name, value } }) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onURLChange = ({ target: { name, value } }) => {
      const urlValue = value.replace(/\s+/g, "") || "";
      this.setState(formHelper.mergeNewState(this.state, name, { value: urlValue }));
    }

    getStyle() {
      const urlClassess = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.url.valid
      });
      const workspaceIdClassess = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.workspace_id.valid
      });
      return {
        submitStyle: "btn btn-primary",
        urlClassess,
        workspaceIdClassess
      };
    }

    render() {
      const style = this.getStyle();
      const { url, workspace_id } = this.state;
      return (
        <div>
          <h4 className="modal-title">
            {this.state.title}
          </h4>
          <div className="modal-form">
            <div className="form-group">
              <div className="field-label">Embed Url</div>
              <div>
                <InputNonRedux
                  name={url.key}
                  value={url.value}
                  onChange={this.onURLChange}
                  label="Embed Url"
                  className={style.urlClassess} />
                {!url.valid && (
                  <div className="text-danger">
                    {url.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Workspace Id</div>
              <div>
                <InputNonRedux
                  name={workspace_id.key}
                  value={workspace_id.value}
                  onChange={this.onChange}
                  label="Workspace Id"
                  className={style.workspaceIdClassess} />
                {!workspace_id.valid && (
                  <div className="text-danger">
                    {workspace_id.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="align-right">
                <button type="button"
                  name="saveEventButton"
                  onClick={this.onSubmit}
                  className={style.submitStyle}
                >
                                DONE
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    timeframeLabel: state.eventDashboard.timeframeLabel,
    eventProfile: state.eventProfile
  };
};

export default connect(mapStateToProps, { updateEventProfile })(withRouter(withAuth(DashboardSettingsForm)));