import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from "react-responsive-modal";
import { notificationMessage } from "fuzion-core-lib";

import {
  getEventProfiles,
  deleteEventProfile,
  getDefaultEventProfile,
  getEventProfileById
} from "actions/event_profiles";
import { changeEventDashboardStatus } from "actions/event_dashboard";
import iconSettings from "assets/images/iconSettingsDark.svg";
import iconAddCircle from "assets/images/iconAddCircleDark.svg";
import ProfileSettingsForm from "./profile_settings_form";
import ProfileDeleteConfirmation from "./profile_delete_confirmation";
import { setNotification } from "actions/notifications";

import "components/profile/profile.css";

class ProfileTopBarProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openProfileSettingsModal: false,
      profileSettingsModalIsInsert: false,
      deleteConfirmationVisible: false
    };
  }

    onCloseModal = () => {
      this.setState({ openProfileSettingsModal: false });
      const {fuzion_event_id, getEventProfiles } = this.props;
      getEventProfiles(fuzion_event_id);
    }

    onOpenModal(isInsert) {
      this.setState({ openProfileSettingsModal: true, profileSettingsModalIsInsert: isInsert });
    }

    renderSettings() {
      return (
        <div className="profile-header-img-edit-container">
          <img src={iconSettings} onClick={() => this.onOpenModal(false)} alt="icon settings" className="profile-header-edit-img" />
        </div>
      );
    }

    async onProfileChange(profile) {
      await this.props.getEventProfileById(profile.fuzion_event_id, profile.quant_event_profile_id);
      this.props.history.replace(`/events/profile/${profile.fuzion_event_id}/post-event/${profile.quant_event_profile_id}`);
    }



    renderEventProfileLinks(isAdmin) {
      let { eventProfiles } = this.props;
      let links = [];
      let profileClass = "profile-link";
      for (const profile of eventProfiles) {
        let isActive = profile.quant_event_profile_id === this.props.quant_event_profile_id;
        if (isActive) {
          profileClass = "profile-link-active";
        } else {
          profileClass = "profile-link";
        }
        links.push(
          <div key={profile.quant_event_profile_id} className="profile-link-container">
            <span className={profileClass}
              onClick={() => this.onProfileChange(profile)}
            >
              {profile.profile_name}
            </span>
            {isActive && isAdmin && !profile.is_default && this.renderSettings()}
          </div>
        );
      }
      return links;
    }

    onOpenDeleteConfirmation = () => {
      this.setState({ deleteConfirmationVisible: true, openProfileSettingsModal: false });
    }

    onCloseDeleteConfirmation = () => {
      this.setState({ deleteConfirmationVisible: false, openProfileSettingsModal: true });
    }

    onDeleteProfile = async () => {
      const { quant_event_profile_id, fuzion_event_id } = this.props;
      const formValues = {
        quant_event_profile_id,
        fuzion_event_id
      };
      await this.props.deleteEventProfile(formValues);
      await Promise.all([this.props.getEventProfiles(fuzion_event_id), this.props.getDefaultEventProfile(fuzion_event_id)]);
      this.props.updateRoute();
      this.setState({ deleteConfirmationVisible: false, openProfileSettingsModal: false });
      this.props.setNotification(notificationMessage.SUCCESS_DELETE);
    }

    onCloseConfirmationModal() {
      this.setState({ deleteConfirmationVisible: false });
    }

    renderAddProfileIcon(isAdmin) {
      if (!isAdmin) return;
      return (
        <div className="profile-header-img-add-container">
          <img src={iconAddCircle} onClick={() => this.onOpenModal(true)} alt="icon settings" className="profile-header-add-img" />
        </div>
      );
    }

    render() {
      let { permissions } = this.props;
      const isAdmin = permissions.isAdmin;
      return (
        <>
          <div className="profile-sub-text">
            <span className="profile-selection-links">Profiles: {this.renderEventProfileLinks(isAdmin)} {this.renderAddProfileIcon(isAdmin)}</span>
          </div>
          <Modal open={this.state.openProfileSettingsModal} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
            <ProfileSettingsForm onClose={this.onCloseModal} onOpenDeleteConfirmation={this.onOpenDeleteConfirmation} isInsert={this.state.profileSettingsModalIsInsert} />
          </Modal>
          <Modal open={this.state.deleteConfirmationVisible} onClose={this.onCloseDeleteConfirmation} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
            <ProfileDeleteConfirmation onClose={this.onCloseDeleteConfirmation} onDelete={this.onDeleteProfile} />
          </Modal>
        </>
      );
    }
}

const mapStateToProps = state => {
  const { quant_event_profile_id, fuzion_event_id } = state.eventProfile;
  return {
    eventProfiles: state.eventProfiles,
    permissions: state.permissions,
    quant_event_profile_id,
    fuzion_event_id
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateRoute: () => {
      ownProps.history.replace(`/events/profile/${stateProps.fuzion_event_id}/post-event/${stateProps.quant_event_profile_id}`);
    }
  };
};

export default withRouter(connect(mapStateToProps, {
  getEventProfiles,
  changeEventDashboardStatus,
  deleteEventProfile,
  getDefaultEventProfile,
  getEventProfileById,
  setNotification
},
mergeProps
)(ProfileTopBarProfileList));