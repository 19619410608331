import { request } from "lib/data/api";
import groupBy from "lib/util/group_by";
import global from "types/global";
import types from "types/events";

const sortEvents = events => {
  let sortedEvents = events.sort((a, b) => {
    if (a.organization_name === b.organization_name) {
      let d1 = new Date(a.event_begin);
      let d2 = new Date(b.event_begin);
      return d2.getTime() - d1.getTime();
    } else {
      return events;
    }
  });
  return sortedEvents;
};

export const filterEvents = (filter_string, events) => {
  let filter_string_lower= filter_string.toLowerCase();   
  let allevents = [], filteredEvents = [];
    
  if (!filter_string_lower) return filteredEvents;
    
  let orgs = Object.getOwnPropertyNames(events);

  orgs.forEach(org => {
    events[org].forEach(event => allevents.push(event));
  });

  filteredEvents = allevents.filter(event =>
    (event.event_name &&
            event.event_name.toLowerCase().includes(filter_string_lower)) ||
            (event.organization_name && 
                event.organization_name.toLowerCase().includes(filter_string_lower))
  );        

  return filteredEvents ? groupBy(filteredEvents, item => item.organization_name) : filteredEvents;
};

export const getEvents = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: "/v1/quant-event/admin"
      });
      let sortedEvents = sortEvents(response.payload);
      let groupedEvents = groupBy(sortedEvents, item => item.organization_name);
      dispatch({
        type: types.GET_EVENTS,
        payload: groupedEvents
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getAllEvents failed"
      });
    }
  };
};

export const getEventById = eventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event/${eventId}`
      });
      return dispatch({
        type: types.GET_EVENT,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getEventById failed"
      });
    }
  };
};

export const getEventsByUserProfile = quant_user_profile => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event/admin/${quant_user_profile}`,
      });
      let sortedEvents = sortEvents(response.payload);
      let groupedEvents = groupBy(sortedEvents, item => item.organization_name);
      dispatch({
        type: types.GET_EVENTS_BY_USER_PROFILE,
        payload: groupedEvents
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getAllEvents failed"
      });
    }
  };
};

export const getEvent = event => { //TODO: ???
  return {
    type: types.GET_EVENT,
    payload: event
  };
};

export const updateEvent = event => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "PUT",
        route: `/v1/quant-event/${event.fuzion_event_id}`,
        data: event
      });
      dispatch({
        type: types.UPDATE_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.updateEvent failed" //TODO: Expand error message
      });
    }
  };
};

export const deleteEvent = fuzion_event_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "DELETE",
        route: `/v1/quant-event/${fuzion_event_id}`
      });
      dispatch({
        type: types.DELETE_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.deleteEvent failed" //TODO: Expand error message
      });
    }
  };
};