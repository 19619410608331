import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withTheme from "components/hoc/with_theme";
import DashboardEmptyState from "./empty_state/dashboard_empty_state";
import FreemanLogoIcon from "components/icons/freeman_logo_icon";
import InsightsMenu from "components/insights/insights_menu";
import PowerBIReport from "components/power_bi/report";
import "./event_dashboard.css";
import { setSideBarVisibility } from "actions/sidebar";
import { getEmbedToken } from "actions/power_bi";
import { getTimeframeDashboard } from "lib/util/dashboard";


class EventDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: {},
      embedToken: null
    };
  }

  async componentDidMount() {
    this.props.setSideBarVisibility(false);
    await this.fetchEmbedToken();
  }

  async componentDidUpdate() {
    const { eventProfile = {}, timeframeLabel } = this.props;
    const { dashboard } = this.state;
    const currDashboard = getTimeframeDashboard(eventProfile, timeframeLabel);
    if (!currDashboard && this.state.dashboard && this.state.dashboard.url) return this.setState({ dashboard: {}, embedToken: null });
    if (currDashboard && (currDashboard.url !== dashboard.url || currDashboard.report_id !== dashboard.report_id || currDashboard.workspace_id !== dashboard.workspace_id)) {
      this.setState({ dashboard: currDashboard }, () => this.fetchEmbedToken());
    }
  }

  async fetchEmbedToken() {
    const { dashboard } = this.state;
    if (dashboard && dashboard.url && dashboard.report_id && dashboard.workspace_id) {
      const newEmbedToken = await this.props.getEmbedToken(dashboard.report_id, dashboard.workspace_id);
      this.setState({ embedToken: newEmbedToken });
    }
  }

  renderDashboardContainer() {
    const { dashboard, embedToken } = this.state;
    const { email } = this.props.user;
    if (embedToken && dashboard && dashboard.url && dashboard.report_id && dashboard.workspace_id) {
      return <PowerBIReport embedToken={embedToken} embedUrl={dashboard.url} reportId={dashboard.report_id} email={email} />;
    } else {
      return <DashboardEmptyState />;
    }
  }

  renderInsightsMenu() {
    const { user } = this.props;
    const insightsVisible = user.insights_menu_visible === null ? false : user.insights_menu_visible;
    if (!insightsVisible) return;
    return <InsightsMenu />;
  }

  render() {
    const { theme } = this.props;
    return (
      <div className="event-dashboard-profile-container">
        <div className="dashboard-container">
          {this.renderDashboardContainer()}
          {this.renderInsightsMenu()}
        </div>
        <div className="metric-grid-footer" style={theme.borderTopColor}>
          <FreemanLogoIcon theme={theme.iconColor} className="metric-grid-footer-logo-icon" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    timeframeLabel: state.eventDashboard.timeframeLabel,
    eventProfile: state.eventProfile,
    user: state.activeUser
  };
};
export default connect(mapStateToProps,
  {
    setSideBarVisibility,
    getEmbedToken
  })(withTheme(withRouter(EventDashboard)));