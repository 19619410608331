import React, { Component } from "react";
import * as pbi from "powerbi-client";

import "./report.css";

const powerBIModels = pbi.models;
const powerBI = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);


class PowerBIReport extends Component {
  constructor(props) {
    super(props);
    this.reportElement = React.createRef();
  }

  componentDidMount() {
    this.renderPowerBIEmbed();
  }

  renderPowerBIEmbed() {
    const { embedToken, embedUrl, reportId, email } = this.props;
    if (!embedUrl) return;
    const  filter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "user_lookup",
        column: "user_email"
      },
      operator: "In",
      values: [email] 
    };

    //WARNING: SOMEONE FAMILIAR WITH JS CAN CHANGE THIS CONFIG AND SEE ALL DATA VERY EASILY.
    powerBI.embed(this.reportElement.current, {
      type: "report",
      tokenType: powerBIModels.TokenType.Embed,
      accessToken: embedToken,
      embedUrl: embedUrl,
      id: reportId,
      permissions: powerBIModels.Permissions.Read,
      viewMode: powerBIModels.ViewMode.View,
      filters:[filter],
      settings: {
        navContentPaneEnabled: false,
        filterPaneEnabled: false
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.embedToken !== this.props.embedToken) {
      this.renderPowerBIEmbed();
    }
  }

  render() {
    return (
      <div className="report-content" ref={this.reportElement}></div>
    );
  }
}

export default PowerBIReport;