import types from "types/events";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_EVENT:
      return action.payload;
    case types.UPDATE_EVENT:
      return action.payload;
    default:
      return state;
  }
};
