import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { Route } from "react-router-dom";

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.trackPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const hasPathNameChanged = pathname !== prevLocation.pathname;
    const hasSearchChanged = search !== prevLocation.search;

    if (hasPathNameChanged || hasSearchChanged) {
      this.trackPageChange(pathname, search);
    }
  }

  trackPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });
    ReactGA.send({ hitType: "pageview", page: page });
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () =>
  <Route component={GoogleAnalytics} />;

const init = () => {
  const gaMeasurementID = process.env.REACT_APP_GA_MEASUREMENT_ID || false;
  const debug = process.env.REACT_APP_GA_DEBUG_MODE === "false" ? false : true;
  if (gaMeasurementID) {
    ReactGA.initialize([
      {
        trackingId: gaMeasurementID,
        gaOptions: { debug: debug },
      }
    ]);
  }

  return gaMeasurementID;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init
};