import types from "types/insights";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_INSIGHT:
      return action.payload;
    case types.UPDATE_INSIGHT:
      return {...state, ...action.payload};
    default:
      return state;
  }
};
