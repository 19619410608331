import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import TabList from "components/tabs/tablist";
import Tab from "components/tabs/tab";
import UserSettingsForm from "./user_settings_form";
import Header from "components/header/header";
import avatarUser from "assets/images/avatarUser.png";
import { getUserById } from "actions/users";
import { getPermissions } from "actions/permissions";
import UserCognitoSetPassword from "./user_cognito_set_password";
import UserCognitoResendTempPassword from "./user_cognito_send_temp_password";
import styles from "./user-settings.module.css";

class UserSettings extends Component {

  componentDidMount() {
    const { quant_user_profile_id } = this.props.match.params;
    this.props.getUserById(quant_user_profile_id);
    this.props.getPermissions();
  }

  render() {
    let { initialValues } = this.props;
    return (
      <Fragment>
        <Header detailView={true} to="/settings/users" />
        <TabList>
          <Tab activeTab={true} title="USER SETTINGS" to={`/settings/users/user-settings/${initialValues.quant_user_profile_id}`} />
          <Tab title="USER EVENTS" to={`/settings/users/user-events/${initialValues.quant_user_profile_id}`} />
        </TabList>
        <div className="container-fluid tabs-spacer">
          <h1 className="page-header">User Settings</h1>
          <div>User: {initialValues.first_name + " " + initialValues.last_name}</div>
          <div className={`"body-content-partial ${styles.userSettingsMainContainer}`}>
            <div className="user-avatar-div"><img src={avatarUser} className="user-avatar" alt="User Profile" /></div>
            <div className={styles.userSettingsFormOuterContainer}>
              <UserSettingsForm currentUser={initialValues} operation="update" />
            </div>
          </div>
        </div>
        <div className={`container-fluid tabs-spacer ${styles.passwordSettingsContainer}`}>
          <h3 className="page-header">User Password Settings</h3>
          <div className={`body-content-partial ${styles.userPasswordSettingsMainContainer}`}>
            <UserCognitoSetPassword username={initialValues.email} state={this.state}></UserCognitoSetPassword>
            <UserCognitoResendTempPassword username={initialValues.email} state={this.state}></UserCognitoResendTempPassword>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.user
  };
};

export default connect(mapStateToProps, {
  getUserById,
  getPermissions
})(withRouter(withAuth(UserSettings)));