import axios from "axios";
import { request } from "lib/data/api";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const SAVE_USER = "SAVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS_ASSOCIATED_WITH_EVENT_PROFILE_ID = "GET_USERS_ASSOCIATED_WITH_EVENT_PROFILE_ID";
export const REMOVE_USER_FROM_EVENT_PROFILE = "REMOVE_USER_FROM_EVENT_PROFILE";

export const getUsers = () => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile`,
      headers: headers
    });
    dispatch({
      type: GET_USERS,
      payload: request
    });
  };
};

export const getAllUsersAssociatedWithEventProfileId = quant_event_profile_id => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile/quant-event-profile-id/${quant_event_profile_id}`,
      headers: headers
    });
    dispatch({
      type: GET_USERS_ASSOCIATED_WITH_EVENT_PROFILE_ID,
      payload: request
    });
  };
};

export const removeUserFromEventProfile = (data, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile/quant-event-profile-id/${data.quant_event_profile_id}`,
      data: data.user,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
      }
    })
      .then(response => {
        dispatch({
          type: REMOVE_USER_FROM_EVENT_PROFILE,
          payload: response
        });
        callback(response);
      });
  };
};

export const getUser = user => {
  return {
    type: GET_USER,
    payload: user
  };
};

export const getUserById = quant_user_profile_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-user-profile/${quant_user_profile_id}`
      });
      dispatch({
        type: GET_USER,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.getUserById failed"
      });
    }
  };
};

export const saveUser = (user) => {
  return async (dispatch, getState) => {
    const request = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile`,
      data: user,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
      }
    });
    return dispatch({
      type: SAVE_USER,
      payload: request
    });
  };
};

export const updateUser = (user, callback) => {
  return async (dispatch, getState) => {
    const request = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile/${user.quant_user_profile_id}`,
      data: user,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
      }
    });
    dispatch({
      type: UPDATE_USER,
      payload: request
    });
    callback(request);
  };
};

export const deleteUser = (user, callback) => {
  return (dispatch, getState) => {
    const request = axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-user-profile/${user.quant_user_profile_id}`,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
      },
      data: user
    }).then(() => callback());
    return dispatch({
      type: DELETE_USER,
      payload: request
    });
  };
};
