import React, { useState } from "react";
import { request } from "lib/data/api";
import { useStore } from "react-redux";
import { useForm } from "react-hook-form";
import UserCognitoResults from "./user_cognito_results";
import styles from "./user-settings.module.css";
import cognitoErrors from "./utils/cognito_errors";

export default function UserCognitoSendTempPassword(props) {
  let { username } = props;
  const [awsCognitoMessage, setAwsCognitoMessage] = useState("");
  const [awsCognitoErrorCode, setAwsCognitoErrorCode] = useState("");
  const [awsCognitoErrorDescription, setAwsCognitoErrorDescription] = useState("");
  const [awsCognitoErrorMessage, setAwsCognitoErrorMessage] = useState("");
  const [awsCognitoStatusCode, setAwsCognitoStatusCode] = useState("");
  const [awsCognitoError, setAwsCognitoError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const store = useStore();

  const { handleSubmit } = useForm({
  });

  async function resendPassword() {
    const response = await request({
      state: store.getState(),
      method: "POST",
      route: `/v1/quant-user-profile/cognitouser/resendemail/${username}`,
    });
    const payload = response.payload;
    if (payload && payload.error === false) {
      console.log(payload);
      setAwsCognitoError(false);
      setAwsCognitoStatusCode("");
      setAwsCognitoMessage("Temporary password has been sent.");
      setAwsCognitoErrorDescription("");
    } else {
      console.log(payload);
      setAwsCognitoError(true);
      setAwsCognitoErrorCode(payload.error_code);
      setAwsCognitoErrorMessage(payload.error_message);
      setAwsCognitoStatusCode(payload.status_code);
      setAwsCognitoMessage("An error has occurred");
      const desc = cognitoErrors[payload.error_code] || "";
      setAwsCognitoErrorDescription(desc);
    }
    setLoading(false);
  }

  const onSubmit = () => { };

  const handleClick = () => {
    setLoading(true);
    resendPassword();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`align-left ${styles.sendTempPasswordContainer}`}>
        <button type="submit"
          className={`btn btn-primary ${styles.setPasswordButton}`}
          disabled={isLoading}
          onClick={!isLoading ? handleClick : null}>
          {isLoading ? "Resending..." : "Resend Temporary Password"}
        </button>
        <UserCognitoResults
          action="resend-temp-password"
          message={awsCognitoMessage}
          error_code={awsCognitoErrorCode}
          error_message={awsCognitoErrorMessage}
          error={awsCognitoError}
          status_code={awsCognitoStatusCode}
          error_description={awsCognitoErrorDescription}
        >
        </UserCognitoResults>
      </div >
    </form >
  );
}