import React, { Component } from "react";
import { connect } from "react-redux";
import Tab from "./tab";
import { getPermissions } from "actions/permissions";

class TabEventSettings extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  render() {
    let { permissions } = this.props;
    if (!permissions || !permissions.canRenderEventSettingsTab) return null;

	    return <Tab activeTab={this.props.isActiveTab} title="SETTINGS" to={`/events/event-settings/${this.props.event.fuzion_event_id}`} />;
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(TabEventSettings);