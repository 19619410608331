import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import { getPermissions } from "actions/permissions";
import avatar from "assets/images/avatarUser.png";
import Cognito from "../../lib/auth/cognito";

import "./account.css";

export class Account extends Component {

  async componentDidMount() {
    await this.props.getPermissions();
  }
    

    logout = e => {
      e.preventDefault();
      Cognito().logout();
    }

    removeEmail = () => {
      const { user } = this.props;
      const subject = "Request to be Forgotten";
      const body = `I, ${user.first_name} ${user.last_name}, am requesting to be forgotten from Quant and would like all of my Personal Identifiable Information removed.`;
      const href = `mailto:${user.email}?subject=${subject}&body=${body}`;
      return href;
    }

    render() {
      const { user } = this.props;

      if (!user) {
        return (<div>fetching active user details...</div>);
      }

      const formatedDate = moment.utc(user.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss").local().format("MMMM Do, h:mm A");

      return (
        <div className="main-admin-container">
          <Header />
          <div className="container-fluid">
            <h1 className="page-header" style={{ marginBottom: "40px" }}>My Account</h1>
            <div className="user-avatar-div">
              <img src={avatar} alt="user avatar" />
            </div>
            <h4 className="modal-title" style={{ marginBottom: "1px" }}>{user.first_name} {user.last_name}</h4>
            <div className="status-div">
              {user.email}
            </div>
            <div className="status-div">
                        Last Updated: {formatedDate}
            </div>
            <div className="formEditButtonContainer">
              <a className ="btn btn-primary-no-margin-left" 
                style={{paddingTop: "8px"}}
                href={process.env.REACT_APP_REDIRECT_URI} //disabled with move to aws cognito
                name="saveUserButton">
                            MY ACCOUNT
              </a>                       
              <button type="button"
                name="logoutButton"
                onClick={this.logout}
                className="btn btn-default">
                            LOGOUT
	                    </button>
            </div>
            <a href={this.removeEmail()} className="formEditTextAnchorLink">
                        Request to be Forgotten
	                </a>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    user: state.activeUser,
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(withAuth(Account));