import React from "react";

export default function DeleteProfileConfirmation(props) {
  return (
    <div>
      <h4 className="modal-title">Delete Profile?</h4>
      <div>Any user access to this profile will be removed. Are you sure you want to delete this profile?</div>
      <div className="flex-display">
        <div className="modal-right-btns">
          <button
            onClick={props.onClose}
            type="button"
            name="cancelRemoveEventBtn"
            style={{ marginTop: "7px" }}
            className="btn btn-default">
                            CANCEL
          </button>
          <button
            type="button"
            onClick={props.onDelete}
            name="removeEventButton"
            className="btn btn-alert pull-right">
                            DELETE
          </button>
        </div>
      </div>
    </div>
  );
}