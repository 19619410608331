import React, { Component } from "react";

class InputNonRedux extends Component {
  onChange = e => {
    this.props.onChange(e);
  };

  render() {
    let { disabled, label, name, value, className, onBlur } = this.props;
    return (
      <input
        name={name}
        value={value}
        onChange={this.onChange}
        onBlur={onBlur}
        type="text"
        placeholder={label}
        className={className}
        disabled={disabled}
      />
    );
  }
}

export default InputNonRedux;