import React from "react";
import Tippy from "@tippy.js/react";

export default function MenuButton(props) {
  return (
    <Tippy content={<span id="tooltip-content">Menu</span>}>
      <button type="button"
        onClick={props.onClick}
        className="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#navbar"
        aria-expanded="false"
        aria-controls="navbar">
        {/* THIS PROBABLY WON'T WORK IN BOOTSTRAP 4! */}
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
    </Tippy>
  );
}