export default {
  EDITOR: {
    title: "Event Editors",
    info: "Editors are allowed to edit profiles and viewers for this event.",
    type: "editor",
    role: "QUANT_EDITOR"
  },
  VIEWER: {
    title: "Event Viewers",
    info: "Viewers are only allowed to see those Profiles they are assigned to.",
    type: "viewer",
    role: "QUANT_USER"
  }
};