const cognitoErrors = {
  CodeDeliveryFailureException: "This exception is thrown when a verification code fails to deliver successfully.",
  InternalErrorException: "This exception is thrown when Amazon Cognito encounters an internal error.",
  InvalidEmailRoleAccessPolicyException: "This exception is thrown when Amazon Cognito is not allowed to use your email identity.",
  InvalidLambdaResponseException: "This exception is thrown when the Amazon Cognito service encounters an invalid AWS Lambda response.",
  InvalidSmsRoleAccessPolicyException: "This exception is returned when the role provided for SMS configuration does not have permission to publish using Amazon SNS.",
  InvalidSmsRoleTrustRelationshipException: "This exception is thrown when the trust relationship is invalid for the role provided for SMS configuration. This can happen if you do not trust cognito-idp.amazonaws.com or the external ID provided in the role does not match what is provided in the SMS configuration for the user pool.",
  NotAuthorizedException: "This exception is thrown when a user is not authorized.",
  LimitExceededException: "This exception is thrown when a user exceeds the limit for a requested AWS resource.",
  ResourceNotFoundException: "This exception is thrown when the Amazon Cognito service cannot find the requested resource.",
  TooManyRequestsException: "This exception is thrown when the user has made too many requests for a given operation.",
  UnexpectedLambdaException: "This exception is thrown when the Amazon Cognito service encounters an unexpected exception with the AWS Lambda service.",
  UserLambdaValidationException: "This exception is thrown when the Amazon Cognito service encounters a user validation exception with the AWS Lambda service.",
  UserNotFoundException: "This exception is thrown when a user is not found."
};

module.exports = cognitoErrors;
