export const SET_SELECTED_METRIC = "SET_SELECTED_METRIC";

export const setSelectedMetric = metric => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_METRIC,
      payload: metric
    });
  };
};
