import React, { Component } from "react";
import Modal from "react-responsive-modal";

import "./confirmation_modal.css";

const CONFIRMATION_DEFAULTS = {
  open: false,
  header: "Comfirming something?",
  text: "You are about to change something!",
  leftLabel: null,
  rightLabel: null,
};

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...CONFIRMATION_DEFAULTS, 
      ...props
    };
  };

	leftClick = () => {
	    const { onLeft, onClose } = this.state;
	    onLeft();
	    onClose();
	};

	rightClick = () => {
	    const { onRight, onClose } = this.state;
	    onRight();
	    onClose();
	};

	render = () => {
	    const { header, text, leftLabel, rightLabel, onClose } = this.state;
	    const open = this.props.open || this.state.open;
	    return (
	        <Modal open={open} classNames={{modal: "custom-modal"}} onClose={onClose} closeOnOverlayClick={false} >
	            <div className="custom-modal-form" role="document">
	                <div className="">
	                    <div className="custom-modal-header">
	                        <h5 className="custom-modal-header-text">{header}</h5>
	                    </div>
	                    <div className="custom-modal-text">
	                        <p>{text}</p>
	                    </div>
	                    <div className="custom-modal-footer">
	                        {leftLabel &&
	                            <button
	                                type="button"
	                              id="confirmation_model_button_left"
	                                className="btn btn-primary pull-right"
	                                onClick={this.leftClick}
	                                name="leftUserButton">
	                                {leftLabel}
	                            </button>}

	                        {rightLabel &&
	                            <button
	                                type="button"
	                              id= "confirmation_model_button_right"
	                                className="btn btn-secondary  pull-right"
	                                onClick={this.rightClick}
	                                name="rightUserButton"
	                                data-dismiss="modal">
	                                {rightLabel}
	                            </button>}
	                    </div>
	                </div>
	            </div>
	        </Modal>
	    );
	};
};

export default ConfirmationModal;
