import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import resolveJoiErrors from "lib/util/joi_error_resolver";

export default function ForgotPasswordReset({ user }) {
  // https://stackoverflow.com/questions/58767980/aws-cognito-password-regex
//   const PASSWORD_REGEX = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{8,}");
  const [awsCognitoError, setAwsCognitoError] = useState(false);
  const [awsCognitoErrorMessage, setAwsCognitoErrorMessage] = useState(false);

  const schema = Joi.object({
    code: Joi.string().required(),
    password: Joi.string().required(),
    confirmPassword: Joi.string().required()
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const { register, handleSubmit, errors } = useForm({ resolver: joiResolver(schema) });

  const resetPassword = ({ code, password, confirmPassword }) => {
    if (password !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    user.confirmPassword(code, password, {
      onSuccess: () => {
        window.location = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI + "/events";
      },
      onFailure: err => {
        setAwsCognitoError(true);
        setAwsCognitoErrorMessage(err.message);
      }
    });
  };

  return (
    <div style={{ maxWidth: "400px", marginTop: "30px" }}>
      <h4 className="page-header">Reset Password</h4>
      <p style={{ marginTop: "15px", marginBottom: "0px" }}>Your new password must contain at least <strong>one lower case character, one upper case character, one number, one special character</strong> and must be <strong>at least 8 characters</strong> in length.</p>
      <form onSubmit={handleSubmit(resetPassword)} style={{ paddingTop: "40px" }}>
        <div className="form-group">
          <div className="field-label">
            Verfication Code
	                </div>
          <input
            placeholder="Enter Code"
            type="text"
            name="code"
            ref={register}
            className="form-control"
          />
          {errors.code && <div className="text-danger">{resolveJoiErrors("Verification Code", errors.code)}</div>}
        </div>
        <div className="form-group">
          <div className="field-label">
            New Password
          </div>
          <input
            placeholder="Enter New Password"
            type="password"
            name="password"
            ref={register}
            className="form-control"
          />
          {errors.password && <div className="text-danger">{resolveJoiErrors("Password", errors.password)}</div>}
        </div>
        <div className="form-group">
          <div className="field-label">
            Confirm New Password
          </div>
          <input
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            ref={register}
            className="form-control"
          />
          {errors.confirmPassword && <div className="text-danger">{resolveJoiErrors("Confirm Password", errors.confirmPassword)}</div>}
        </div>
        {!passwordMatch && <div className="text-danger">Passwords do not match</div>}
        {awsCognitoError && (
          <div className="text-danger">{awsCognitoErrorMessage}</div>
        )}
        <div className="align-right">
          <button type="submit" className="btn btn-primary">RESET PASSWORD</button>
        </div>
      </form>
    </div>
  );
};
