import { 
  UPDATE_EVENT_DASHBOARD_STATUS
} from "actions/event_dashboard";

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EVENT_DASHBOARD_STATUS:
      return {...state, ...action.payload};
    default:
      return state;
  }
};
