import React, { Component, Fragment } from "react";
import { withRouter  } from "react-router";
import { connect } from "react-redux";

import withAuth from "components/hoc/with_auth";
import { getEventsByUserProfile } from "actions/events";
import { getUserById } from "actions/users";
import TabList from "components/tabs/tablist";
import Tab from "components/tabs/tab";
import EventList from "components/events/list/events_list";
import Header from "components/header/header";
import { getPermissions } from "actions/permissions";

class UserEvents extends Component {
  async componentDidMount() {
    const { quant_user_profile_id } = this.props.match.params;
    await this.props.getUserById(quant_user_profile_id);
    await this.props.getEventsByUserProfile(this.props.user.email);
    this.props.getPermissions();
  }

  render() {
    const { user, events } = this.props;
    return (
      <Fragment>
        <Header detailView={true} to="/settings/users" />
        <TabList>
          <Tab title="USER SETTINGS" to={`/settings/users/user-settings/${user.quant_user_profile_id}`} />
          <Tab activeTab={true} title="USER EVENTS" to={`/settings/users/user-events/${user.quant_user_profile_id}`} />
        </TabList>
        <div className="container-fluid tabs-spacer">
          <h1 className="page-header">User Events</h1>
          <div>User: {user.first_name + " " + user.last_name}</div>
          <div className="body-content-partial">
            <EventList events={events} hideEventControls={true} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    events: state.events
  };
};

export default connect(mapStateToProps, {
  getEventsByUserProfile, 
  getUserById,
  getPermissions
})(withRouter(withAuth(UserEvents)));