import React, { Component } from "react";
import PropTypes from "prop-types";

import "./toggle.css";

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {		
    this.setState({checked: this.props.checked});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({checked: nextProps.checked});
  }

	toggleCheckbox = (event) => {
	    const { active, onChange } = this.props;
	    if (active) {
	        const checked = !this.state.checked;
	        this.setState({checked});
	        const result = {};
	        result.name = this.props.name;
	        result.value = checked;
	        onChange(result);
	    }
	}

	render() {
	    let { theme } = this.props;
	    const activeClass = this.props.active ? "toggle-input-div" : "toggle-input-div inactive-toggle";
	    const interiorClass = this.state.checked ? "toggle-interior" : "toggle-interior toggle-interior-off";
	    const switchClass = this.state.checked ? "toggle-switch" : "toggle-switch toggle-switch-off";
	    const labelStyle = this.state.checked ? "toggle-label" : "toggle-label toggle-label-off";
	    return (
	        <div className={activeClass}>
	                <div className="toggle-input" onClick={this.toggleCheckbox}>
	                    <div className={interiorClass} >
	                        <div className={switchClass} >
	                        </div>
	                    </div>
	                </div>
	                <div className={labelStyle} style={theme}>
	                    {this.props.label}
	                </div>
	        </div>
	    );
	}
}

Toggle.propTypes = {
  active: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
};

Toggle.defaultProps = {
  active: true
};

export default Toggle;
