import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationMessage } from "fuzion-core-lib";

import { email, required } from "lib/validation/form_validation_rules";
import { getUsers, saveUser } from "actions/users";
import { getRoles } from "actions/roles";
import formHelper from "lib/ui/form";
import EventAccessEventProfileSelector from "./event_access_event_profile_selector";
import { setNotification } from "actions/notifications";
import renderClassNames from "lib/ui/render_class_names";

import "assets/css/checkbox.css";

class EventViewerDialog extends Component {
  constructor(props) {
    super(props);

    this.defaultEventProfileId = props.eventProfiles.filter(e => e.is_default).map(e => e.quant_event_profile_id)[0];

    this.state = {
      event_profiles: {
        key: "event_profiles",
        valid: true,
        value: [this.defaultEventProfileId]
      },
      first_name: {
        key: "first_name",
        value: "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      last_name: {
        key: "last_name",
        value: "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      email: {
        key: "email",
        value: "",
        valid: true,
        errorMessage: "",
        validation: [required, email]
      },
      quant_security_role_id: {
        key: "quant_security_role_id",
        value: ""
      },
      fuzion_event_id: {
        key: "fuzion_event_id",
        value: [props.fuzion_event_id]
      }
    };
  }

  async componentDidMount() {
    await this.props.getRoles();
    const user_role = this.props.roles.find(role => role.role_name === "QUANT_USER");
    this.setState(formHelper.mergeNewState(this.state, this.state.quant_security_role_id.key, {value: user_role.quant_security_role_id}));
  }

    selectProfile = (profileId) => {
      let values = new Set(this.state.event_profiles.value);
      if (values.has(profileId) && profileId !== this.defaultEventProfileId) {
        values.delete(profileId);
      } else {
        values.add(profileId);
      }
      let event_profiles = { ...this.state.event_profiles, value: [...values] };
      this.setState(formHelper.mergeNewState(this.state, this.state.event_profiles.key, event_profiles));
    }

    onChange = ({ target: { name, value } }) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onBlur = (e) => {
      const { target: { name, value } } = e;
      const validation = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, validation));
    }

    submit = async () => {
      const isFormValid = formHelper.isFormValid(this.state);
      if (!isFormValid.valid) {
        this.setState(isFormValid.formState);
        return;
      }
      let form = formHelper.getFormValues(this.state);
      form.events = [this.props.fuzion_event_id];
      await this.props.saveUser(form);
      this.props.getUsers();
      this.props.setNotification(notificationMessage.SUCCESS_ADD);
      this.props.onFinish();
    }

    cancel = () => {
      this.props.onFinish();
    }

    getStyle() {
      const firstNameErrStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.first_name.valid
      });
      const lastNameErrStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.last_name.valid
      });
      const emailErrStyle = renderClassNames({
        "form-control insert": true,
        "form-control-error": !this.state.email.valid
      });
      return {
        firstNameErrStyle: firstNameErrStyle,
        lastNameErrStyle: lastNameErrStyle,
        emailErrStyle: emailErrStyle  
      };
    }

    render() {
      const style = this.getStyle();
      return (
        <div style={{padding: "16px"}}>
          <div className="form-group">
            <div className="field-label">
                        First Name*
	                </div>
            <input
              name={this.state.first_name.key}
              type="text"
              value={this.state.first_name.value}
              onChange={this.onChange}
              onBlur={this.onBlur}  
              className={style.firstNameErrStyle}
            />
            {!this.state.first_name.valid &&
                        (<div className="text-danger">{this.state.first_name.errorMessage}</div>)
            }
          </div>
          <div className="form-group">
            <div className="field-label">
                        Last Name*
	                </div>
            <input
              name={this.state.last_name.key}
              type="text"
              value={this.state.last_name.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.lastNameErrStyle}
            />
            {!this.state.last_name.valid &&
                        (<div className="text-danger">{this.state.last_name.errorMessage}</div>)
            }
          </div>
          <div className="form-group">
            <div className="field-label">
                        Email Address*
	                </div>
            <input
              name={this.state.email.key}
              type="text"
              value={this.state.email.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.emailErrStyle}
            />
            {!this.state.email.valid &&
                        (<div className="text-danger">{this.state.email.errorMessage}</div>)
            }
          </div>
          <div className="form-group">
            <label className="field-label">
                        Select Profile(s)
	                </label>                    
            <EventAccessEventProfileSelector
              eventProfiles={this.props.eventProfiles}
              selectedProfiles={this.state.event_profiles.value}
              onChange={this.selectProfile}
            />
          </div>
          <div className="align-right" style={{margin: "16px"}}>
            <button type="button" className="btn btn-default" onClick={this.cancel}>
                        CANCEL
            </button>
            <button type="button" className="btn btn-primary" onClick={this.submit}>
                        ADD
            </button>                    
          </div>
        </div>
      );
    }
}

EventViewerDialog.defaultProps = {
  eventProfiles: []
};

const mapStateTopProps = state => {
  return {
    roles: state.roles
  };
};

export default connect(mapStateTopProps,
  {
    getRoles,
    getUsers,
    saveUser,
    setNotification
  }
)(EventViewerDialog);