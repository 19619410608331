import React, { Component } from "react";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";
import { getPermissions } from "actions/permissions";
import iconUserLight from "assets/images/iconUserLight.svg";

class ProfileSettingsButton extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

    onClick = () => {
      let { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    }

    render() {
      let { permissions } = this.props;
      if (!permissions || !permissions.canRenderEventProfileSettingsButton) return null;

      return (
        <Tippy content={<span id="tooltip-content">Event Profile Settings</span>}> 
          <button onClick={this.onClick} className="profileHeaderIcon">
            <img src={iconUserLight} alt="Event Profile Settings" />
          </button>
        </Tippy>
      );
    }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(ProfileSettingsButton);
