
import React, { Component } from "react";
import { withRouter } from "react-router";

import Header from "./header/header";

import "assets/css/no-permissions.css";

class NoPermissions extends Component {
  render() {
    const mailToURL = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
    return (
      <div className="main-admin-container">
        <Header />
        <div className="container-fluid no-permissions-container">
          <div className="main vertical-center">
            <div className="vertical-center-inner">
              <h1 className="page-header">Uh Oh...</h1>
              <div className="empty-state no-permissions-desc">
                <div>You currently don’t have access to Quant. Please contact support if you feel this is error.</div>
              </div>
              <a href={mailToURL} className="no-permissions-link">{process.env.REACT_APP_SUPPORT_EMAIL}</a>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(NoPermissions);