import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { notificationMessage, theme } from "fuzion-core-lib";

import select from "components/form/select";
import changes from "components/hoc/unsaved_changes";
import input from "components/form/input";
import ConfirmationModal from "components/modal/confirmation_modal";
import { required } from "lib/validation/form_validation_rules";
import { getRoles } from "actions/roles";
import { updateUser, deleteUser, saveUser } from "actions/users";
import { setNotification } from "actions/notifications";
import renderClassNames from "lib/ui/render_class_names";
import styles from "./user-settings.module.css";

import "assets/css/form.css";

class UserSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmationOpen: false,
    };
  }

  componentDidMount() {
    this.props.getRoles();
  }

  onOpenModal = () => {
    this.setState({ deleteConfirmationOpen: true });
  }

  onSubmit = async formData => {
    let { updateUser, operation, saveUser, onSave, currentUser } = this.props;
    if (operation === "update") {
      let user_role = this.props.roles.filter(role => role.quant_security_role_id === formData.quant_security_role_id);
      formData.role_friendly_name = user_role[0].role_friendly_name;
      formData.security_role_name = user_role[0].role_name;
      formData.role_permissions = user_role[0].role_permissions;
      formData.status_flag = formData.status_flag !== null ? parseInt(formData.status_flag) : null;
      formData.prior_status_flag = currentUser.status_flag !== null ? parseInt(currentUser.status_flag) : null;
      updateUser(formData, () => {
        this.props.setNotification(notificationMessage.SUCCESS_UPDATE);
      });
    }
    if (operation === "save") {
      formData.theme = { mode: theme.LIGHT };
      await saveUser(formData);
      onSave();
    }
  }

  onDelete = () => {
    let { history, deleteUser, currentUser } = this.props;
    deleteUser(currentUser, () => {
      this.setState({ deleteConfirmationOpen: false });
      history.push("/settings/users");
      this.props.setNotification(notificationMessage.SUCCESS_DELETE);
    });
  }

  selectRoles = () => {
    if (!this.props.roles) return;
    const options = {};
    const validRoles = ["QUANT_USER", "QUANT_ADMIN"];
    this.props.roles.forEach(role => {
      if (validRoles.includes(role.role_name)) {
        options[role.quant_security_role_id] = (role.role_friendly_name);
      }
    });
    return options;
  }

  render() {
    let { handleSubmit, submitting, operation } = this.props;
    let isInsert = operation === "save";
    const buttonContainerStyle = renderClassNames({
      "form-group": !isInsert,
      "align-right": isInsert
    });
    let firstName = isInsert ? "First Name*" : "First Name";
    let lastName = isInsert ? "Last Name*" : "Last Name";
    let emailAddress = isInsert ? "Email Address*" : "Email Address";
    let backgroundColor = isInsert ? { backgroundColor: "rgba(0, 0, 0, 0.05)" } : { backgroundColor: "#fff" };
    return (
      <div className={styles.userSettingsFormInnerContainer}>
        {isInsert &&
          <div>
            <h4 className="modal-title">Invite User</h4>
          </div>
        }
        <form onSubmit={handleSubmit(this.onSubmit)} className={`modal-form ${styles.userSettingsForm}`}>
          <div className="form-group">
            <div className="field-label">
              {firstName}
            </div>
            <div>
              <Field
                type="text"
                label="First Name"
                name="first_name"
                component={input}
                bgColor={backgroundColor}
                validate={required}
                disabled={operation === "update"}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">
              {lastName}
            </div>
            <div>
              <Field
                label="Last Name"
                name="last_name"
                component={input}
                bgColor={backgroundColor}
                validate={required}
                disabled={operation === "update"}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">
              {emailAddress}
            </div>
            <div>
              <Field
                label="email@address.com"
                name="email"
                component={input}
                bgColor={backgroundColor}
                validate={required}
                disabled={operation === "update"}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">
              Role
            </div>
            <Field
              label="Role"
              name="quant_security_role_id"
              component={select}
              bgColor={backgroundColor}
              options={this.selectRoles()}
              placeholder="Select a security role..."
              validate={required}
            />
          </div>
          {!isInsert &&
            <div className="form-group">
              <div className="field-label">
                Status
              </div>
              <div>
                <Field
                  label="Status"
                  name="status_flag"
                  component={select}
                  bgColor={backgroundColor}
                  options={{
                    1: "Active",
                    0: "Inactive"
                  }}
                />
              </div>
            </div>
          }
          {isInsert &&
            <div className="field-label required-label" style={{ marginTop: "5px" }}>
              * Required
            </div>
          }
          <div className={buttonContainerStyle}>
            {!isInsert &&
              <button
                type="submit"
                className="btn btn-primary"
              >
                SAVE
              </button>
            }
            {!isInsert &&
              <button
                type="button"
                disabled={submitting}
                onClick={this.onOpenModal}
                name="deleteUserButton"
                className="btn btn-default"
              >
                DELETE USER
              </button>
            }
            {isInsert &&
              <button
                type="submit"
                id={styles.userSettingFormButton}
                name="sendUserInviteButton"
                className="btn btn-primary"
              >
                SEND
              </button>
            }
            {isInsert &&
              <button
                type="button"
                id={styles.userSettingFormButton}
                onClick={this.props.onClose}
                name="cancelButton"
                className="btn btn-default"
              >
                CANCEL
              </button>
            }
          </div>
        </form>
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete User?"
          text="This user will no longer have access to the application."
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.user,
    roles: state.roles
  };
};

export default connect(mapStateToProps, {
  getRoles,
  updateUser,
  deleteUser,
  setNotification,
  saveUser
})(reduxForm({
  form: "UserSettingsForm",
  enableReinitialize: true
})(withRouter(changes(UserSettingsForm))));