import axios from "axios";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLE = "GET_ROLE";

export const getRoles = () => {
  return async (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
    };
    const request = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/security-roles?take=1000`,
      headers: headers
    });
    dispatch({
      type: GET_ROLES,
      payload: request
    });
  };
};

export const getRole = quant_security_role_id => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/security-roles/${quant_security_role_id}`,
      headers: headers
    });
    dispatch({
      type: GET_ROLE,
      payload: request
    });
  };
};

