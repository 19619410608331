import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import renderClassNames from "lib/ui/render_class_names";
import iconAddCircle from "assets/images/iconAddCircleDark.svg";
import InsightsForm from "./insights_form";
import InsightsList from "./insights_list";
import { reorderInsights } from "actions/event_profiles";

import "./insights_menu.css";

class InsightsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insightModalOpen: false,
      insightModalOperation: null,
      currentInsightId: null
    };
  }

    onOpenModal = (operation, insightId) => {
      this.setState({ currentInsightId: insightId, insightModalOpen: true, insightModalOperation: operation });
    }

    onCloseModal = () => {
      this.setState({ insightModalOpen: false });
    }

    onDrop = (result) => {
      const {timeframeLabel} = this.props;
      this.props.reorderInsights(timeframeLabel, result.source.index, result.destination.index);
    }

    insightsMenuStyles = (isDragging = false) => {
      return renderClassNames({
        "insights-menu-insight-list-container": true,
        "insights-menu-drag-active": isDragging
      });
    }

    render() {
      const { eventProfile, timeframeLabel, permissions } = this.props;
      const { currentInsightId, insightModalOpen, insightModalOperation } = this.state;
      const timeframeInsights = eventProfile[`${timeframeLabel}_insights`];
      const currentInsight = timeframeInsights ? timeframeInsights.filter(insight => insight.id === currentInsightId)[0] : null;
      const isAdmin = permissions.isAdmin;
      return (
        <>
          <div className="insights-menu-container">
            <div className="insights-menu-header">
              <div className="insights-menu-header-content">
                <h5>Insights</h5>
                {isAdmin &&
                                <img onClick={() => this.onOpenModal("insert", null)} className="insights-menu-header-icon-add" src={iconAddCircle} alt="add insight" />
                }
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDrop}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div className={this.insightsMenuStyles(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    <InsightsList onOpenModal={this.onOpenModal} insights={timeframeInsights} timeframeLabel={timeframeLabel} isAdmin={isAdmin} />
                    {provided.placeholder}
                  </div>

                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Modal open={insightModalOpen} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
            <InsightsForm currentInsightId={currentInsightId} onClose={this.onCloseModal} insight={currentInsight} operation={insightModalOperation} />
          </Modal>
        </>
      );
    };
}
const mapStateToProps = state => {
  return {
    permissions: state.permissions,
    eventProfile: state.eventProfile,
    timeframeLabel: state.eventDashboard.timeframeLabel
  };
};
export default connect(mapStateToProps, {reorderInsights})(InsightsMenu);