import { metricEventStatus } from "fuzion-core-lib";

import { request } from "lib/data/api";
import global from "types/global";
import types from "types/event_profile_metrics";

export const getSplitMetrics = eventProfileMetrics => {
  let preEventMetrics = eventProfileMetrics
    .filter(m => m.event_status === metricEventStatus.PRE_EVENT)
    .sort((a, b) => (a.dashboard_order - b.dashboard_order));
  let eventMetrics = eventProfileMetrics
    .filter(m => m.event_status === metricEventStatus.DURING_EVENT)
    .sort((a, b) => (a.dashboard_order - b.dashboard_order));
  let postEventMetrics = eventProfileMetrics
    .filter(m => m.event_status === metricEventStatus.POST_EVENT)
    .sort((a, b) => (a.dashboard_order - b.dashboard_order));
  return {
    preEventMetrics: preEventMetrics,
    eventMetrics: eventMetrics,
    postEventMetrics: postEventMetrics
  };
};

export const getMetricsByEventProfile = eventProfileId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event-profile-metrics/?quant_event_profile_id=${eventProfileId}`
      });
      dispatch({
        type: types.GET_EVENT_PROFILE_METRICS,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getMetricsByEventProfile failed"
      });
    }
  };
};

export const getEventProfileMetricData = (eventId, metric_label) => {
  return (dispatch, getState) => {
    try {
      let state = getState();
      const response = request({
        state: state,
        method: "GET",
        eventId: eventId,
        apiUri: process.env.REACT_APP_API_METRIC_URI,
        route: `/v1/metrics/?metric_label=${metric_label}`
      });
      return response;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getEventProfileMetricData failed"
      });
    }
  };
};

export const addEventProfileMetric = (metric, quant_event_profile_id, eventStatus) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const data = {
        ...metric,
        event_status: eventStatus - 1
      };
      await request({
        state: state,
        method: "POST",
        route: `/v1/quant-event-profile-metrics/${quant_event_profile_id}`,
        data: data
      });
      dispatch(getMetricsByEventProfile(quant_event_profile_id));
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.addEventProfileMetric failed"
      });
    }
  };
};

export const deleteEventProfileMetric = metric => {
  const {quant_event_profile_metric_id, quant_event_profile_id} = metric;
  return async (dispatch, getState) => {
    try {
      let state = getState();
      await request({
        state: state,
        method: "DELETE",
        route: `/v1/quant-event-profile-metrics/${quant_event_profile_metric_id}`,
        data: {quant_event_profile_id: quant_event_profile_id}
      });
      let metrics = state.eventProfileMetrics.filter((m => m.quant_event_profile_metric_id !== quant_event_profile_metric_id));
      dispatch(updateEventProfileMetric(metrics));
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profile_metric.deleteEventProfileMetric failed"
      });
    }
  };
};

export const updateEventProfileMetric = metrics => ({
  type: types.UPDATE_EVENT_PROFILE_METRICS,
  payload: metrics
});

export const updateEventProfileMetricData = eventProfileMetric => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "PUT",
        route: `/v1/quant-event-profile-metrics/${eventProfileMetric.quant_event_profile_metric_id}`,
        data: eventProfileMetric
      });
      dispatch({
        type: types.UPDATE_EVENT_PROFILE_METRIC_DATA,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.updateEventProfileMetricData failed"
      });
    }
  };
};

export const updateEventProfileMetricOrder = (metrics) => {
  return (dispatch, getState) => {
    metrics = [...new Set([...getState().eventProfileMetrics, ...metrics])];
    dispatch(updateEventProfileMetric(metrics));
    dispatch(saveEventProfileMetricOrder());
  };
};

export const saveEventProfileMetricOrder = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let {eventProfileMetrics, eventDashboard, event } = getState();
      let metrics = eventProfileMetrics
        .filter(m => m.event_status === eventDashboard.eventStatus - 1);
      const response = await request({
        state: state,
        method: "POST",
        route: "/v1/quant-event-profile-metrics/order",
        data: { quant_event_profile_id: event.fuzion_event_id, metrics }
      });
      if (response.status !== 200) {
        throw new Error();
      } 
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.saveEventProfileMetricOrder failed"
      });
    }
  };
};
