import React, { Component } from "react";
import { connect } from "react-redux";

import UserText from "./user_text";
import EmptyState from "components/empty_state";

class UserList extends Component {
  renderLink(item) {
    return (
      <div key={item.quant_user_profile_id}>
        <div className="list-item">
          <UserText item={item} />
        </div>
      </div>
    );
  }

  render() {
    let { items } = this.props;
    if (!items || (items && items.length < 1)) return <EmptyState type="users" theme="light" />;

    let activeUsers = items
      .filter(item => item.status_flag > 0)
      .map(item => this.renderLink(item));
    let inactiveUsers = items
      .filter(item => item.status_flag === 0)
      .map(item => this.renderLink(item));

    return (
      <div className="list-group">
        <div className="event-sub-header">
          {activeUsers.length > 0 ? "Active" : ""}
        </div>
        {activeUsers}
        <div className="event-sub-header">
          {inactiveUsers.length > 0 ? "Inactive" : ""}
        </div>
        {inactiveUsers}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(UserList);
