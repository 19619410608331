import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";

import renderClassNames from "lib/ui/render_class_names";
import iconSettings from "assets/images/iconSettingsDark.svg";
import DashboardSettingsForm from "components/profile/dashboard_settings_form";

import "./profile_dashboard_tab.css";

class ProfileTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardSettingsModalOpen: false
    };
  }

    onCloseModal = () => {
      this.setState({dashboardSettingsModalOpen: false});
    }

    handleSettingsClick = (e) => {
      this.setState({dashboardSettingsModalOpen: true});
    }

    renderSettings() {
      const { permissions } = this.props;
      if (permissions.isAdmin) {
        return  <img src={iconSettings} onClick={this.handleSettingsClick} alt="icon settings" className="profile-dashboard-header-tab-img"/>;
      }
    }

    render() {
      let { isActiveTab } = this.props;
      const profileClass = renderClassNames({
        "profile-dashboard-header-tab": true,
        "profile-dashboard-header-tab-active": isActiveTab
      });
      return (
        <>
          <Link
            className={profileClass}
            onClick={this.props.onClick}
            to={this.props.to}
            replace={true}
          >
            {this.props.title}
            {this.renderSettings()}
          </Link>
          <Modal open={this.state.dashboardSettingsModalOpen} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
            <DashboardSettingsForm onClose={this.onCloseModal}/>
          </Modal>
        </>
      );
    }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {})(withRouter(ProfileTab));