import React, { useState, useRef, } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { upload } from "lib/data/api";

import styles from "./bulk_upload.module.css";
import iconExitRed from "assets/images/iconExitRed.svg";
import iconCheckCircle from "assets/images/iconCheckCircle.svg";


export default function BulkUpload(props) {
  const [fileName, setFileName] = useState("Select a CSV file to import");
  const [file, setFile] = useState();
  const [errors, setErrors] = useState();
  const [fileSelected, setFileSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [successUsersCount, setSuccessUsersCount] = useState(0);

  const state = useSelector(state => state);
  const { fuzion_event_id, quant_event_profile_id } = useParams();

  const fileInput = useRef();
  const { handleSubmit } = useForm();

  const chadworth = () => {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const postFile = async () => {
    const user_role = props.roles.find(role => role.role_name === "QUANT_USER");
    let formData = new FormData();
    formData.append("upload", file);
    formData.append("quant_security_role_id", user_role.quant_security_role_id);
    formData.append("events", [fuzion_event_id]);
    formData.append("event_profiles", [quant_event_profile_id]);

    const response = await upload({
      state: state,
      method: "POST",
      route: "/v1/quant-user-profile/upload",
      data: formData
    });
    await chadworth();
    if (response.payload.errors.length !== 0) {
      setErrors(response.payload.errors);
    } else {
      setIsSuccessful(true);
      setSuccessUsersCount(response.payload.succeededRecordCount);
    }
    setLoading(false);
  };

  const onClick = () => {
    setFileSelected(false);
    setErrors(null);
    setFileName("Select a CSV file to import");

    document.getElementById("fileInput").click();
  };

  const onChange = () => {
    if (fileInput.current.files[0]) {
      setFileName(fileInput.current.files[0].name);
      setFile(fileInput.current.files[0]);
      setErrors(null);
      setFileSelected(true);
    }
  };

  const onSubmit = () => { };

  const handleClick = () => {
    setLoading(true);
    postFile();
  };

  const closeModal = () => {
    props.onClose();
  };

  return (
    <div style={{ margin: "16px" }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: "30px" }}>
        <div className="form-group">
          <div className="field-label">Filename*</div>
          <div className="form-control" style={{ opacity: 0.38 }}>{fileName}</div>
          <input type="file" accept=".csv" id="fileInput" ref={fileInput} onChange={onChange} hidden />
          <button className="btn btn-default" onClick={onClick}>Browse</button>
        </div>
        <div style={{ height: "80px" }}>
          {errors && (
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <img className={styles.icon} src={iconExitRed} alt="icon exit red"></img>
              <div className={styles.messageText}>
                <strong>Import failed!</strong>
                {` An error was encountered when attempting to import ${fileName}:`}
                {Array.isArray(errors)
                  ? errors.map((error, idx) => {
                    return (
                      error.message &&
                      <li key={idx} style={{ listStyle: "none" }}>Line{error.rowNumber}: {error.message}</li>
                    );
                  })
                  : <li style={{ listStyle: "none" }}>{errors.message}</li>
                }
              </div>
            </div>
          )}
          {isSuccessful && (
            <div style={{ display: "flex" }}>
              <img className={styles.icon} src={iconCheckCircle} alt="icon check circle"></img>
              <div className={styles.messageText}>
                {`Bulk import completed successfully, ${successUsersCount} users created.`}
              </div>
            </div>
          )}
          <div className="align-left" >* Required</div>
          <div className="align-right" style={{ marginBottom: "16px" }}>
            {!isSuccessful && (
              <button
                className="btn btn-default"
                onClick={closeModal}
              >
                CANCEL
              </button>
            )}
            {(!isSuccessful && fileSelected) && (
              <button
                className="btn btn-primary"
                disabled={isLoading || !fileSelected}
                onClick={!isLoading ? handleClick : null}
              >
                {isLoading ? "Loading…" : "IMPORT"}
              </button>
            )}
            {isSuccessful && (
              <button
                className="btn btn-primary align-right"
                onClick={closeModal}
              >
                OK
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
