import React, { Component } from "react";
import Tippy from "@tippy.js/react";

import Fullscreen from "lib/ui/fullscreen";
import iconFullScreenLight from "assets/images/iconFullScreenLight.svg";

class FullscreenButton extends Component {
    state = {
      isFullscreen: false
    }

    onClick = () => {
      this.setState(prevState => 
        ({ isFullscreen: !prevState.isFullscreen })
      );
    }

    render() {
      return (
        <>
          <Tippy content={<span id="tooltip-content">Full Screen</span>}>
            <button onClick={this.onClick} className="profileHeaderIcon">
              <img src={iconFullScreenLight} alt="Fullscreen" />
            </button>
          </Tippy>
          <Fullscreen isFullscreen={this.state.isFullscreen} />
        </>
      );
    }
}

export default FullscreenButton;
