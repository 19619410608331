import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { getPermissions } from "actions/permissions";
import ListIcon from "./list_icon";
import EmptyState from "components/empty_state";
import avatarDocument from "assets/images/avatarDocument.png";
import iconDeleteDark from "assets/images/iconDeleteDark.svg";

class DocumentList extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  renderDeleteIcon(item, onDelete) {
    let { permissions } = this.props;
    if (!permissions || !permissions.canRenderDocumentListDeleteButton) return null;

    return (
      <div className="list-image-section">
        <ListIcon iconImg={iconDeleteDark} iconTo="/pre-event" item={item} onClick={() => onDelete(item)} tooltip="Delete"/>
      </div>
    );
  }

  renderLink(item, match) {
    let {
      id,
      onClick,
      listTitle,
      dateTitle,
      dateField,
      onDelete
    } = this.props;

    let timeStamp = moment(item[dateField]).format("MMM DD, h:mm A");

    return (
      <div key={item[id]} className="list-item list-item-2">
        <div className="list-group-item-simple" key={item[id]} onClick={() => onClick(item)}>
          <div className="list-avatar-div">
            <img src={avatarDocument} className="list-document-image" alt="List Profile" />
          </div>
          <div className="list-info-container">
            <div className="list-info-details">
              <div className="list-title">
                {item[listTitle]}
              </div>
              <div className="status-div">
                {dateTitle && dateField ? <div>{dateTitle}: {timeStamp}</div> : null}
              </div>
            </div>
          </div>
        </div>
        {this.renderDeleteIcon(item, onDelete)}
      </div>
    );
  }

  render() {
    let activeItems = <EmptyState type="documents" theme="light"/>;
    let { items, match } = this.props;
    if (items.length > 0) activeItems = items.map(item => this.renderLink(item, match));
    return (
      <div className="list-group">
        {activeItems}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions
})(withRouter(DocumentList));