import React, { Component } from "react";
import { withRouter } from "react-router";

import Header from "./header/header";

class Index extends Component {
  componentDidMount() {
    this.props.history.push("/events");
  }

  render() {
    return (
      <div className="main-admin-container">
        <Header />
        <div className="load-bar with-header">
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
