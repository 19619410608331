import React, { Fragment, Component } from "react";

class TextAreaNonRedux extends Component {
  onChange = e => {
    this.props.onChange(e);
  };

  render() {
    let { rows, label, className, value, name } = this.props;
    return (
      <div className="textAreaContainer">
        <Fragment>
          <textarea
            onChange={this.onChange}
            rows={rows}
            placeholder={label}
            className={className}
            value={value}
            name={name}
          />
        </Fragment>
      </div>
    );
  }
}

export default TextAreaNonRedux;