import React, { Component } from "react";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";

import { getPermissions } from "actions/permissions";
import iconUsersLight from "assets/images/iconUsersLight.svg";

class UsersButton extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

    onClick = () => {
      let { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    }

    render() {
      let { permissions } = this.props;
      if (!permissions || !permissions.canRenderEventProfileUsersButton) return null;
        
      return (
        <Tippy content={<span id="tooltip-content">Profile Access</span>}> 
          <button onClick={this.onClick} className="profileHeaderIcon">
            <img src={iconUsersLight} alt="User Profile Access" />
          </button>
        </Tippy>
      );
    }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(UsersButton);
