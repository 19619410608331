import types from "types/metrics";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_METRICS:
      return action.payload;
    default:
      return state;
  }
};
