import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setSideBarVisibility } from "actions/sidebar";
import iconUserDark from "assets/images/iconUserDark.svg";
import iconEventDark from "assets/images/iconEventDark.svg";
import iconSettingsDark from "assets/images/iconSettingsDark.svg";
import iconHelpDark from "assets/images/iconHelpDark.svg";
import iconExitDark from "assets/images/iconExitDark.svg";
import logoQuant from "assets/images/logoQuant.svg";
import "assets/css/sidebar.css";
import { getPermissions } from "actions/permissions";
import renderClassNames from "lib/ui/render_class_names";
import Cognito from "../lib/auth/cognito";

class SideBar extends Component {
  async componentDidMount() {
    await this.setSideBar();
    await this.props.getPermissions();
    window.addEventListener("resize", this.resize);
  }

  setSideBar = async () => {
    let isVisible = false;
    const user = await Cognito().getSession();
    if (window.innerWidth > 768 && window.location.href.indexOf("/profile/") === -1) {
      isVisible = true;
    }
    if (!user || user.isExpired) {
      isVisible = false;
    }
    await this.props.setSideBarVisibility(isVisible);
  }

  resize = () => {
    this.forceUpdate();
    this.setSideBar();
  }

  logout = e => {
    e.preventDefault();
    Cognito().logout();
  }

  renderItem(to, text, imgSrc, isAvatar = false) {
    const { pathname } = this.props.location;
    const active = pathname.startsWith(to);
    const liClasses = renderClassNames({
      "active": active
    });
    const iconDivClasses = renderClassNames({
      "icon-menu": true,
      "icon-menu-avatar": isAvatar
    });
    let imgOpacity = active ? { opacity: 1 } : { opacity: 0.5 };
    return (
      <li className={liClasses} onClick={this.setSideBar}>
        <Link to={to} className="sidebar-link">
          <div className={iconDivClasses} style={imgOpacity}>
            <img src={imgSrc} alt="Sidebar Link Icon" />
          </div>
          <div className="sidebar-text">
            {text}
          </div>
        </Link>
      </li>
    );
  }

  // isExpiredSession(){
  //     let isExpired = false;
  //     const token = localStorage.getItem('id_token');
  //     const decodedToken=jwt.decode(token, {complete: true});

  //     if(decodedToken.exp < new Date().getTime())
  //         isExpired = true;

  //     return isExpired;
  // }

  // async cognitoSession() {
  //     let user = await Cognito().getSession();
  //     return user;
  // }

  renderSettings() {
    let { permissions } = this.props;
    if (!permissions || !permissions.canRenderSidebarSettingsMenuItem) return null;
    return this.renderItem("/settings/users", "Settings", iconSettingsDark);
  }

  render() {
    //   if (await !Cognito().getSession() || this.isExpiredSession()) {
    //     return null;
    //   }
    const mailToURL = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
    if (!this.props.sidebar.isVisible) {
      return <div className="sidebar" />;
    }
    return (
      <div className="sidebar sidebar-open">
        <div className="sidebar-header">
          <a className="sidebar-brand" href="/">
            <img src={logoQuant} className="logomain center-block" alt="Quant Logo" />
          </a>
        </div>
        <ul className="sidebar-list">
          {this.renderItem("/account", "My Account", iconUserDark, true)}
          {this.renderItem("/events", "My Events", iconEventDark)}
          {this.renderSettings()}
          {/* {this.renderItem("/help", "Help", iconHelpBorderDark)} */}
          <li>
            <a href={mailToURL} className="sidebar-link">
              <div className="icon-menu" style={{ opacity: 0.5 }}>
                <img src={iconHelpDark} alt="Help" />
              </div>
              <div className="sidebar-text">
                Help
	                        </div>
            </a>
          </li>
          <li>
            <a href="/" className="sidebar-link" onClick={this.logout}>
              <div className="icon-menu" style={{ opacity: 0.5 }}>
                <img src={iconExitDark} alt="Logout" />
              </div>
              <div className="sidebar-text">
                Logout
	                        </div>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
    sidebar: state.sidebar,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
  setSideBarVisibility,
})(withRouter(SideBar));