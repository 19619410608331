import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Loading from "components/loading";
import Header from "components/header/header";
import withAuth from "components/hoc/with_auth";
import catchError from "components/hoc/catch_error";
import { setSideBarVisibility } from "actions/sidebar";
import { getEvents, filterEvents, getEventsByUserProfile } from "actions/events";
import { getPermissions } from "actions/permissions";

const EventList = React.lazy(() => import("./list/events_list"));
const ListSearch = React.lazy(() => import("components/form/list_search"));

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsFiltered: false,
      filterString: ""
    };
  }

  async componentDidMount() {
    this.setVisibility();
    await this.props.getEventsByUserProfile(this.props.user.email);
    this.props.getPermissions();
  }

  setVisibility() {
    let setVisibility = false;
    if (window.innerWidth > 768) {
      setVisibility = true;
    }
    this.props.setSideBarVisibility(setVisibility);
  }

    filterEvents = () => {
      if (this.state.filterString !== "") {
        return filterEvents(this.state.filterString, this.props.events);
      } else {
        return this.props.events;
      }
    }

    onListSearchChange = e => {
      this.setState({
        eventsFiltered: true,
        filterString: e.value
      });
    }

    render() {
      return (
        <>
          <Header />
          <Suspense fallback={<Loading />}>
            <div className="container-fluid">
              <h1 className="page-header">My Events</h1>
              <ListSearch
                onChange={this.onListSearchChange}
                placeholder="Search for an event"
                autoComplete="off">
              </ListSearch>
              <div className="body-content-partial">
                <EventList events={this.filterEvents()} hideEventControls={true} eventsFiltered={this.state.eventsFiltered} />
              </div>
            </div>
          </Suspense>
        </>
      );
    }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    events: state.events,
  };
};

export default connect(mapStateToProps, {
  setSideBarVisibility,
  getEventsByUserProfile,
  getEvents,
  getPermissions
})(withRouter(withAuth(catchError(Events))));
