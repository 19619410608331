import React from "react";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";

import iconEditDark from "assets/images/iconEditDark.svg";
import iconMessageDark from "assets/images/iconMessageDark.svg";
import iconDragDropDark from "assets/images/iconDragDropDark.svg";

export default function renderInsightsList(props) {
  const { isAdmin, onOpenModal, insights } = props;
  if (!insights) {
    return (
      <div className="insights-empty-state">
        <img className="insights-empty-state-image" src={iconMessageDark} alt="edit insight" />
        <h5 className="insights-empty-state-text">No Insights have been added.</h5>
      </div>
    );
  };
  return insights.map((insight, index) => {
    return (
      <Draggable key={insight.id} draggableId={insight.id} index={index} isDragDisabled={!props.isAdmin}>
        {(provided) => (
          <div 
            key={insight.id} className="insight-item-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="insight-item-header-container">
              <h5 className="insight-item-title">{insight.title}</h5>
              <h6 className="insight-item-date">{moment(insight.last_mod_timestamp).format("MMM D, YYYY h:mm A")}</h6>
              <h6 className="insight-item-signature">{`Created By: ${insight.created_by}`}</h6>
            </div>
            <div className="insight-item-body">
              {insight.body}
            </div>
            {isAdmin &&
                            <div className="insight-item-action-container">
                              <img className="insights-menu-header-icon" src={iconDragDropDark} alt="drag insight" />
                              <img onClick={() => onOpenModal("update", insight.id)} className="insights-menu-header-icon edit" src={iconEditDark} alt="edit insight" />
                            </div>}
          </div>

        )}
      </Draggable>
    );
  });
};