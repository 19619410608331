import React, { Component } from "react";

import Error from "./error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      message: null
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      message: error
    };
  }

  render() {
    if (this.state.hasError) {
      return <Error message={this.state.message} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
