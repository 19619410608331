import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import Cognito from "lib/auth/cognito";
import { getActiveUser } from "actions/active_user";
import { setIdentityUser } from "actions/identity_user";

let withAuth = ExtendedComponent => {
  class WithAuth extends Component {
    constructor() {
      super();
      this.nextLocationKey = "nextLocation";
      this.state = {
        authCompleted: false,
      };
    }

    async componentDidMount() {
      await this.authCognitoUser();
    }

    setNextLocation(nextLocation) {
      if (!window.localStorage) {
        return;
      }
      window.localStorage.setItem(this.nextLocationKey, nextLocation);
    }

    getNextLocation() {
      if (!window.localStorage) {
        return "/events";
      }
      return window.localStorage.getItem(this.nextLocationKey);
    }

    authCognitoUser = async () => {
      let { history } = this.props;
      try {
        let user = await Cognito().getSession();
        //TODO: test fully all this redirect logic once switched to Cognito. Seems to work alright for now.
        if (!user) {
          history.push("/");
        } else {
          if (user.expired) {
            this.setNextLocation(window.location.pathname);
            window.location = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
            return;
          }
          this.props.setIdentityUser(user);
          const response = await (this.props.getActiveUser(user));
          const u = response.payload;
          this.setState({ authCompleted: true });
          if (!(u && u.role_permissions && u.role_permissions.length !== 0)) {
            history.push("/nopermissions");
          } else {
            let nextLocation = this.getNextLocation();
            if (nextLocation && nextLocation !== "") {
              history.push(nextLocation);
              this.setNextLocation("");
            }
          }
        }
      } catch (e) {
        window.location = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI + "/login";
      }
    }

    render() {
      let { activeUser } = this.props;
      const { authCompleted } = this.state;

      if (activeUser && activeUser.role_permissions && activeUser.role_permissions.length !== 0 && authCompleted) {
        return <ExtendedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapStateToProps = state => {
    return {
      activeUser: state.activeUser
    };
  };

  return connect(mapStateToProps, {
    setIdentityUser,
    getActiveUser
  })(withRouter(WithAuth));
};

export default withAuth;