import React, { Fragment } from "react";

function EventAccessEventProfileSelector(props) {
  return (
    <Fragment>
      {props.eventProfiles.map(ep =>
        <div className="checkbox" key={ep.quant_event_profile_id}>
          <input
            name="event_profiles"
            type="checkbox"
            checked={props.selectedProfiles.includes(ep.quant_event_profile_id)}
            onChange={() => { }}
          />
          <label onClick={() => props.onChange(ep.quant_event_profile_id)}>
            {ep.profile_name}
          </label>
        </div>
      )}
    </Fragment>
  );
}

export default EventAccessEventProfileSelector;