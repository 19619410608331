import global from "types/global";
import types from "types/permissions";

export const getPermissions = () => {
  return (dispatch, getState) => {
    try {
      const state = getState();
      const activeUser = state.activeUser;
      if (Object.keys(activeUser).length === 0) return;
      const activeUserPermissions = activeUser.role_permissions;
      const activeUserPermissionNames = activeUserPermissions.map(x => x.permission_name);
      dispatch({
        type: types.PERMISSIONS,
        payload: {
          canRenderSidebarSettingsMenuItem: permissionsForSidebarSettings(activeUserPermissionNames),
          canRenderEventProfileEditButton: permissionsForEventProfileEditButton(activeUserPermissionNames),
          canRenderEventProfileUsersButton: permissionsForEventProfileUsersButton(activeUserPermissionNames),
          canRenderEventProfileSettingsButton: permissionsForEventProfileSettingsButton(activeUserPermissionNames),
          canRenderEventProfileDocumentsButton: permissionsForEventProfileDocumentsButton(activeUserPermissionNames),
          canRenderEventProfileDownloadButton: permissionsForEventProfileDownloadButton(activeUserPermissionNames),
          canRenderEventProfileAddButton: permissionsForEventProfileAddButton(activeUserPermissionNames),
          canRenderEventProfileDeleteButton: permissionsForEventProfileDeleteButton(activeUserPermissionNames),
          canRenderEventProfileDocumentAddButton: permissionsForEventDocumentAddButton(activeUserPermissionNames),
          canRenderEventControlsProfilesButton: permissionsForEventControlsProfilesButton(activeUserPermissionNames),
          canRenderEventControlsDocumentsButton: permissionsForEventControlsDocumentsButton(activeUserPermissionNames),
          canRenderEventControlsSettingsButton: permissionsForEventControlsSettingsButton(activeUserPermissionNames),
          canRenderDocumentListDeleteButton: permissionsForDocumentListDeleteButton(activeUserPermissionNames),
          canRenderTemplatesAddButton: permissionsForTemplatesAddButton(activeUserPermissionNames),
          canRenderUsersAddButton: permissionsForUsersAddButton(activeUserPermissionNames),
          canRenderEventProfilesTab: permissionsForEventProfilesTab(activeUserPermissionNames),
          canRenderEventDocumentsTab: permissionsForEventDocumentsTab(activeUserPermissionNames),
          canRenderEventSettingsTab: permissionsForEventSettingsTab(activeUserPermissionNames),
          isDeveloper: isInRole(activeUser, "QUANT_DEVELOPER"),
          isAdmin: isInRole(activeUser, "QUANT_ADMIN"),
          isEditor: isInRole(activeUser, "QUANT_EDITOR"),
          isUser: isInRole(activeUser, "QUANT_USER")
        }
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.getPermissions failed. " + e
      });
    }
  };
};

export const permissionsForEventProfileEditButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_ADD",
    "EVENT_PROFILES_EDIT",
    "EVENT_PROFILES_REMOVE",
    "EVENT_PROFILES_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileUsersButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_ADD",
    "EVENT_PROFILES_EDIT",
    "EVENT_PROFILES_REMOVE",
    "EVENT_PROFILES_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileSettingsButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileDocumentsButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileDownloadButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileAddButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_ADD",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventDocumentAddButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_ADD",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForSidebarSettings = activeUserPermissionNames => {
  const permissionsRequired = [
    "TEMPLATES_ADD",
    "TEMPLATES_EDIT",
    "TEMPLATES_REMOVE",
    "TEMPLATES_VIEW",
    "USERS_ADD",
    "USERS_EDIT",
    "USERS_REMOVE",
    "USERS_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventControlsProfilesButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_VIEW"
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventControlsDocumentsButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_VIEW"
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventControlsSettingsButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_SETTINGS_VIEW"
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForDocumentListDeleteButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_REMOVE"
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfileDeleteButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_REMOVE",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForTemplatesAddButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "TEMPLATES_ADD",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForUsersAddButton = activeUserPermissionNames => {
  const permissionsRequired = [
    "USERS_ADD",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventProfilesTab = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_PROFILES_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventDocumentsTab = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_DOCUMENTS_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

export const permissionsForEventSettingsTab = activeUserPermissionNames => {
  const permissionsRequired = [
    "EVENT_SETTINGS_VIEW",
  ];
  return permissionsRequired.every(name => activeUserPermissionNames.includes(name));
};

const isInRole= (user, role) => {
  return user.security_role_name === role;
};