const Joi = require("joi");

export const required = value => value ? null : "Required";
// export const minLength = min => value =>
// 	value && value.length < min ? `Must be ${min} characters or more` : null;
// export const maxLength = max => value =>
// 	value && value.length > max ? `Must be ${max} characters or less` : null;
export const minLength2 = value =>
  value && value.length < 2 ? `Must be ${3} characters or more` : null;
export const minLength3 = value =>
  value && value.length < 3 ? `Must be ${3} characters or more` : null;
export const maxLength15 = value =>
  value && value.length > 15 ? `Must be ${15} characters or less` : null;
export const maxLength35 = value =>
  value && value.length > 35 ? `Must be ${35} characters or less` : null;
export const maxLength50 = value =>
  value && value.length > 50 ? `Must be ${50} characters or less` : null;
export const maxLength100 = value =>
  value && value.length > 100 ? `Must be ${100} characters or less` : null;
export const maxLength256 = value =>
  value && value.length > 256 ? `Must be ${256} characters or less` : null;
export const maxLength500 = value =>
  value && value.length > 500 ? `Must be ${500} characters or less` : null;
export const maxLength1000 = value =>
  value && value.length > 1000 ? `Must be ${1000} characters or less` : null;
export const number = value => value && isNaN(Number(value)) ? "Must be a number" : null;
export const requiredNumber = value => {
  const num = parseFloat(value);
  if (!isNaN(num)) return true;
  return "Required";
};
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : null;
export const url = value => value && !/((ftp|http|https):\/\/)?:\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(value) ? "Must be a url with http:// or https://" : null;

export const eventsDateRange = values => {
  const errors = {};
  let pre_event_begin = values.pre_event_begin ? new Date(values.pre_event_begin) : null;
  let pre_event_end = values.pre_event_end ? new Date(values.pre_event_end) : null;
  let event_begin = values.event_begin ? new Date(values.event_begin) : null;
  let event_end = values.event_end ? new Date(values.event_end) : null;
  let post_event_begin = values.post_event_begin ? new Date(values.post_event_begin) : null;
  let post_event_end = values.post_event_end ? new Date(values.post_event_end) : null;

  if (pre_event_end && pre_event_begin && (pre_event_end < pre_event_begin)) {
    errors.pre_event_end = "Invalid Range.";
  }
  if (event_end && event_begin && (event_end < event_begin)) {
    errors.event_end = "Invalid Range.";
  }
  if (post_event_end && post_event_begin && (post_event_end < post_event_begin)) {
    errors.post_event_end = "Invalid Range.";
  }
  if (event_begin && pre_event_end && (event_begin < pre_event_end)) {
    errors.event_begin = "Invalid Start Date.";
  }
  if (post_event_begin && event_end && (post_event_begin < event_end)) {
    errors.post_event_begin = "Invalid Start.";
  }
  return errors;
};

//Use Joi to leverage built in email validation
const emailSchema = Joi.object({
  value: Joi.string().email({ tlds: { allow: false } }).trim()
});


const emailValidate = param => {
  const { error } = emailSchema.validate({ value: param });
  if (error) {
    return "Invalid email address";
  } else {
    return null;
  }
};

export const email = value => value && emailValidate(value);