import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import { getPermissions } from "actions/permissions";
import UserList from "./users_list/user_list";
import TabList from "components/tabs/tablist";
import Tab from "components/tabs/tab";
import Header from "components/header/header";
import UserSettingsForm from "./user_settings_form";
import { getUsers, getUser, updateUser } from "actions/users";
import { getRoles } from "actions/roles";
import withAuth from "components/hoc/with_auth";

import "assets/css/list.css";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  async componentDidMount() {
    await this.props.getPermissions();
    this.loadData();
  }

  loadData() {
    this.props.getUsers();
    this.props.getRoles();
  }

	onOpenModal = () => {
	    this.setState({ open: true });
	    this.props.getUser({}); //clear for insert
	};

	onCloseModal = () => {
	    this.setState({ open: false });
	    this.loadData();
	};

	renderButton() {
	    let { permissions } = this.props;
	    if (!permissions || !permissions.canRenderUsersAddButton) return null;

	    return (
	        <button onClick={this.onOpenModal} className="btn btn-primary-no-margin-left">
				+ USER
	        </button>
	    );
	}

	render() {
	    let { users } = this.props;

	    return (
	        <Fragment>
	            <Header />
	            <TabList>
	                <Tab activeTab={true} title="USERS" to="/settings/users" />
	            </TabList>
	            <div className="container-fluid tabs-spacer">
	                <h1 className="page-header">Users</h1>
	                {this.renderButton()}
	                <div className="body-content-partial">
	                    <UserList items={users}></UserList>
	                </div>
	            </div>
	            <Modal open={this.state.open} onClose={this.onCloseModal} classNames={{ modal: "custom-modal" }} >
	                <UserSettingsForm onClose={this.onCloseModal} title="New User" onSave={this.onCloseModal} operation="save" />
	            </Modal>
	        </Fragment>
	    );
	}
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
    roles: state.roles,
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
  getUsers,
  getUser,
  getRoles,
  updateUser
})(withRouter(withAuth(Users)));