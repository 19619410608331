import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ProfileTab from "components/profile/header/profile_dashboard_tab";
import { changeEventDashboardStatus } from "actions/event_dashboard";

import "./profile_dashboard_tabs.css";

const tabs = [
  // {
  //     title: "Pre",
  //     timeframe: "pre",
  //     url_path: "pre-event"
  // },
//   {
//     title: "During",
//     timeframe: "during",
//     url_path: "event"
//   },
  {
    title: "Event Summary",
    timeframe: "post",
    url_path: "post-event"
  }
];

class ProfileDashboardTabs extends Component {

  async componentDidMount() {
    await this.props.setDashboardTimeframe();
    this.props.updateRoute();
  }

  async onTabClick(tab) {
    await this.props.changeEventDashboardStatus(null, tab.timeframe);
  }

  render() {
    const { 
      fuzion_event_id,
      quant_event_profile_id
    } = this.props;
    return (
      <div className="profile-dashboard-tabs-header-links">
        {
          tabs.map(tab =>
            <ProfileTab
              key={tab.timeframe}
              title={tab.title}
              to={`/events/profile/${fuzion_event_id}/${tab.timeframe}/${quant_event_profile_id}`}
              isActiveTab={this.props.timeframe === tab.timeframe}
              onClick={() => this.onTabClick(tab)}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    eventProfile,
    eventDashboard,
    event
  } = state;

  return {
    fuzion_event_id: event.fuzion_event_id,
    quant_event_profile_id: eventProfile.quant_event_profile_id,
    timeframe: eventDashboard.timeframeLabel
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    updateRoute: () => {
      const { fuzion_event_id, dashboard_timeframe, quant_event_profile_id } = ownProps.match.params;
      const activeTimeframeMap = {
        "pre": "pre-event",
        "during": "event",
        "post": "post-event"
      };
      const timeframe = activeTimeframeMap[stateProps.timeframe] || dashboard_timeframe;
      ownProps.history.replace(`/events/profile/${fuzion_event_id}/${timeframe}/${quant_event_profile_id}`);
    },
    setDashboardTimeframe: async () => {
      const { dashboard_timeframe } = ownProps.match.params;
      const activeTimeframeMap = {
        "pre-event": "pre",
        "event": "during",
        "post-event": "post"
      };
      const timeframe = activeTimeframeMap[dashboard_timeframe];
      await dispatchProps.changeEventDashboardStatus(null, timeframe);
    }
  };
};

export default withRouter(connect(mapStateToProps, {
  changeEventDashboardStatus,
},
mergeProps
)(ProfileDashboardTabs));