import { Component } from "react";

class Fullscreen extends Component {
    browserFullscreenHandlers = {
      "firefox": {
        on: (element) => element.mozRequestFullScreen(element),
        off: () => document.mozCancelFullScreen(),
      },
      "safari": {
        on: (element) => element.webkitRequestFullScreen(element),
        off: () => document.webkitExitFullscreen(),
      },
      "default": {
        on: (element) => element.webkitRequestFullScreen(element),
        off: () => document.exitFullscreen(),
      },
    };

    componentDidMount() {
      this.determineBrowser();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.isFullscreen === this.props.isFullscreen) return;
      if (this.props.isFullscreen) {
        this.requestFullscreen();
      } else {
        this.exitFullScreen();
      }
    }

    determineBrowser = () => {
      if (document.webkitRequestFullScreen) {
        this.browser = "safari";
        this.fullscreenAvailable = true;
      } else if (document.mozFullScreenEnabled) {
        this.browser = "firefox";
        this.fullscreenAvailable = true;
      } else if (document.fullscreenEnabled) {
        this.browser = "default";
        this.fullscreenAvailable = true;
      } else {
        this.fullscreenAvailable = false;
      }
    }

    requestFullscreen = () => {
      if (!this.fullscreenAvailable) return;
      const el = this.props.element ? this.props.element : document.documentElement;
      this.browserFullscreenHandlers[this.browser].on(el);
    }

    exitFullScreen = () => {
      if (!this.fullscreenAvailable) return;
      this.browserFullscreenHandlers[this.browser].off();
    }

    render() {
      return null;
    }
}

export default Fullscreen;