import React, { Component } from "react";
import { connect } from "react-redux";

import { getPermissions } from "actions/permissions";
import ListIcon from "components/list/list_icon";
import iconDocumentDark from "assets/images/iconDocumentDark.svg";
import iconGroupDark from "assets/images/iconGroupDark.svg";
import iconSettingsDark from "assets/images/iconSettingsDark.svg";

class EventControls extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  renderProfilesIcon(item, permissions) {
    if (!permissions || !permissions.canRenderEventControlsProfilesButton) return null;
        
    return (
      <ListIcon iconImg={iconGroupDark} iconTo={`/event-profiles/${item.fuzion_event_id}`} eventId={item.fuzion_event_id} item={item} tooltip="Profiles"/>
    );
  }
    
  renderDocumentsIcon(item, permissions) {
    if (!permissions || !permissions.canRenderEventControlsDocumentsButton) return null;

    return (
      <ListIcon iconImg={iconDocumentDark} iconTo={`/event-documents/${item.fuzion_event_id}`} eventId={item.fuzion_event_id} item={item} tooltip="Documents"/>
    );
  }
    

  renderSettingsIcon(item, permissions) {
    if (!permissions || !permissions.canRenderEventControlsSettingsButton) return null;
                    
    return (
      <ListIcon iconImg={iconSettingsDark} iconTo={`/event-settings/${item.fuzion_event_id}`} eventId={item.fuzion_event_id} item={item} tooltip="Settings"/>
    );
  }
    
  render() {
    let { item, permissions } = this.props;

    return (
      <div className="list-image-section">
        {this.renderSettingsIcon(item, permissions)}
        {this.renderDocumentsIcon(item, permissions)}
        {this.renderProfilesIcon(item, permissions)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(EventControls);
