import React from "react";
import Tippy from "@tippy.js/react";

import iconChevronLeftLight from "assets/images/iconChevronLeftLight.svg";

export default function MenuBackButton(props) {
  return (
    <Tippy content={<span id="tooltip-content">Back</span>}>
      <button onClick={props.onClick} className="profileHeaderIcon">
        <img src={iconChevronLeftLight} alt="Back"/>
      </button>
    </Tippy>
  );
}