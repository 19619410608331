import React, { useState } from "react";
import Modal from "react-responsive-modal";

import Cognito from "../../lib/auth/cognito";

export default function DataCollectionNotice() {
  // const [accepted, setAccepted] = useState(false);
  const [open, setOpen] = useState(true);

  const onAccept = () => {
    // setAccepted(true);
    setOpen(false);
    Cognito().login();
  };

  const onDoNotAccept = () => {
    //kick user out for not accepting terms.
    Cognito().logout();
  };

  return (
    <Modal open={open} onClose={onDoNotAccept} closeOnOverlayClick={false} showCloseIcon={false} closeOnEsc={false} classNames={{ modal: "custom-modal" }}>
      <div style={{paddingTop: "20px"}}>
        <h1 className="page-header">Data Privacy and Terms of Use Notice</h1>
        <div>
          <p>
                    At various times Freeman or its agents may collect personally identifiable information. 
                    Freeman will use your personally identifiable information to perform services you have contracted from us, where necessary for our legitimate interests (that are not overridden by your fundamental rights), to comply with a legal or regulatory obligation and as further detailed in each Freeman Application.
            <br/>
            <br/>
                    Personally identifiable information may include your name, email address, and professional association.
            <br/>
            <br/>
                    Learn more:<br/>
            <a href="https://www.freeman.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a><br/>
            <a href="https://www.freeman.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a><br/>
          </p>
        </div>
        <div className="align-right">
          <button onClick={onDoNotAccept}className="btn">I DO NOT ACCEPT</button>
          <button onClick={onAccept}className="btn btn-primary">I ACCEPT</button>
        </div>
      </div>
    </Modal>
  );
}
