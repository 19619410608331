import React from "react";

const select = field => {
  let { touched, error, pristine } = field.meta;

  const renderSelectOptions = (key, index) => {
    return (
      <option key={index} value={key}>
        {field.options[key]}
      </option>
    );
  };

  const placeholder = field.placeholder || "Select an option...";

  return (
    <div>
      <select {...field.input} className={pristine ? "form-control select placeholder" : "form-control select"} style={field.bgColor} disabled={field.disabled}>
        {field.hideplaceholder === "true" ?
          "" :
          <option key="-1" value="" disabled >{placeholder}</option>}
        {Object.keys(field.options).map(renderSelectOptions)}
      </select>
      <span className="text-danger">
        {touched ? error : ""}
      </span>
    </div>
  );
};

export default select;