import React, { useState, useEffect } from "react";
import { request } from "lib/data/api";
import { useStore } from "react-redux";
import styles from "./user-settings.module.css";
import cognitoStatuses from "./utils/cognito_statuses";

export default function UserCognitoSettings(props) {
  let { username, setPassword } = props;
  const [userCognitoState, setUserCognitoState] = useState("");
  const [userCognitoStateDesc, setUserCognitoStateDesc] = useState("");
  const store = useStore();

  useEffect(() => {
    async function fetchData() {
      const response = await request({
        state: store.getState(),
        method: "GET",
        route: `/v1/quant-user-profile/cognitouser/${username}`,
      });
      if (response.payload !== null) {
        setUserCognitoState(response.payload.UserStatus);
        const desc = cognitoStatuses[response.payload.UserStatus] || "";
        setUserCognitoStateDesc(desc);
      }
    }
    fetchData();
  }, [username, setPassword, store]);

  return (
    <div>
      <div className={styles.cognitoSettingsContainer}>
        <h4 className="page-header">Cognito Status:</h4>
        <span className={`page-header ${styles.cognitoSettingsValue}`}>{userCognitoState && userCognitoState.toUpperCase()}</span>
      </div>
      <div className={styles.cognitoSettingsContainer}>
        <h4 className="page-header">Description:</h4>
        <span className={`page-header ${styles.cognitoSettingsValue}`}>{userCognitoStateDesc}</span>
      </div>
    </div>
  );
}