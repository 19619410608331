import types from "types/events";

export default (state = null, action) => {
  switch (action.type) {
    case types.GET_EVENTS:
      return action.payload;
    case types.GET_EVENTS_BY_USER_PROFILE:
      return action.payload;
    default:
      return state;
  }
};
