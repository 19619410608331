import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from "react-responsive-modal";

import DocumentListForm from "components/profile/document_list_form";
import UserSettingsForm from "components/profile/user_settings_form/user_settings_form";
import DownloadPdfForm from "./download_pdf_form";
import EventAccess from "./event_access/";
import EventSettingsForm from "components/profile/event_settings_form/event_settings_form"; 
import { saveEventProfileMetricOrder } from "actions/event_profile_metrics";
import MenuBackButton from "components/header/menu_back_button";
import UsersButton from "./header_icons/users_button";
import ProfileSettingsButton from "./header_icons/settings_button";
import EventSettingsButton from "./header_icons/event_settings_button";
import DocumentsButton from "./header_icons/documents_button";
import FullscreenButton from "./header_icons/fullscreen_button";
import { getAdminEventProfiles } from "actions/event_profiles";

import "assets/css/header.css";
import "./profile_header.css";

class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentListOpen: false,
      profileSettingsModalOpen: false,
      downloadPDFModalOpen: false,          
      userProfileAccessModalOpen: false,
      eventSettingsModalOpen: false
    };
  }

    openDownloadPDFModal = e => {
      this.setState({ downloadPDFModalOpen: true });
    }

    openProfileSettingsModal = e => {
      this.setState({ profileSettingsModalOpen: true });
    }

    openUserProfileAccessModal = e => {
      this.setState({ userProfileAccessModalOpen: true });
    }
   
    openDocumentList = e => {
      this.setState({ documentListOpen: true });
    }

    openEventSettingsModal = e => {
      this.setState({ eventSettingsModalOpen: true });
    }

    onCloseModal = () => {
      this.setState({
        documentListOpen: false,
        profileSettingsModalOpen: false,
        downloadPDFModalOpen: false,
        userProfileAccessModalOpen: false,
        eventSettingsModalOpen: false
      });
    };

    render() {
      return (
        <nav className="navbar">
          <div className="navbar-left">
            <MenuBackButton onClick={() => this.props.history.goBack()} />
          </div>
          <div>
            <div className="navbar-form navbar-right">
              <UsersButton onClick={this.openUserProfileAccessModal} />
              <ProfileSettingsButton onClick={this.openProfileSettingsModal} />
              <DocumentsButton onClick={this.openDocumentList} />
              <EventSettingsButton onClick={this.openEventSettingsModal} />
              <FullscreenButton />
            </div>
          </div>
          <Modal open={this.state.documentListOpen} onClose={this.onCloseModal} classNames={{ modal: "custom-modal" }} >
            <DocumentListForm title="Documents" onSave={this.onCloseModal} operation="insert" eventId={this.props.eventId} />
          </Modal>
          <Modal open={this.state.profileSettingsModalOpen} onClose={this.onCloseModal} classNames={{ modal: "profile-header-modal" }}>
            <UserSettingsForm title="Settings" onSave={this.onCloseModal} currentEventProfile={this.props.eventProfile} onCloseModal={this.onCloseModal} operation="insert" eventId={this.props.eventId} />
          </Modal>
          <Modal open={this.state.downloadPDFModalOpen} onClose={this.onCloseModal} classNames={{ modal: "custom-modal" }} >
            <DownloadPdfForm title="Download PDF" onSave={this.onCloseModal} operation="insert" eventId={this.props.eventId} />
          </Modal>
          <Modal open={this.state.eventSettingsModalOpen} onClose={this.onCloseModal} classNames={{ modal: "custom-modal" }}>
            <EventSettingsForm title="Event Settings" onSave={this.onCloseModal} currentEventProfile={this.props.eventProfile} onCloseModal={this.onCloseModal} operation="insert" eventId={this.props.eventId} />
          </Modal>
          <Modal open={this.state.userProfileAccessModalOpen} onClose={this.onCloseModal} classNames={{ modal: "profile-header-modal" }}>
            <EventAccess />
          </Modal>
        </nav>
      );
    }
}

const mapStateToProps = state => {
  return {
    eventProfile: state.eventProfile
  };
};

export default connect(mapStateToProps, {
  saveEventProfileMetricOrder,
  getAdminEventProfiles,

})(withRouter(ProfileHeader));
