import React, { Component } from "react";
import { connect } from "react-redux";

import { setNotification } from "actions/notifications";

import "./notifications.css";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: {type: "", response: ""}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications.response && this.props.notifications.type) {
      setTimeout (() => {
        this.props.setNotification({});
      },5000);
    }
  }

  render() {
    return (
      <>
        {this.props.notifications.type && this.props.notifications.response &&
                    <div className={`fixed-bottom fade-in notification ${this.props.notifications.type}`}>
                      <div className="notificationContainer">
                        <p className="justify-content-center notificationText">{this.props.notifications.response}</p>
                      </div>
                    </div>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};

export default connect( mapStateToProps, { setNotification })(Notifications);
