import { notificationMessage } from "fuzion-core-lib";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DatePicker from "react-datepicker";

import { updateEvent } from "actions/events";
import { saveEventProfile } from "actions/event_profiles";
import { required } from "lib/validation/form_validation_rules";
import { setNotification } from "actions/notifications";
import withAuth from "components/hoc/with_auth";
import renderClassNames from "lib/ui/render_class_names";
import formHelper from "lib/ui/form";
import convertToDate from "lib/util/convert_to_date";

class EventsProfileEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_name: {
        key: "profile_name",
        value: "",
        valid: true,
        validation: [required]
      },
      template_name: {
        key: "template_name",
        value: 1,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      pre_event_begin: {
        key: "pre_event_begin",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      pre_event_end: {
        key: "pre_event_end",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_begin: {
        key: "event_begin",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_end: {
        key: "event_end",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      post_event_begin: {
        key: "post_event_begin",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      post_event_end: {
        key: "post_event_end",
        value: null,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
    };
  }

    onSubmit = () => {
      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        this.setState(form.formState);
        return;
      }
      this.setState(form.formState, async () => {
        const formValues = formHelper.getFormValues(this.state);
        formValues.fuzion_event_id = this.props.eventId;
        formValues.isDefault = false;
        this.props.saveEventProfile(formValues, (newEventProfile) => {
          this.props.onClose();
          this.props.setNotification(notificationMessage.SUCCESS_ADD);
          this.props.history.push(`/events/profile/${newEventProfile.fuzion_event_id}/pre-event/${newEventProfile.quant_event_profile_id}`);
        });
      });
    }

    onChange = ({ target: { name, value, type } }) => {
      if (type === "number") value = parseInt(value);
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onBlur = (e) => {
      const { target: { name, value } } = e;
      const validation = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, validation));
    }

    onDateChange = (name, value) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    determineDate = (startDate, endDate) => {
      if (!startDate) return endDate;
      if (!endDate) return startDate;
      return startDate > endDate ? startDate : endDate;
    }

    renderTitle() {
      return (
        <div>
          <h4 className="modal-title">
            {this.props.title}
          </h4>
        </div>
      );
    }

    renderCancelButton(options) {
      return (
        <button type="button"
          disabled={options.submitting}
          onClick={this.props.onSave}
          name="cancelButton"
          className={options.otherStyle}
        >
                CANCEL
	        </button>
      );
    }

    render() {
      let { initialValues, operation } = this.props;
      let isInsert = operation || !initialValues;
      const otherStyle = renderClassNames({
        "btn": true,
        "btn-default": true,
        "pull-right": isInsert
      });
      const submitStyle = renderClassNames({
        "btn": true,
        "btn-primary": true,
        "pull-right": isInsert
      });
        
      return (
        <div ref={node => {
          this.node = node;
        }} className="main-admin-container">
          {this.props.title ? this.renderTitle() : "Add Profile"}
          <div className="modal-form">
            <div className="form-group">
              <div className="field-label">
                            Name *
              </div>
              <div>
                <input
                  name={this.state.profile_name.key}
                  placeholder="Profile Name"
                  value={this.state.profile_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className="form-control"
                />
                {!this.state.profile_name.valid &&
                                (<div className="text-danger">{this.state.profile_name.errorMessage}</div>)
                }
              </div>
            </div>
            {/* Template is not used */}
            {/* <div className="form-group">
                        <div className="field-label">
                            Template
	                    </div>
                        <div>
                            <select
                                name={this.state.template_name.key}
                                value={this.state.template_name.value}
                                disabled={true}
                                className="form-control"
                            >
                                <option value="1">None (blank)</option>
                            </select>
                            {!this.state.template_name.valid &&
                                (<div className="text-danger">{this.state.template_name.errorMessage}</div>)
                            }
                        </div>
                    </div> */}
            <div className="form-group">
              <div className="field-label">
                            Pre Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.state.pre_event_begin.value}
                    onChange={(val) => this.onDateChange(this.state.pre_event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                  />
                  {!this.state.pre_event_begin.valid &&
                                    (<div className="text-danger">{this.state.pre_event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.pre_event_begin.value, this.state.pre_event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.pre_event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.state.pre_event_begin.value)}
                  />
                  {!this.state.pre_event_end.valid &&
                                    (<div className="text-danger">{this.state.pre_event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.pre_event_end.value, this.state.event_begin.value)}
                    onChange={(val) => this.onDateChange(this.state.event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.pre_event_end.value))}
                  />
                  {!this.state.event_begin.valid &&
                                    (<div className="text-danger">{this.state.event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.event_begin.value, this.state.event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.event_begin.value))}
                  />
                  {!this.state.event_end.valid &&
                                    (<div className="text-danger">{this.state.event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Post Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.event_end.value, this.state.post_event_begin.value)}
                    onChange={(val) => this.onDateChange(this.state.post_event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.event_end.value))}
                  />
                  {!this.state.post_event_begin.valid &&
                                    (<div className="text-danger">{this.state.post_event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.post_event_begin.value, this.state.post_event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.post_event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.post_event_begin.value))}
                  />
                  {!this.state.post_event_end.valid &&
                                    (<div className="text-danger">{this.state.post_event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group flex-display">
              <div className="field-label required-label">
                            * Required
	                    </div>
              <div className="align-right">
                <button type="button"
                  onClick={this.props.onSave}
                  name="cancelButton"
                  className={otherStyle}
                >
                                CANCEL
	                        </button>
                <button type="buton"
                  name="saveEventButton"
                  className={submitStyle}
                  onClick={this.onSubmit}
                >
                                ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default connect(null, {
  updateEvent,
  saveEventProfile,
  setNotification
})(withRouter(withAuth(EventsProfileEditForm)));
