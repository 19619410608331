import React, { useState } from "react";
import Cognito from "../../lib/auth/cognito";
import Modal from "react-responsive-modal";
// import { CognitoContext } from "components/cognito_provider";
// import { request } from "../../lib/data/api";


const formStage = {
  first: "PASSWORD",
  second: "FORCE_RESET",
  third: "COOKIE_NOTICE",
  fourth: "DATA_COLLECTION"
};

export default function CookieNotice(props) {
  // const [accepted, setAccepted] = useState(false);
  // const { cognitoUser } = useContext(CognitoContext);
  const [open, setOpen] = useState(true);

  const onClick = async () => {
    props.setFormStage(formStage.fourth);
    setOpen(false);
  };

  const onCloseModal = () => {
    // this shouldn't ever be called, but it is a required prop by <Modal/>
    Cognito().logout();
  };

  return (
    <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false} showCloseIcon={false} closeOnEsc={false} classNames={{ modal: "custom-modal" }}>
      <div style={{paddingTop: "20px"}}>
        <h1 className="page-header">Cookie Notice</h1>
        <div>
          <p>
                    This site uses cookies and other technologies, so we can remember you and understand how you and other visitors use our site. By continuing to browse, cookies will be set on your device.
            <br/>
            <br/>
                    For more information, see our <a href="https://www.freeman.com/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>.
          </p>
        </div>
        <div className="align-right">
          <button onClick={onClick}className="btn btn-primary">OK</button>
        </div>
      </div>
    </Modal>
  );
}
