import { request } from "lib/data/api";
import global from "types/global";
import types from "types/event_profiles";

import axios from "axios";
export const GET_EVENT_PROFILES = "GET_EVENT_PROFILES";
export const GET_EVENT_PROFILES_FOR_METRICS_VIEW = "GET_EVENT_PROFILES_FOR_METRICS_VIEW";
export const GET_EVENT_PROFILE = "GET_EVENT_PROFILE";
export const SAVE_EVENT_PROFILE = "SAVE_EVENT_PROFILE";
export const UPDATE_EVENT_PROFILE = "UPDATE_EVENT_PROFILE";
export const DELETE_EVENT_PROFILE = "DELETE_EVENT_PROFILE";
export const UPDATE_DEFAULT_EVENT_PROFILE = "UPDATE_DEFAULT_EVENT_PROFILE";

export const getEventProfiles = (fuzion_event_id) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-user-profile/${state.activeUser.quant_user_profile_id}/event-profiles/${fuzion_event_id}`
      });
      dispatch({
        type: types.GET_EVENT_PROFILES,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.getEventProfiles failed"
      });
    }
  };
};

export const getAdminEventProfiles = eventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event-profile/${eventId}/admin`,
      });
      dispatch({
        type: types.GET_EVENT_PROFILES,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.getAdminEventProfiles failed"
      });
    }
  };
};

export const getDefaultEventProfile = eventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event-profile/${eventId}/default`
      });
      return dispatch({
        type: types.GET_EVENT_PROFILE,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.getDefaultEventProfile failed"
      });
    }
  };
};

export const getEventProfileById = (eventId, eventProfileId) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/quant-event-profile/${eventProfileId}`,
        eventId
      });
      return dispatch({
        type: types.GET_EVENT_PROFILE,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.getEventProfileById failed"
      });
    }
  };
};

export const getUserProfiles = (eventId, event_profiles, callback) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: eventId,
      event_profiles: event_profiles
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-profile`,
      headers: headers
    });
    request.then(result => {
      let eventProfiles = result.data.payload;
      if (result.status === 200) {
        dispatch({
          type: GET_EVENT_PROFILES_FOR_METRICS_VIEW,
          payload: eventProfiles
        });
        callback(eventProfiles);
      }
    });
  };
};

export const updateEventProfile = formData => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state,
        route: `/v1/quant-event-profile/${formData.quant_event_profile_id}`,
        data: formData
      });
      return dispatch({
        type: UPDATE_EVENT_PROFILE,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.updateEventProfile failed"
      });
    }
  };
};

export const updateDefaultEventProfile = (data, callback) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: data.fuzion_event_id
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-profile/default/${data.quant_event_profile_id}`,
      data: data,
      headers: headers
    }).then(() => callback());
  };
};

export const saveEventProfile = formData => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state,
        route: "/v1/quant-event-profile",
        data: formData
      });
      return dispatch({
        type: UPDATE_EVENT_PROFILE,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.saveEventProfile failed"
      });
    }
  };
};

export const deleteEventProfile = formData => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state,
        route: `/v1/quant-event-profile/${formData.quant_event_profile_id}`,
        data: formData
      });
      return dispatch({
        type: DELETE_EVENT_PROFILE,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.deleteEventProfile failed"
      });
    }
  };
};

export const updateEventProfileURL = (formData, quant_event_profile_id) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state,
        route: `/v1/quant-event-profile/dashboard-urls/${quant_event_profile_id}`,
        data: formData
      });
      return dispatch({
        type: UPDATE_EVENT_PROFILE,
        payload: response.payload.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.updateEventProfileURL failed"
      });
    }
  };
};

export const updateInsight = (eventProfileId, formData) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "PUT",
        route: `/v1/quant-event-profile/insights/${eventProfileId}`,
        data: formData
      });
      dispatch({
        type: types.UPDATE_EVENT_PROFILE,
        payload: response.payload.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.updateInsight failed"
      });
    }
  };
};

export const addInsight = (eventProfileId, formData) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "POST",
        route: `/v1/quant-event-profile/insights/${eventProfileId}`,
        data: formData
      });
      dispatch({
        type: types.UPDATE_EVENT_PROFILE,
        payload: response.payload.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.addInsight failed"
      });
    }
  };
};

export const deleteInsight = (eventProfileId, formData) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        state: state,
        method: "DELETE",
        route: `/v1/quant-event-profile/insights/${eventProfileId}`,
        data: formData
      });
      dispatch({
        type: types.UPDATE_EVENT_PROFILE,
        payload: response.payload.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_profiles.deleteInsight failed"
      });
    }
  };
};

export const reorderInsights = (timeframe, oldIndex, newIndex) => {
  return (dispatch, getState) => {
    const state = getState();
    const timeframeProp = `${timeframe}_insights`;
    const insights = state.eventProfile[timeframeProp];
    const newInsights = Array.from(insights);
    const [removed] = newInsights.splice(oldIndex, 1);
    newInsights.splice(newIndex, 0, removed);
    if (JSON.stringify(insights) !== JSON.stringify(newInsights)) {
      const updatedEventProfile = { ...state.eventProfile, [timeframeProp]: newInsights };
      dispatch({
        type: types.UPDATE_EVENT_PROFILE,
        payload: updatedEventProfile
      });
      dispatch(updateEventProfile(updatedEventProfile));
    }
  };
};