import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import ActiveUserReducer from "./active_user";
import EventDocuments from "./documents";
import ErrorReducer from "./error";
import EventReducer from "./event";
import EventsReducer from "./events";
import EventDashboardReducer from "./event_dashboard";
import EventProfileReducer from "./event_profile";
import EventsProfilesReducer from "./event_profiles";
import EventsProfileDetailReducer from "./event_profile_details";
import EventProfileEditMode from "./event_profile_edit_mode";
import EventProfileMetricsReducer from "./event_profile_metrics";
import IdentityUserReducer from "./identity_user";
import Insight from "./insight";
import Insights from "./insights";
import MetricsReducer from "./metrics";
import MetricSettings from "./metric_settings";
import MetricSettingsMenu from "./metric_settings_menu";
import NotificationsReducer from "./notifications";
import Permissions from "./permissions";
import RoleReducer from "./role";
import RolesReducer from "./roles";
import SideBarReducer from "./sidebar";
import UserReducer from "./user";
import UsersReducer from "./users";

const rootReducer = combineReducers({
  error: ErrorReducer,
  form: formReducer,
  user: UserReducer,
  users: UsersReducer,
  events: EventsReducer,
  event: EventReducer,
  sidebar: SideBarReducer,
  roles: RolesReducer,
  role: RoleReducer,
  notifications: NotificationsReducer,
  metrics: MetricsReducer,
  metricSettings: MetricSettings,
  metricSettingsMenu: MetricSettingsMenu,
  eventProfile: EventProfileReducer,
  eventProfiles: EventsProfilesReducer,
  eventProfileDetails: EventsProfileDetailReducer,
  eventProfileMetrics: EventProfileMetricsReducer,
  eventProfileEditMode: EventProfileEditMode,
  eventDashboard: EventDashboardReducer,
  documents: EventDocuments,
  identityUser: IdentityUserReducer,
  activeUser: ActiveUserReducer,
  insights: Insights,
  insight: Insight,
  permissions: Permissions,
});

export default rootReducer;
