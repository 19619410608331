import {
  GET_EVENT_PROFILES_DEFAULT
} from "actions/event_profile_details";

export default (state = [], action) => {
  switch (action.type) {
    case GET_EVENT_PROFILES_DEFAULT:
 			return action.payload;
    default:
      return state;
  }
};
