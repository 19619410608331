import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setSideBarVisibility } from "actions/sidebar";
import MenuButton from "./menu_button";
import MenuBackButton from "./menu_back_button";
import renderClassNames from "lib/ui/render_class_names";

import "assets/css/header.css";

class Header extends Component {
	toggleSideBar = () => {
	    this.props.setSideBarVisibility(!this.props.sidebar.isVisible);
	}

	render() {
	    const { sidebar, detailView } = this.props;
	    const navClass = renderClassNames({
	        "navbar": true,
	        "sidebar-spacer": sidebar.isVisible
	    });
	    let button = null;
	    if (detailView) {
	        button = <MenuBackButton onClick={() => this.props.history.push("/settings/users")} />;
	    } else {
	        button = <MenuButton onClick={this.toggleSideBar} />;
	    }

	    return (
	        <nav className={navClass}>
	            <div className="navbar-left navbar-container">
	                {button}
	            </div>
	        </nav>
	    );
	}
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps, {
  setSideBarVisibility,
})(withRouter(Header));