import global from "types/global";
import types from "types/sidebar";

export const setSideBarVisibility = isVisible => {
  return (dispatch) => {
    try {
      dispatch({
        type: types.SET_SIDEBAR_VISIBILITY,
        payload: {
          isVisible: isVisible
        }
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.sidebar.setSideBarVisibility failed"
      });
    };
  };
};