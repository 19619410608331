import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import DashboardSettingsForm from "components/profile/dashboard_settings_form";
import iconPollChart from "assets/images/iconPollDark.svg";

import "./dashboard_empty_state.css";

class DashboardEmptyState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardURLModalOpen: false
    };
  }

    onCloseModal = () => {
      this.setState({dashboardURLModalOpen: false});
    }

    renderDashboardEmptyStateButton() {
      const { permissions } = this.props;
      if (permissions.isAdmin) {
        return <button type="button" onClick={() => this.setState({dashboardURLModalOpen: true})} className="btn btn-primary">ADD URL</button>;
      }
    }

    render() {
      return (
        <>
          <div className="dashboard-container-empty">
            <div className="dashboard-content-empty">
              <img src={iconPollChart} alt="icon chart" className="dashboard-image-empty"/>
              <h3 className="dashboard-header-empty-state">
                        Nothing to see... yet.
              </h3>
              <p className="dashboard-text-empty-state">
                        We're still preparing your dashboard. Please check back shortly.
              </p>
              {this.renderDashboardEmptyStateButton()}
            </div>
          </div>
          <Modal open={this.state.dashboardURLModalOpen} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
            <DashboardSettingsForm onClose={this.onCloseModal}/>
          </Modal>
        </>
      );    
    };
}
const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};
export default connect(mapStateToProps)(DashboardEmptyState);
