import React, { createContext, useState, useEffect, useCallback } from "react";
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from "amazon-cognito-identity-js";

const CognitoContext = createContext();

const CognitoProvider = (props) => {
  const Pool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  });
  const [isAuthenticated, setAuth] = useState(false);
  const [userAccessToken, setUserAccessToken] = useState();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [userAttributes, setUserAttributes] = useState();
  const [cognitoUser, setCognitoUser] = useState();

  const getSession = useCallback(async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  }, [Pool]);

  useEffect(() => {
    getSession().then((session) => {
      setUserAccessToken(session.getAccessToken().getJwtToken());
      setAuth(true);
    }).catch();
  }, [getSession]);

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: function(userAttributes, requiredAttributes) {
          delete userAttributes.email_verified;

          setUserAttributes(userAttributes);
          setNewPasswordRequired(true);
          resolve("NEW_PASSWORD_REQUIRED");
        }
      });
      setCognitoUser(user);
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      window.location.reload();
    }
  };

  return (
    <CognitoContext.Provider
      value={{
        isAuthenticated,
        userAccessToken,
        authenticate,
        getSession,
        logout,
        newPasswordRequired,
        userAttributes,
        cognitoUser
      }}
    >
      {props.children}
    </CognitoContext.Provider>
  );
};

export { CognitoProvider, CognitoContext };
