import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { notificationMessage } from "fuzion-core-lib";

import EventViewerDialog from "./event_access_viewer_dialog";
import EventViewerEdit from "./event_access_viewer_edit";
import Icon from "components/icons/icon";
import AddIcon from "assets/images/iconAddCircleDark.svg";
import UserIcon from "assets/images/iconUserDark.svg";
import AvatarUser from "assets/images/avatarUser.png";
import iconDeleteDark from "assets/images/iconDeleteDark.svg";
import iconEditDark from "assets/images/iconEditDark.svg";
import userTypes from "./user_types";
import { getUsers, updateUser } from "actions/users";
import { getRoles } from "actions/roles";
import { setNotification } from "actions/notifications";
import UserImportTabs from "../bulk_upload/tabs";

class EventAccess extends Component {
  constructor() {
    super();
    this.state = {
      modal: {
        key: "modal",
        isOpen: false
      },
      viewer_edit_modal: {
        key: "viewer_edit_modal",
        isOpen: false
      },
      remove_modal: {
        key: "remove_modal",
        isOpen: false
      }
    };
  }

  onCloseModal = async () => {
    await this.props.getUsers();
    this.toggleModal(this.state.modal.key);
  }

  async componentDidMount() {
    await this.props.getUsers();
    await this.props.getRoles();
  }

  toggleModal = (key) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isOpen: !prevState[key].isOpen
      }
    }));
  }

  selectUser = (user) => {
    this.setState(prevState => ({
      ...prevState,
      user
    }));
    this.toggleModal(this.state.viewer_edit_modal.key);
  }

  deleteUser = (user) => {
    this.setState(prevState => ({
      ...prevState,
      user
    }));
    this.toggleModal(this.state.remove_modal.key);
  }

  removeUserFromEvent = async (user) => {
    const eventProfileIds = this.props.eventProfiles.map(ep => ep.quant_event_profile_id);
    user.events = user.events.filter(e => e !== this.props.event.fuzion_event_id);
    user.event_profiles = user.event_profiles ? user.event_profiles.filter(ep => !eventProfileIds.includes(ep)) : null;
    await this.props.updateUser(user, () => { });
    this.props.getUsers();
    this.toggleModal(this.state.remove_modal.key);
    this.props.setNotification(notificationMessage.SUCCESS_DELETE);
  }

  getModalBody = () => {
    const { event, userType } = this.props;
    switch (userType.type) {
      case userTypes.VIEWER.type:
        return (
          <EventViewerDialog
            onFinish={() => this.toggleModal(this.state.modal.key)}
            fuzion_event_id={event.fuzion_event_id}
            eventProfiles={this.props.eventProfiles}
          />
        );
      default:
        return null;
    }
  }

  canViewEditor = () => {
    const { userType, permissions } = this.props;
    return userType.type === userTypes.EDITOR.type && !(permissions.isDeveloper || permissions.isAdmin);
  }

  getUserList(label) {
    const { event, users, userType } = this.props;
    const list = users.filter(u => userType.role === u.security_role_name && (u.events && u.events.includes(event.fuzion_event_id)));

    if (!list || list.length === 0) {
      return (
        <div className="event-access-no-content">
          <div>
            <Icon src={UserIcon} />
            <div className="event-access-no-content-text">
              No {label} Added
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="event-access-user-container">
        {
          list.map(user => (
            <Fragment key={user.quant_user_profile_id}>
              <div className="list-item list-item-2">
                <div className="list-group-item" key={user.quant_user_profile_id}>
                  <div className="list-avatar-div">
                    <img src={AvatarUser} className="list-avatar-image" alt="List Profile" />
                  </div>
                  <div className="list-info-container">
                    <div className="list-info-details">
                      <div className="list-title">
                        {`${user.first_name} ${user.last_name}`}
                      </div>
                      <div className="status-div">
                        <div>Email: {user.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list-image-section">
                  <div className="list-other-items-div">
                    <Icon src={iconDeleteDark}
                      onClick={() => this.deleteUser(user)}
                      className="list-other-items-image" />
                  </div>
                  {userType.type === userTypes.VIEWER.type &&
                    <div className="list-other-items-div">
                      <Icon src={iconEditDark}
                        onClick={() => this.selectUser(user)}
                        className="list-other-items-image" />
                    </div>
                  }
                </div>
              </div>
            </Fragment>
          ))
        }
      </div>
    );
  }

  render() {
    const { userType } = this.props;
    if (this.canViewEditor()) return null;
    let label = "";
    switch (userType.type) {
      case userTypes.VIEWER.type:
        label = "Viewer";
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <h5>
          {userType.title}
          <Icon src={AddIcon} classes="event-access-add-icon" onClick={() => this.toggleModal(this.state.modal.key)} />
        </h5>
        <div className="event-access-info-text">
          {userType.info}
        </div>
        {this.getUserList(label)}
        <Modal open={this.state.modal.isOpen} onClose={this.onCloseModal} classNames={{ modal: "profile-header" }} >
          <UserImportTabs add_user={this.getModalBody()} onClose={this.onCloseModal} roles={this.props.roles} />
        </Modal>
        <Modal open={this.state.viewer_edit_modal.isOpen} onClose={() => this.toggleModal(this.state.viewer_edit_modal.key)} classNames={{ modal: "profile-header-modal" }}>
          <EventViewerEdit
            eventProfiles={this.props.eventProfiles}
            user={this.state.user}
            onFinish={() => this.toggleModal(this.state.viewer_edit_modal.key)}
          />
        </Modal>
        <Modal open={this.state.remove_modal.isOpen} onClose={() => this.toggleModal(this.state.remove_modal.key)} classNames={{ modal: "profile-header-modal" }}>
          <Fragment>
            <h4>Remove {label}?</h4>
            <p>
              Removing this {userType.type} from this event revokes the {userType.type}'s access to this event.
                                        It does not impact the {userType.type}'s access to any other events. Are you sure you want to remove this {userType.type} from this event?
            </p>
            <div className="form-group align-right">
              <button type="button" className="btn btn-default event-access-btn-remove align-right" onClick={() => this.removeUserFromEvent(this.state.user)}>
                REMOVE
              </button>
              <button type="button" className="btn btn-default align-right" onClick={() => this.toggleModal(this.state.remove_modal.key)}>
                CANCEL
              </button>
            </div>
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    event: state.event,
    eventProfiles: state.eventProfiles,
    permissions: state.permissions,
    roles: state.roles
  };
};

export default connect(mapStateToProps,
  {
    getUsers,
    getRoles,
    updateUser,
    setNotification
  }
)(EventAccess);