import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { notificationMessage } from "fuzion-core-lib";

import { getUsers, updateUser } from "actions/users";
import formHelper from "lib/ui/form";
import EventAccessEventProfileSelector from "./event_access_event_profile_selector";
import { setNotification } from "actions/notifications";

import "assets/css/checkbox.css";

class EventViewerEdit extends Component {
  constructor(props) {
    super(props);

    this.defaultEventProfileId = props.eventProfiles.filter(e => e.is_default).map(e => e.quant_event_profile_id)[0];

    this.state = {
      event_profiles: {
        key: "event_profiles",
        value: [...(this.props.user.event_profiles || [] )]
      }
    };
  }

    selectProfile = (profileId) => {
      let values = new Set(this.state.event_profiles.value);
      if (values.has(profileId) && this.defaultEventProfileId !== profileId) {
        values.delete(profileId);
      } else {
        values.add(profileId);
      }
      let event_profiles = { ...this.state.event_profiles, value: [...values] };
      this.setState(formHelper.mergeNewState(this.state, this.state.event_profiles.key, event_profiles));
    }

    submit = async () => {
      const data = {
        ...this.props.user, 
        event_profiles: [...this.state.event_profiles.value]
      };
      await this.props.updateUser(data, () => {});
      this.props.getUsers();
      this.props.setNotification(notificationMessage.SUCCESS_UPDATE);
      this.props.onFinish();
    }

    cancel = () => {
      this.props.onFinish();
    }

    render() {
      return (
        <Fragment>
          <h4 className="event-access-title">Edit Viewer Profile Access</h4>              
          <div className="form-group">
            <label className="field-label">
                        Select Profile(s)
	                </label>
            <EventAccessEventProfileSelector
              eventProfiles={this.props.eventProfiles}
              selectedProfiles={this.state.event_profiles.value}
              onChange={this.selectProfile}
            />
          </div>
          <div className="align-right">
            <button type="button" className="btn btn-primary align-right" onClick={this.submit}>
                        SAVE
            </button>
            <button type="button" className="btn btn-default align-right" onClick={this.cancel}>
                        CANCEL
            </button>
          </div>
        </Fragment>
      );
    }
}

EventViewerEdit.defaultProps = {
  eventProfiles: [],
  user: {
    event_profiles: []
  }
};

export default connect(null,
  {
    getUsers,
    setNotification,
    updateUser
  }
)(EventViewerEdit);