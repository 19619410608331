import { theme } from "fuzion-core-lib";
import React, { Component } from "react";
import { connect } from "react-redux";

import NoPermissions from "components/no_permissions";

const themes = {
  light: {
    title: "light",
    background: { backgroundColor: "#f4f4f4" },
    cardColor: { backgroundColor: "#ffffff" },
    text: { color: "rgba(0, 0, 0, .87)" },
    subText: { color: "rgba(0, 0, 0, .54)" },
    subTextActive: { color: "rgba(0, 0, 0, .87)" },
    subTextInactive: { color: "rgba(0, 0, 0, 0.1)" },
    editBarColor: { backgroundColor: "#ffffff" },
    borderFullColor: { border: "1px solid rgba(0, 0, 0, .1)" },
    borderColor: { borderColor: "rgba(0, 0, 0, .1)" },
    borderBottomColor: { borderBottom: "1px solid rgba(0, 0, 0, .1)" },
    borderTopColor: { borderTop: "1px solid rgba(0, 0, 0, .1)" },
    inputBorderColor: { borderBottom: "2px solid rgba(0, 0, 0, 0.38)" },
    textareaBorderColor: { border: "2px solid rgba(0, 0, 0, 0.38)" },
    inputBackgroundColor: { backgroundColor: "rgba(255, 255, 255, 1)" },
    modalInputBackgroundColor: { backgroundColor: "rgba(0, 0, 0, .05" },
    outlineColor: { outlineColor: "rgba(0, 0, 0, 0.87)" },
    iconColor: { fill: "rgb(0,0,0)"},
    btnSecondary : {backgroundColor: ""}
  },
  dark: {
    title: "dark",
    background: { backgroundColor: "#181717" },
    cardColor: { backgroundColor: "#232223" },
    text: { color: "#ffffff" },
    subText: { color: "hsla(0, 0%, 100%, .7)" },
    subTextActive: { color: "hsla(0, 0%, 100%, 1)" },
    subTextInactive: { color: "hsla(0, 0%, 100%, 0.1)" },
    editBarColor: { backgroundColor: "#595758" },
    borderFullColor: { border: "1px solid #404040" },
    borderColor: { borderColor: "rgba(255, 255, 255, .1)" },
    borderBottomColor: { borderBottom: "1px solid rgba(255, 255, 255, .1)" }, 
    borderTopColor: { borderTop: "1px solid rgba(255, 255, 255, .1)" },
    inputBorderColor: { borderBottom: "2px solid rgba(255, 255, 255, 0.38)" },
    textareaBorderColor: { border: "2px solid rgba(255, 255, 255, 0.38)" },
    inputBackgroundColor: { backgroundColor: "rgba(0, 0, 0, .54)" },
    modalInputBackgroundColor: { backgroundColor: "rgba(255, 255, 255, .05"},
    outlineColor: { outlineColor: "rgba(255, 255, 255, 0.87)" },
    iconColor: { fill: "rgb(255,255,255)" },
    btnSecondary : {backgroundColor: "#fff", opacity: .25}
  }
};

let withAuthTheme = ExtendedComponent => {
  class Theme extends Component {
        getThemeStyles = () => {
          let styles = null;
          let { user } = this.props;
          if (!user.theme) return themes.light;
          switch (user.theme.mode) {
            case theme.LIGHT:
              styles = themes.light;
              break;
            case theme.DARK:
              styles = themes.dark;
              break;
            default:
              styles = themes.light;
              break;
          }
          return styles;
        }

        render() {
          let { user } = this.props;
          let themeStyles = this.getThemeStyles();
		    if (user && user.role_permissions && user.role_permissions.length !== 0) {
		        return <ExtendedComponent theme={themeStyles} {...this.props} />;
		    } else {
		        return <NoPermissions />;
          }
        }
  }

  const mapStateToProps = state => {
    return {
      user: state.activeUser
    };
  };

  return connect(mapStateToProps, {})(Theme);
};

export default withAuthTheme;
