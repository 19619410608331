import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Login from "components/login/login";
import NewPassword from "components/login/new_password";
import { CognitoContext } from "components/cognito_provider";
import logoQuant from "assets/images/logoQuant.svg";
import CookieNotice from "components/login/cookies_notice";
import DataCollectionNotice from "components/login/data_collection_notice";

const FORM_STAGE = {
  first: "PASSWORD",
  second: "FORCE_RESET",
  third: "COOKIE_NOTICE",
  fourth: "DATA_COLLECTION"
};

export default function LoginPage() {

  const [formStage, setFormStage] = useState(FORM_STAGE.first);
  const { newPasswordRequired } = useContext(CognitoContext);
  return (
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, 30%)", width: "400px", height: "515px"}}>
      <Link to={"/login"}>
        <img src={logoQuant} style={{width:"400px", padding: "10px"}} alt="Quant Logo"  />
      </Link>
      <div>
        {newPasswordRequired ? <NewPassword setFormStage={setFormStage}/> : <Login setFormStage={setFormStage}/> }
        {FORM_STAGE.third === formStage && (<CookieNotice setFormStage={setFormStage} open={true} />)}
        {FORM_STAGE.fourth === formStage && (<DataCollectionNotice open={true}/>)}
      </div>
    </div>
  );
}