import { tippy } from "@tippy.js/react";

export default tippy.setDefaults({
  animation: "fade",
  delay: [0, 1500],
  duration: 150,
  maxWidth: 200,
  placement: "bottom",
  theme: "tooltip",
  sticky: true,
  onTrigger: () => tippy.hideAll(),
});