import { GET_USER , UPDATE_USER, REMOVE_USER_FROM_EVENT_PROFILE} from "actions/users";

export default (state = [], action) => {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    case UPDATE_USER:
      return action.payload.data.payload;
    case REMOVE_USER_FROM_EVENT_PROFILE:
      return action.payload.data.payload;
    default:
      return state;
  }
};
