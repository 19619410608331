import { GET_ROLE } from "actions/roles";

export default (state = [], action) => {
  switch (action.type) {
    case GET_ROLE:
      return action.payload.data.payload;
    default:
      return state;
  }
};
