import React, { Component } from "react";
import { connect } from "react-redux";
import { getPermissions } from "actions/permissions";

class EventDocumentAddButton extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  render() {
    let { permissions, onClick } = this.props;
    if (!permissions || !permissions.canRenderEventProfileDocumentAddButton) return null;

    return <button type="submit" className="btn btn-primary-no-margin-left" onClick={onClick}>+ DOCUMENT</button>;
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(EventDocumentAddButton);