import axios from "axios";

export const GET_EVENT_PROFILES_DEFAULT = "GET_EVENT_PROFILE_DEFAULT";

export const getEventProfilesAndEventProfileDefault = (eventId) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: eventId
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-profile/${eventId}/admin`, //TODO: temporary until we refactor
      headers: headers
    });
    request.then(result => {
      if (result.status === 200) {
        let eventProfiles = result.data.payload;
        let defaultProfile = eventProfiles.filter(p => p.is_default === true)[0];
        dispatch({
          type: GET_EVENT_PROFILES_DEFAULT,
          payload: {eventProfiles, defaultProfile}
        });
      }
    });
  };
};
