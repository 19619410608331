import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { notificationMessage } from "fuzion-core-lib";

import { requiredNumber } from "lib/validation/form_validation_rules";
import changes from "components/hoc/unsaved_changes";
import { updateEvent, getEventById } from "actions/events";
import avatarEvent from "assets/images/avatarEvent.png";
import withAuth from "components/hoc/with_auth";
import "assets/css/form.css";
import { setNotification } from "actions/notifications";
import { getPermissions } from "actions/permissions";
import {
  saveImage,
  deleteImage,
  getImage
} from "actions/event_image";
import formHelper from "lib/ui/form";
import styles from "./event_settings_form.module.css";
import InputNonRedux from "components/form/input_non_redux";

import "./event_settings_form.css";

class EventSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      eventImage: null,
      event_image: {
        key: "event_image",
        value: this.props.event.event_image || "",
      },
      imageButtonsDisabled: false,
      event_name: {
        key: "event_name",
        value: this.props.event.event_name || "",
      },
      organization_name: {
        key: "organization_name",
        value: this.props.event.organization_name || ""
      },
      status_flag: {
        key: "status_flag",
        value: this.props.event.status_flag >= 0 && this.props.event.status_flag <= 1 ?
          this.props.event.status_flag : 1,
        valid: true,
        errorMessage: "",
        validation: [requiredNumber]
      },
    };
  }

  async componentDidMount() {
    let { event, getEventById, getPermissions } = this.props;
    await getEventById(this.props.match.params.fuzion_event_id);
    if (event && event.event_image) {
      this.setImage(event.event_image_file);
    }   
    await getPermissions();
  }

  componentDidUpdate(prevProps) {
    const { event_image_file } = this.props.event;
    if (prevProps.event.event_image_file !== event_image_file) {
      this.setImage(event_image_file);
    }
  }

  setImage(eventImage) {
    this.setState({eventImage});
  }

    onBlur = (e) => {
      const { target: { name, value } } = e;
      const validation = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, validation));
    }

    onChange = ({ target: { name, value, type } }) => {
      if (type === "number") value = parseInt(value);
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onStatusFlagChange = ({ target: { name, value } }) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value: parseInt(value) }));
    }

    onOpenModal = () => {
      this.setState({ open: true });
    };

    onCloseModal = () => {
      this.setState({ open: false });
    };

    submit = async () => {
      let { updateEvent, setNotification, onSave, getEventById } = this.props;
      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        this.setState(form.formState);
        return;
      }
      this.setState(form.formState, async () => {
        const formValues = formHelper.getFormValues(this.state);
        const event = { ...this.props.event, ...formValues };   
        event.event_image_file = null;       
        await updateEvent(event);
        await getEventById(event.fuzion_event_id);
        setNotification(notificationMessage.SUCCESS_UPDATE);
        onSave();
      });
    }

    handleNewImage = e => {
      e.stopPropagation();
      e.preventDefault();
      this.refs.fileUploader.click();
    }

    onDeleteImage = e => {
      this.setState({imageButtonsDisabled: true});
      let { event, setNotification, deleteImage } = this.props;
      deleteImage(event.fuzion_event_id, result => {
        if (result.status === 200) {
          this.setImage(null);
          this.setState(formHelper.mergeNewState(this.state, "event_image", { value: null }));
          setNotification(notificationMessage.SUCCESS_DELETE);
        } else {
          setNotification(notificationMessage.ERROR_DELETE);            
        }
        this.setState({imageButtonsDisabled: false});
      });
    }

    onChangeFile = e => {
      this.setState({imageButtonsDisabled: true});
      let { event, setNotification, saveImage } = this.props;
      let file = e.target.files[0];
      let acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i;
      if (file.type.length && !acceptFileTypes.test(file.type)) {
        setNotification(notificationMessage.ERROR_FILE_UPLOAD);
        console.log("Image must be of type jpg, png, or gif!");
        return false;
      }
      if (file.size > 2097152) {
        setNotification(notificationMessage.ERROR_FILE_UPLOAD);
        console.log("File is too large to upload!");
        return false;
      }
      let formData = new FormData();
      formData.append("quant_event_image", file);
      formData.append("content_type", file.type);
      formData.append("image_name", file.name);
      e.target.value = null;

      saveImage(formData, event.fuzion_event_id, result => {
        if (result.status === 200) {
          const localImageUrl = window.URL.createObjectURL(file);
          this.setImage(localImageUrl);
          this.setState(formHelper.mergeNewState(this.state, "event_image", { value: result.data.payload.event_image }));
          setNotification(notificationMessage.SUCCESS_UPDATE);
        } else {
          setNotification(notificationMessage.ERROR_UPDATE);
        }
        this.setState({ imageButtonsDisabled: false });
      });
    }

    render() {
      let logo = this.state.eventImage || avatarEvent;

      return (
        <div ref={node => {
          this.node = node;
        }}>
          <div className="modal-form">
            <h4 className={`modal-title ${styles.title}`}> 
                        Event Settings
            </h4>
            <div className="form-group">
              <div className="field-label">Event*</div>
              <div>
                <InputNonRedux
                  name={this.state.event_name.key}
                  placeholder="Event Name"
                  value={this.state.event_name.value}
                  onChange={this.onChange}
                  disabled={true}
                  className="form-control"
                ></InputNonRedux>
                {!this.state.event_name.valid &&
                                (<div className="text-danger">{this.state.event_name.errorMessage}</div>)
                }
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Organization*</div>
              <div>
                <InputNonRedux
                  name={this.state.organization_name.key}
                  placeholder="Organization Name"
                  value={this.state.organization_name.value}
                  onChange={this.onChange}
                  disabled={true}
                  className="form-control"
                ></InputNonRedux>
                {!this.state.organization_name.valid &&
                                (<div className="text-danger">{this.state.organization_name.errorMessage}</div>)
                }
              </div>
            </div>                  
            <div className="form-group">
              <div className="field-label">
                            Status
              </div>
              <div>
                <select
                  name={this.state.status_flag.key}
                  value={this.state.status_flag.value}
                  onChange={this.onStatusFlagChange}
                  className="form-control"
                  onBlur={this.onBlur}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {!this.state.status_flag.valid &&
                                (<div className="text-danger">{this.state.status_flag.errorMessage}</div>)
                }
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Event Image
              </div>
              <img src={logo} className="event-avatar" alt="Event Profile" />
              <input type="file" name="quant_event_image" id="quant_event_image"
                style={{ display: "none" }} ref="fileUploader" onChange={this.onChangeFile}
                accept="image/gif, image/png, image/jpeg" />
              <div className="align-left">
                <button type="submit"
                  // disabled={this.state.event_image.submitting}
                  name="updateEventImageButton"
                  onClick={this.handleNewImage}
                  className="btn btn-default-alt">
                                UPDATE
	                        </button>
                {this.state.eventImage && <button type="submit"
                  // disabled={this.state.event_image.submitting}
                  name="removeEventImageButton"
                  onClick={this.onDeleteImage}
                  className="btn btn-default" >
                                REMOVE
	                        </button>
                }
              </div>
              <div>PNG, JPG or GIF format. Recommended dimensions: 300px x 300px.</div>
            </div>                   
            <div className="right-justify-buttons-modal">
              <div className={"form-required-label"}>
                            * Required
              </div>
              <button type="submit" onClick={this.submit}
                name="saveEventButton"
                className="btn btn-primary pull-right">
                                DONE
              </button>
            </div>                    
          </div>    
        </div>
      );
    }
}

EventSettingsForm.defaultProps = {
  event: {}
};

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
    event: state.event
  };
};

export default connect(mapStateToProps, {
  updateEvent,
  getEventById,
  setNotification,
  saveImage,
  deleteImage,
  getImage,
  getPermissions
})(withRouter(changes(withAuth(EventSettingsForm))));
