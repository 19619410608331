import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { setSideBarVisibility } from "actions/sidebar";
import "assets/css/tabs.css";
import renderClassNames  from "lib/ui/render_class_names";

class TabList extends Component {
  render() {
    const navClass = renderClassNames({
      "admin-tab-subheader": true,
      "tab-open-sidebar": this.props.sidebar.isVisible
    });
    return (
      <div className={navClass}>
        <div className="tab-inner-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps, {
  setSideBarVisibility
})(withRouter(TabList));