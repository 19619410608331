import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorBoundary from "components/error_boundary";
import AppContainer from "components/app_container";
import SideBar from "components/sidebar";
import Account from "components/account/account";
import Users from "components/settings/users/users";
import UserEvents from "components/settings/users/user_events";
import UserSettings from "components/settings/users/user_settings";
import Events from "components/events/events";
import EventProfiles from "components/events/event_profiles";
import EventDocuments from "components/events/event_documents";
import EventSettings from "components/events/event_settings";
import Profile from "components/profile/profile";
import Index from "components/index";
import Notifications from "components/notifications";
import NoPermissions from "components/no_permissions";
import Login from "components/login";
import ForgotPassword from "components/forgot_password";

import "components/tooltip/options";
import "components/tooltip/tooltip.css";

import GA from "lib/util/google_analytics";

console.log(`React v${React.version}`);

class QuantRouter extends Component {
  render() {
    return (
      <BrowserRouter >
        {GA.init() && <GA.RouteTracker />}
        <ErrorBoundary>
          <SideBar />
          <Switch>
            <AppContainer>
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/account" component={Account} />
              <Route path="/settings/users/user-events/:quant_user_profile_id" component={UserEvents} />
              <Route path="/settings/users/user-settings/:quant_user_profile_id" component={UserSettings} />
              <Route exact path="/settings/users" component={Users} />
              <Route exact path="/settings" component={Users} />
              <Route path="/events/profile/:fuzion_event_id/:dashboard_timeframe/:quant_event_profile_id?" component={Profile} />
              <Route path="/events/event-profiles/:fuzion_event_id" component={EventProfiles} />
              <Route path="/events/event-documents/:fuzion_event_id" component={EventDocuments} />
              <Route path="/events/event-settings/:fuzion_event_id" component={EventSettings} />
              <Route exact path="/events" component={Events} />
              <Route path="/nopermissions" component={NoPermissions} />
              <Route exact path="/" component={Index} />
            </AppContainer>
          </Switch>
          <Notifications />
        </ErrorBoundary>
      </BrowserRouter>
    );
  }
}
export default QuantRouter;