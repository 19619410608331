import React, { Component, Fragment } from "react";

class Error extends Component {
  render() {
    return (
      <Fragment>
        <h1>Something went wrong!</h1>
        <div>
                    Message: {this.props.message}
        </div>
      </Fragment>
    );
  }
}

export default Error;
