import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import moment from "moment";

import withAuth from "components/hoc/with_auth";
import EmptyState from "components/empty_state";
import TabList from "components/tabs/tablist";
import TabEventProfiles from "components/tabs/tab_event_profiles";
import TabEventDocuments from "components/tabs/tab_event_documents";
import TabEventSettings from "components/tabs/tab_event_settings";
import Header from "components/header/header";
import EventProfileAddButton from "./event_profile_add_button";
import EventsProfileEditForm from "./profiles/event_profile_edit_form";

import { getEventProfiles,
		 getEventProfileById
} from "actions/event_profiles";
import { getMetricsByEventProfile } from "actions/event_profile_metrics";
import { getEventById } from "actions/events";

class EventProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  async componentDidMount() {
    const { fuzion_event_id } = this.props.match.params;
    await this.props.getEventById(fuzion_event_id);
    await this.loadData();
  }

  loadData() {
    let { event } = this.props;
    this.props.getEventProfiles(event.fuzion_event_id);
  }

	onOpenModal = () => {
	    this.setState({ open: true});
	    this.loadData();
	};

	onCloseModal = () => {
	    this.setState({ open: false });
	    this.loadData();
	};

	loadEventProfile(eventId, eventProfileId) {
	    this.props.getEventProfileById(eventId, eventProfileId);
	    this.props.getMetricsByEventProfile(eventProfileId);
	}

	renderEventProfiles(profile) {
	    return (
	        <div key={profile.quant_event_profile_id} className="list-item">
	            <Link
	                className="list-group-item-simple"
	                key={profile.quant_event_profile_id}
	                to={`/events/profile/${profile.fuzion_event_id}/pre-event/${profile.quant_event_profile_id}`}
	                onClick={() => this.loadEventProfile(profile.fuzion_event_id, profile.quant_event_profile_id)}
	            >
	                <div className="list-info-container">
	                    <div className="list-info-details">
	                        <div className="list-title">
	                            {profile.profile_name}
	                        </div>
	                        <div className="status-div">
	                            <div>Last Updated: {moment(profile.last_mod_timestamp).format("MMM DD, YYYY")}</div>
	                        </div>
	                    </div>
	                </div>
	            </Link>
	        </div>
	    );
	}

	render() {
	    let activeItems = <EmptyState type="event profiles" theme="light"/>;
	    let { event, eventProfiles, match } = this.props;
	    if (eventProfiles.length > 0) activeItems = eventProfiles.map(item => this.renderEventProfiles(item, match));
	    return (
	        <Fragment>
	            <div>
	                <Header detailView={true} to="/events" />
	                <TabList>
	                    <TabEventProfiles event={event} isActiveTab="true" />
	                    <TabEventDocuments event={event} />
	                    <TabEventSettings event={event} />
	                </TabList>
	                <div className="container-fluid tabs-spacer">
	                    <h1 className="page-header">Event Profiles</h1>
	                    <div className="sub-header">
	                        {event.organization_name + ": " + event.event_name}
	                    </div>
	                    <EventProfileAddButton onClick={this.onOpenModal} />
	                    <div className="body-content-partial">
	                        {activeItems}
	                    </div>
	                </div>
	            </div>
	            <Modal open={this.state.open} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
	                <EventsProfileEditForm title="Add Profile" onSave={this.onCloseModal} onClose={this.onCloseModal} operation="insert" eventId={event.fuzion_event_id} />
	            </Modal>
	        </Fragment>
	    );
	}
}

const mapStateToProps = state => {
  return {
    event: state.event,
    eventProfiles: state.eventProfiles
  };
};

export default connect(mapStateToProps, {
  getEventById,
  getEventProfiles,
  getEventProfileById,
  getMetricsByEventProfile
})(withRouter(withAuth(EventProfiles)));