import React, { Component } from "react";

import "./event_access.css";
import userTypes from "./user_types";
import EventAccessList from "./event_access_list";

class EventAccessContainer extends Component {
  render() {
    return (
      <div style={{margin: "16px"}}>
        <h4 className="event-access-title" >
                    Event Access
        </h4>
        <EventAccessList userType={userTypes.VIEWER} />
      </div>
    );
  }
}

export default EventAccessContainer;
