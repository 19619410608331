import axios from "axios";
import { request } from "lib/data/api";
import global from "types/global";

export const SAVE_IMAGE = "SAVE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";


export const getImage = eventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const result = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-event-image/${eventId}`
      });
      return result.data.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.eventImage.getImage failed"
      });
    }
  };
};

export const saveImage = (formData, fuzion_event_id, callback) => {
  return (dispatch, getState) => {
    const reqParams = {
      method: "POST",
      url:  `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-image`,
      data: formData,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
        fuzion_event_id: fuzion_event_id
      }
    };
    let request = axios(reqParams)
      .then(result => callback(result));
    // .catch(e => console.log(e)); //TODO: Handle in a useful way!
    return dispatch({
      type: SAVE_IMAGE,
      payload: request
    });
  };
};

export const deleteImage = (fuzion_event_id, callback) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: fuzion_event_id,
    };
    const request = axios({ 
      method: "DELETE",
      url:  `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-image`,
      headers: headers
    }).then(result => callback(result));
    return dispatch({
      type: DELETE_IMAGE,
      payload: request
    });
  };
};
