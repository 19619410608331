import axios from "axios";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export const SAVE_DOCUMENT = "SAVE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const getDocuments = (start, pageSize, fuzion_event_id) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      start: start,
      page_size: pageSize,
      fuzion_event_id: fuzion_event_id
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-documents`,
      headers: headers
    });
    dispatch({
      type: GET_DOCUMENTS,
      payload: request
    });
  };
};

export const getDocument = document => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: document.fuzion_event_id
    };
    const request = axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-documents/${document.quant_document_id}`,
      headers: headers
    });
    dispatch({
      type: GET_DOCUMENT,
      payload: request
    });
  };
};

export const downloadDocument = (document, callback) => {
  return (dispatch, getState) => {
    let splitFileName = document.document_name.split(".");
    let extension = splitFileName[1];
    if (!extension) {
      throw new Error("ERROR: Document does not have an extension");
    }
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: document.fuzion_event_id
    };
    axios({
      method: "GET",
      responseType: "blob",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-documents/download/?fuzion_event_id=${document.fuzion_event_id}&quant_document_id=${document.quant_document_id}&extension=${extension}`,
      headers: headers
    }).then(result => {
      dispatch({
        type: DOWNLOAD_DOCUMENT,
        payload: callback(result)
      });
    });
  };
};

export const saveDocument = (formData, fuzion_event_id, quant_user_profile_id, callback) => {
  return (dispatch, getState) => {
    const reqParams = {
      method: "POST",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-documents/${quant_user_profile_id}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${getState().identityUser.token}`,
        fuzion_event_id: fuzion_event_id
      }
    };
    let request = axios(reqParams)
      .then(result => callback(result));
    // .catch(e => console.log(e)); //TODO: Handle in a useful way!
    return dispatch({
      type: SAVE_DOCUMENT,
      payload: request
    });
  };
};

export const deleteDocument = (data, callback) => {
  return (dispatch, getState) => {
    let headers = {
      Authorization: `Bearer ${getState().identityUser.token}`,
      fuzion_event_id: data.fuzion_event_id
    };
    const request = axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/quant-event-documents/${data.quant_document_id}`,
      headers: headers
    }).then(() => callback());
    return dispatch({
      type: DELETE_DOCUMENT,
      payload: request
    });
  };
};