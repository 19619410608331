import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import "assets/css/bootstrap.min.css";
import "assets/css/quant.ui.css";

import Quant from "./routes/index";
import registerServiceWorker from "./registerServiceWorker";
import { CognitoProvider } from "components/cognito_provider";

render(
  <CognitoProvider>
    <Provider store={store}>
      <Quant />
    </Provider>
  </CognitoProvider>,
  document.getElementById("root"));
registerServiceWorker();
