import {
  GET_EVENT_PROFILE,
  UPDATE_EVENT_PROFILE,
  DELETE_EVENT_PROFILE,
  UPDATE_DEFAULT_EVENT_PROFILE
} from "actions/event_profiles";

export default (state = [], action) => {
  switch (action.type) {
    case GET_EVENT_PROFILE:
      return action.payload;
    case UPDATE_EVENT_PROFILE:
      return action.payload;
    case DELETE_EVENT_PROFILE:
      return action.payload;
    case UPDATE_DEFAULT_EVENT_PROFILE:
      return action.payload;
    default:
      return state;
  }
};