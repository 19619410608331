import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { required } from "lib/validation/form_validation_rules";
import withAuth from "components/hoc/with_auth";
import Loading from "components/loading";
import { getEventProfilesAndEventProfileDefault } from "actions/event_profile_details";
import Input from "components/form/input";
import select from "components/form/select";

class DownloadPdfForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeframeOptions: ""
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { eventId } = this.props;
    this.props.getEventProfilesAndEventProfileDefault(eventId);
  }

    formatEventProfileOptions = () => {
      const { eventProfiles } = this.props;
      let options = {};
      if (eventProfiles) {
        eventProfiles.forEach(profile => {
          options[profile.quant_event_profile_id] = profile.profile_name;
        });
      }
      return options;
    }

    formatDates = eventData => {
      return moment.utc(eventData, "YYYY-MM-DD hh:mm:ss").local().format("MMMM Do");
    }

    getAndFormatEventTimeframeOptions = (selectedEventProfile) => {
      let options = {};

      options[0] = "Pre Event (" + this.formatDates(selectedEventProfile.pre_event_begin) + " - " + this.formatDates(selectedEventProfile.pre_event_end) + ")";
      options[1] = "Event (" + this.formatDates(selectedEventProfile.event_begin) + " - " + this.formatDates(selectedEventProfile.event_end) + ")";
      options[2] = "Post Event (" + this.formatDates(selectedEventProfile.post_event_begin) + " - " + this.formatDates(selectedEventProfile.post_event_end) + ")";

      return options;
    }

    handleEventProfileOptionsChange = (e) => {
      let { eventProfiles } = this.props;

      let selectedEventProfile = eventProfiles.filter(profile => profile.quant_event_profile_id === e.target.value)[0];
      let options = this.getAndFormatEventTimeframeOptions(selectedEventProfile);

      this.setState({
        timeframeOptions: options
      });

    }

    downloadToPDF = () => {
      let screenshotComponent = document.querySelector(".container-profile");

      html2canvas(screenshotComponent).then((canvas) => {
        let imgData = canvas.toDataURL("image/jpeg");

        let doc = new jsPDF("l", "mm", "a4");
        let width = doc.internal.pageSize.width;
        let height = doc.internal.pageSize.height;
        doc.addImage(imgData, "JPEG", 0, 0, width, height);
        doc.save("yourmom.pdf");
      });
    }

    renderTitle() {
      return (
        <div>
          <h4 className="modal-title">
            {this.props.title}
          </h4>
        </div>
      );
    }

    renderCancelButton() {
      return (
        <button type="button"
          onClick={this.props.onSave}
          name="cancelButton"
          className="btn btn-default pull-right">
                CANCEL
        </button>
      );
    }

    renderSaveButton() {
      return (
        <button type="button"
          // onClick={this.downloadToPDF}
          name="saveEventProfileButton"
          className="btn btn-primary pull-right">
                SAVE
        </button>
      );
    }

    render() {
      let { initialValues} = this.props;

      if (!initialValues) {
        return <Loading />;
      }

      return (
        <div ref={node => {
          this.node = node; 
        }} className="main-admin-container">
          {this.renderTitle()}
          <form className="modal-form">
            <div className="body-content-partial">
              <div className="form-group">
                <div className="field-label">
                                Filename*
                </div>
                <div>
                  <Field
                    label="Filename"
                    name="filename"
                    component={Input}
                    validate={[required]}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-label">
                                Profile
                </div>
                <div>
                  <Field
                    label="Profile"
                    name="profile"
                    component={select}
                    options={this.formatEventProfileOptions()}
                    onChange={this.handleEventProfileOptionsChange}
                    // selected={this.props.defaultProfile.quant_event_profile_id}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-label">
                                Timeframe
                </div>
                <div>
                  <Field
                    label="Profile"
                    name="profile_timeframe"
                    component={select}
                    options={this.state.timeframeOptions}
                  />
                </div>
              </div>
              {/* <div className="form-group">
                            <div>
                                <Checkbox
                                    label="Include Insights"
                                    value="insight"
                                    checked={true}
                                />
                            </div>
                        </div> */}
              <div className="form-group flex-display">
                <div className="col-md-12 right-justify-buttons-modal">
                  {this.renderCancelButton()}
                  {this.renderSaveButton()}
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    initialValues: state.eventProfileDetails.defaultProfile,
    eventProfiles: state.eventProfileDetails.eventProfiles,
  };
};

export default connect(mapStateToProps, {
  getEventProfilesAndEventProfileDefault,
})(reduxForm({
  form: "DownloadPDFForm",
  enableReinitialize: true
})(withAuth(DownloadPdfForm)));

