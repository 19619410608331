import React from "react";
import iconCheckCircle from "assets/images/iconCheckCircle.svg";
import iconCheckCircleRed from "assets/images/iconExitRed.svg";
import styles from "./user-settings.module.css";

export default function UserCognitoResults(props) {
  let { error_code, error_message, message, error, action, status_code, error_description } = props;

  return (
    <div className={`align-left ${styles.cognitoResultsMainContainer}`}>
      {message && error &&
        <div className={action === "set-password" ? `${styles.setPasswordErrorResultsContainer}` : `${styles.sendTempPasswordErrorResultsContainer}`}>
          <div className={styles.cognitoResultsMainContainer}>
            <img className={styles.icon} src={iconCheckCircleRed} alt="icon check circle red"></img>
            <div className={styles.messageText}>
              {message}
            </div>
          </div>
          {error_code && <div className={`${styles.messageText} ${styles.messageTextSecondary}`}>
            {error_code}
          </div>}
          {error_description && <div className={`${styles.messageText} ${styles.messageTextSecondary}`}>
            {error_description}
          </div>}
          {error_message &&
            <div className={`${styles.messageText} ${styles.messageTextSecondary}`}>
              {error_message}
            </div>}
          {status_code &&
            <div className={`${styles.messageText} ${styles.messageTextSecondary}`}>
              HTTP Status Code: {status_code}
            </div>}
        </div>
      }
      {message && !error &&
        <div className={action === "set-password" ? `${styles.setPasswordResultsContainer}` : `${styles.sendTempPasswordResultsContainer}`}>
          <img className={styles.icon} src={iconCheckCircle} alt="icon check circle"></img>
          <div className={styles.messageText}>
            {message}
          </div>
        </div>
      }
    </div >
  );
}