import { GET_ROLES } from "actions/roles";

export default (state = [], action) => {
  switch (action.type) {
    case GET_ROLES:
      let { payload } = action.payload.data;
      return payload;
    default:
      return state;
  }
};