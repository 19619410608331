import React, { Component } from "react";
import { connect } from "react-redux";

import Error from "components/error";

let catchError = ExtendedComponent => {
  class CatchError extends Component {
    render() {
      let { error } = this.props;
      if (error) return <Error message={error} />;
      return <ExtendedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      error: state.error,
      user: state.activeUser
    };
  };

  return connect(mapStateToProps, {})(CatchError);
};

export default catchError;
