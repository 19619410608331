import { request } from "lib/data/api";
import types from "types/active_user";
import global from "types/global";

export const getActiveUser = user => {
  return async (dispatch, getState) => {
    try {
      const email = user.profile.ImpersonatedUser;
      const state = getState();
      const response = await request({
        state: state,
        method: "GET",
        route: `/v1/quant-user-profile/email/${email}`
      });
      return dispatch({
        type: types.GET_ACTIVE_USER,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.active_user.getActiveUser failed"
      });
    }
  };
};

export const updateProfile = (profile) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await request({
        state: state,
        method: "PUT",
        route: `/v1/quant-user-profile/${profile.quant_user_profile_id}`,
        data: profile
      });
      dispatch({
        type: types.UPDATE_ACTIVE_USER,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.active_user.updateProfile failed"
      });
    }
  };
};