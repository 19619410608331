import React, { Component } from "react";
import { connect } from "react-redux";
import Tab from "./tab";
import { getPermissions } from "actions/permissions";

class TabEventProfiles extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  render() {
    let { permissions } = this.props;
    if (!permissions || !permissions.canRenderEventProfilesTab) return null;

    return <Tab activeTab={this.props.isActiveTab} title="PROFILES" to={`/events/event-profiles/${this.props.event.fuzion_event_id}`} />;
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(TabEventProfiles);