import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import "assets/css/tabs.css";
import render_class_names from "lib/ui/render_class_names";

class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: false
    };
  }

  render() {
    const tabClass = render_class_names({
      "nav-item": true,
      "nav-item-active": this.props.activeTab
    });
    return (
      <Link className={tabClass} to={this.props.to}>
        {this.props.title}
      </Link>
    );
  }
}

const mapStateToProps = state => {
  return {
    tab: state.tab
  };
};

export default connect(mapStateToProps, {})(withRouter(Tab));