import { request } from "lib/data/api";

export const getEmbedToken = (report_id, workspace_id) => {
  return async (dispatch, getState) => {
    let result = null;
    try {
      const state = getState();
      const response = await request({
        state,
        method: "POST",
        route: "/v1/powerbi/embedtoken",                
        data: {report_id, workspace_id}
      });
      result = response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getAllEvents failed"
      });
      result = null;
    }
    return result;
  };
};