import { notificationMessage } from "fuzion-core-lib";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router";

import { requiredNumber } from "lib/validation/form_validation_rules";
import changes from "components/hoc/unsaved_changes";
import { updateEvent, getEventById } from "actions/events";
import avatarEvent from "assets/images/avatarEvent.png";
import withAuth from "components/hoc/with_auth";
import { setNotification } from "actions/notifications";
import {
  saveImage,
  deleteImage,
  getImage
} from "actions/event_image";
import formHelper from "lib/ui/form";
import convertToDate from "lib/util/convert_to_date";

import "assets/css/form.css";
import "./event_settings_form.css";

class DeleteEventConfirmation extends Component {
  render() {
    let { onCloseModal, onDelete } = this.props;
    return (
      <div>
        <h4 className="modal-title">Remove Event?</h4>
        <div>Removing an event from Quant deletes all associated documents and profiles and revokes access to the event for all users.
                    Are you sure you want to remove this event?
        </div>
        <div className="flex-display">
          <div className="modal-right-btns">
            <button
              onClick={onCloseModal}
              type="button"
              name="cancelRemoveEventBtn"
              style={{ marginTop: "7px" }}
              className="btn btn-default">
                            CANCEL
            </button>
            <button
              type="button"
              onClick={onDelete}
              name="removeEventButton"
              className="btn btn-primary">
                            REMOVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class EventSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      eventImage: null,
      imageButtonsDisabled: false,
      event_name: {
        key: "event_name",
        value: this.props.event.event_name || "",
      },
      organization_name: {
        key: "organization_name",
        value: this.props.event.organization_name || ""
      },
      status_flag: {
        key: "status_flag",
        value: this.props.event.status_flag >= 0 && this.props.event.status_flag <= 1 ?
          this.props.event.status_flag : 1,
        valid: true,
        errorMessage: "",
        validation: [requiredNumber]
      },
      pre_event_begin: {
        key: "pre_event_begin",
        value: convertToDate(this.props.event.pre_event_begin),
        valid: true,
        errorMessage: ""
      },
      pre_event_end: {
        key: "pre_event_end",
        value: convertToDate(this.props.event.pre_event_end),
        valid: true,
        errorMessage: ""
      },
      event_begin: {
        key: "event_begin",
        value: convertToDate(this.props.event.event_begin),
        valid: true,
        errorMessage: ""
      },
      event_end: {
        key: "event_end",
        value: convertToDate(this.props.event.event_end),
        valid: true,
        errorMessage: ""
      },
      post_event_begin: {
        key: "post_event_begin",
        value: convertToDate(this.props.event.post_event_begin),
        valid: true,
        errorMessage: ""
      },
      post_event_end: {
        key: "post_event_end",
        value: convertToDate(this.props.event.post_event_end),
        valid: true,
        errorMessage: ""
      }
    };
  }

  componentDidMount() {
    const { event } = this.props;
    getEventById(this.props.match.params.fuzion_event_id);
    if (event && event.event_image) {
      this.setImage(event.event_image_file);
    }
  }

  componentDidUpdate(prevProps) {
    const { event_image_file } = this.props.event;
    if (prevProps.event.event_image_file !== event_image_file) {
      this.setImage(event_image_file);
    }
  }

  setImage(eventImage) {
    this.setState({eventImage});
  }

    onBlur = (e) => {
      const { target: { name, value } } = e;
      const validation = formHelper.validate(this.state, name, value);
      this.setState(formHelper.mergeNewState(this.state, name, validation));
    }

    onChange = ({ target: { name, value, type } }) => {
      if (type === "number") value = parseInt(value);
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onDateChange = (name, value) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }

    onStatusFlagChange = ({ target: { name, value } }) => {
      this.setState(formHelper.mergeNewState(this.state, name, { value: parseInt(value) }));
    }

    onOpenModal = () => {
      this.setState({ open: true });
    };

    onCloseModal = () => {
      this.setState({ open: false });
    };

    determineDate = (startDate, endDate) => {
      if (!startDate) return endDate;
      if (!endDate) return startDate;
      return startDate > endDate ? startDate : endDate;
    }

    submit = async () => {
      let { updateEvent, setNotification } = this.props;
      const form = formHelper.isFormValid(this.state);
      if (!form.valid) {
        this.setState(form.formState);
        return;
      }
      this.setState(form.formState, async () => {
        const formValues = formHelper.getFormValues(this.state);
        const event = { ...this.props.event, ...formValues };
        await updateEvent(event);
        setNotification(notificationMessage.SUCCESS_UPDATE);
      });
    }

    handleNewImage = e => {
      e.stopPropagation();
      e.preventDefault();
      this.refs.fileUploader.click();
    }

    onDeleteImage = e => {
      this.setState({imageButtonsDisabled: true});
      let { event, setNotification, deleteImage } = this.props;
      deleteImage(event.fuzion_event_id, result => {
        if (result.status === 200) {
          this.setImage(null);
          setNotification(notificationMessage.SUCCESS_DELETE);
        } else {
          setNotification(notificationMessage.ERROR_DELETE);            
        }
        this.setState({imageButtonsDisabled: false});
      });
    }

    onChangeFile = e => {
      this.setState({imageButtonsDisabled: true});
      let { event, setNotification, saveImage } = this.props;
      let file = e.target.files[0];
      let acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i;
      if (file.type.length && !acceptFileTypes.test(file.type)) {
        setNotification(notificationMessage.ERROR_FILE_UPLOAD);
        return false;
      }
      if (file.size > 2097152) {
        setNotification(notificationMessage.ERROR_FILE_UPLOAD);
        return false;
      }
      let formData = new FormData();
      formData.append("quant_event_image", file);
      formData.append("content_type", file.type);
      formData.append("image_name", file.name);
      e.target.value = null;

      saveImage(formData, event.fuzion_event_id, result => {
        if (result.status === 200) {
          const localImageUrl = window.URL.createObjectURL(file);
          this.setImage(localImageUrl);
          setNotification(notificationMessage.SUCCESS_UPDATE);
        } else {
          setNotification(notificationMessage.ERROR_UPDATE);
        }
        this.setState({ imageButtonsDisabled: false });
      });
    }

    render() {
      let logo = this.state.eventImage || avatarEvent;

      return (
        <div ref={node => {
          this.node = node;
        }}
        className="main-admin-container">
          <div className="page-form event-settings-form">
            <div className="form-group">
              <div className="field-label">
                            Event*
              </div>
              <div>
                <input
                  name={this.state.event_name.key}
                  placeholder="Event Name"
                  value={this.state.event_name.value}
                  onChange={this.onChange}
                  disabled={true}
                  className="form-control"
                />
                {!this.state.event_name.valid &&
                                (<div className="text-danger">{this.state.event_name.errorMessage}</div>)
                }
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Organization*
              </div>
              <div>
                <input
                  name={this.state.organization_name.key}
                  placeholder="Organization Name"
                  value={this.state.organization_name.value}
                  onChange={this.onChange}
                  disabled={true}
                  className="form-control"
                />
                {!this.state.organization_name.valid &&
                                (<div className="text-danger">{this.state.organization_name.errorMessage}</div>)
                }
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Event Image
              </div>
              <img src={logo} className="event-avatar" alt="Event Profile" />
              <input type="file" name="quant_event_image" id="quant_event_image"
                style={{ display: "none" }} ref="fileUploader" onChange={this.onChangeFile}
                accept="image/gif, image/png, image/jpeg" />
              <div className="align-left">
                <button type="submit"
                  // disabled={this.state.event_image.submitting}
                  name="updateEventImageButton"
                  onClick={this.handleNewImage}
                  className="btn btn-default-alt">
                                UPDATE
	                        </button>
                {this.state.eventImage && <button type="submit"
                  // disabled={this.state.event_image.submitting}
                  name="removeEventImageButton"
                  onClick={this.onDeleteImage}
                  className="btn btn-default" >
                                REMOVE
	                        </button>
                }
              </div>
              <div>PNG, JPG or GIF format. Recommended dimensions: 300px x 300px.</div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Status
              </div>
              <div>
                <select
                  name={this.state.status_flag.key}
                  value={this.state.status_flag.value}
                  onChange={this.onStatusFlagChange}
                  className="form-control"
                  onBlur={this.onBlur}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {!this.state.status_flag.valid &&
                                (<div className="text-danger">{this.state.status_flag.errorMessage}</div>)
                }
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Pre Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.state.pre_event_begin.value}
                    onChange={(val) => this.onDateChange(this.state.pre_event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                  />
                  {!this.state.pre_event_begin.valid &&
                                    (<div className="text-danger">{this.state.pre_event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.pre_event_begin.value, this.state.pre_event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.pre_event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.state.pre_event_begin.value)}
                  />
                  {!this.state.pre_event_end.valid &&
                                    (<div className="text-danger">{this.state.pre_event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.pre_event_end.value, this.state.event_begin.value)}
                    onChange={(val) => this.onDateChange(this.state.event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.pre_event_end.value))}
                  />
                  {!this.state.event_begin.valid &&
                                    (<div className="text-danger">{this.state.event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.event_begin.value, this.state.event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.event_begin.value))}
                  />
                  {!this.state.event_end.valid &&
                                    (<div className="text-danger">{this.state.event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">
                            Post Event Timeframe*
	                    </div>
              <div className="flex-display">
                <div>
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.event_end.value, this.state.post_event_begin.value)}
                    onChange={(val) => this.onDateChange(this.state.post_event_begin.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.event_end.value))}
                  />
                  {!this.state.post_event_begin.valid &&
                                    (<div className="text-danger">{this.state.post_event_begin.errorMessage}</div>)
                  }
                </div>
                <div className="flex-field">
                  <DatePicker
                    className={"form-control drop-down"}
                    selected={this.determineDate(this.state.post_event_begin.value, this.state.post_event_end.value)}
                    onChange={(val) => this.onDateChange(this.state.post_event_end.key, val)}
                    dateFormat="MMM DD, YYYY"
                    placeholderText="Select a date"
                    minDate={convertToDate(this.determineDate(this.state.pre_event_begin.value, this.state.post_event_begin.value))}
                  />
                  {!this.state.post_event_end.valid &&
                                    (<div className="text-danger">{this.state.post_event_end.errorMessage}</div>)
                  }
                </div>
              </div>
            </div>
            <div className="form-group flex-display">
              <div className="align-left">
                <button type="submit"
                  onClick={this.submit}
                  name="saveEventButton"
                  className="btn btn-primary">
                                SAVE
	                        </button>
              </div>
            </div>
            <Modal open={this.state.open} onClose={this.onCloseModal} closeOnOverlayClick={false} classNames={{ modal: "custom-modal" }}>
              <DeleteEventConfirmation onCloseModal={this.onCloseModal} onDelete={this.onDelete} />
            </Modal>
            <div className={"form-required-label"}>
                        * Required
            </div>
          </div>
        </div>
      );
    }
}

EventSettingsForm.defaultProps = {
  event: {}
};

export default connect(null, {
  updateEvent,
  getEventById,
  setNotification,
  saveImage,
  deleteImage,
  getImage
})(withRouter(changes(withAuth(EventSettingsForm))));
