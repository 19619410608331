export default {
  getFormValues,
  isFormValid,
  mergeNewState,
  validate
};

function isObj(val) {
  return val && typeof val === "object";
}

function getFormValues(state) {
  let form = {};
  const keys = Object.keys(state);
    
  keys.filter(k => isObj(state[k]) && state[k].key)
    .map(key => form[key] = state[key].value);
  return form;
}

function isFormValid(state) {
  let temp = state;
  const keys = Object.keys(temp);
  const isFormValid = keys.reduce((prev, curr) => {
    if (!isObj(temp[curr])) return prev;
    const validation = validate(temp, curr, temp[curr].value);
    temp = mergeNewState(temp, curr, validation);
    return prev && validation.valid;
  }, true);
  return {valid: isFormValid, formState: temp};
}

function validate(state, key, value) {
  const methods = state[key].validation;
  let valid = true;
  let errorMessage = "";
  if (methods && methods.length > 0) {
    for (let x = methods.length - 1; x >= 0; x--) {
      const validationMethod = methods[x];
      const result = validationMethod(value);
      if (result && result.length > 1) {
        errorMessage = result;
        valid = false;
        break;
      }

    }
  }
  return {valid, errorMessage};
}

function mergeNewState(state, key, newPropState) {
  const newState = {
    ...state[key],
    ...newPropState
  };    

  return {...state, [key]: newState};
}