import axios from "axios";
import retry from "retry";

const adminApiUri = process.env.REACT_APP_API_ADMIN_URI;
const operation = retry.operation({ retries: 1 });

export const request = async params => {
  return new Promise(((resolve, reject) => {
    operation.attempt(async () => {
      try {
        let { eventId, apiUri, state, method, route } = params;
        const headers = {
          Authorization: `Bearer ${state.identityUser.token}`,
        };
        if (eventId) {
          headers.fuzion_event_id = eventId;
        }
        let uri = adminApiUri;
        if (apiUri) {
          uri = apiUri;
        }
        let axiosBody = {
          method: method,
          url: `${uri}${route}`,
          headers: headers
        };
        if (params.data) {
          axiosBody.data = params.data;
        }
        const result = await axios(axiosBody);
        resolve(result.data);
      } catch (e) {
        if (operation.retry(e)) return;
        console.log(e);
        reject(e.message);
      }
    });
  }));
};


export const upload = async params => {
  return new Promise(((resolve, reject) => {
    operation.attempt(async () => {
      try {
        let { eventId, apiUri, state, method, route } = params;
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${state.identityUser.token}`,
        };
        if (eventId) {
          headers.fuzion_event_id = eventId;
        }
        let uri = adminApiUri;
        if (apiUri) {
          uri = apiUri;
        }
        let axiosBody = {
          method: method,
          url: `${uri}${route}`,
          headers: headers
        };
        if (params.data) {
          axiosBody.data = params.data;
        }
        const result = await axios(axiosBody);
        resolve(result.data);
      } catch (e) {
        if (operation.retry(e)) return;
        console.log(e);
        reject(e.message);
      }
    });
  }));
};
