import React, { Component } from "react";
import { connect } from "react-redux";
import { getPermissions } from "actions/permissions";

class EventProfileAddButton extends Component {
  async componentDidMount() {
    await this.props.getPermissions();
  }

  render() {
    let { permissions, onClick } = this.props;
    if (!permissions || !permissions.canRenderEventProfileAddButton) return null;

	    return <button className="btn btn-primary-no-margin-left" onClick={onClick}>+ PROFILE</button>;
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, {
  getPermissions,
})(EventProfileAddButton);