import React, { Component } from "react";
import { Link } from "react-router-dom";

import avatarUser from "assets/images/avatarUser.png";

class UserText extends Component {
  render() {
    let { item } = this.props;
    return (
      <Link className="list-group-item" key={item.quant_user_profile_id} to={`users/user-settings/${item.quant_user_profile_id}`}>
        <div className="list-avatar-div">
          <img src={avatarUser} className="list-avatar-image" alt="List Profile" />
        </div>
        <div className="list-info-container">
          <div className="list-info-details">
            <div className="list-title">
              {`${item.first_name} ${item.last_name}`}
            </div>
            <div className="status-div">
              <div>Email: {item.email}</div>
              <div>Role: {item.role_friendly_name}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default UserText;
