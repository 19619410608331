import types from "types/identity_user";

export default (state = {}, action) => {
  switch (action.type) {
    case types.SET_IDENTITY_USER:
      return action.payload;
    default:
      return state;
  }
};
