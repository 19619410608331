import React, { Component } from "react";
import { withRouter } from "react-router";
import Modal from "react-responsive-modal";

let unsavedChanges = ExtendedComponent => {
  class UnsavedChanges extends Component {
    constructor() {
      super();
      this.state = {
        open: false
      };
    }

    componentDidMount() {
      this.unblock = this.props.history.block((location, action) => {
        this.nextLocation = location.pathname;
        if (this.props.dirty) {
          this.setState({ open: true });
        } else {
          this.setState({ open: false });
        }
        return !this.props.dirty;
      });
    }

    componentWillUnmount() {
      this.unblock();
    }

        onOpenModal = () => {
          this.setState({ open: true });
        };

        onCloseModal = () => {
          this.setState({ open: false });
        };

        onExit = () => {
          this.unblock();
          this.setState({ open: false });
          this.props.history.push(this.nextLocation);
        };

        renderModal() {
          return (
            <Modal open={this.state.open} onClose={this.onCloseModal} closeOnOverlayClick={false} showCloseIcon={false} classNames={{ modal: "custom-modal" }}>
              <div className="custom-modal-form">
                <div className="custom-modal-dialog" role="document">
                  <h5 className="custom-modal-header-text">Unsaved Changes</h5>
                  <div className="custom-modal-text">
                    <p>
                                    Changes have been made that haven't been saved yet. If you exit this
									page now your changes will be lost. Are you sure you want to exit this
									page without saving?
		                        </p>
                  </div>
                  <div className="custom-modal-footer">
                    <button
                      type="button"
                      className="btn btn-default pull-right"
                      data-dismiss="modal"
                      onClick={this.onCloseModal}
                    >
                                    CANCEL
		                        </button>
                    <button
                      type="button"
                      className="btn btn-alert pull-right"
                      onClick={this.onExit}
                      name="unsavedChanges"
                    >
                                    EXIT
		                        </button>
                  </div>
                </div>
              </div>
            </Modal>
          );
        }

        render() {
          return (
            <div>
              {this.renderModal()}
              <ExtendedComponent
                onCloseModal={this.onCloseModal}
                onExitModal={this.onExitModal}
                {...this.props}
              />
            </div>
          );
        }
  }

  return withRouter(UnsavedChanges);
};

export default unsavedChanges;