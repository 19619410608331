import React from "react";

const freemanLogoIcon = props => {
  let style = props.theme ? { enableBackground: "new 0 0 329 31", ...props.theme } : { enableBackground: "new 0 0 329 31" };
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 329 31" className={props.className} style={style}>
      <g>
        <polygon points="321.2,0 321.2,21.6 321.1,21.6 306,0 295.3,0 295.3,31 303.2,31 303.2,8.9 303.3,8.9 318.8,31 329.1,31 329.1,0 	
                    "/>
        <polygon points="-0.1,31 7.8,31 7.8,18.9 22.6,18.9 22.6,12.6 7.8,12.6 7.8,6.3 23.8,6.3 23.8,0 -0.1,0 	"/>
        <path d="M69.2,9.4C69.2,2.2,63,0,55.8,0H42v31h7.8V18.6h3.9L61.1,31h9.4l-9.1-13.1C66.5,17.1,69.2,13.8,69.2,9.4z M54.4,12.8h-4.6
                    v-7h5.1c2.9,0,6.1,0.5,6.1,3.6C61,12.8,57.3,12.8,54.4,12.8z"/>
        <polygon points="97.1,18.4 112.6,18.4 112.6,12.1 97.1,12.1 97.1,6.3 113.5,6.3 113.5,0 89.3,0 89.3,31 114.4,31 114.4,24.7 
                    97.1,24.7 	"/>
        <polygon points="146.4,18.4 161.8,18.4 161.8,12.1 146.4,12.1 146.4,6.3 162.7,6.3 162.7,0 138.5,0 138.5,31 163.6,31 163.6,24.7 
                    146.4,24.7 	"/>
        <polygon points="206.5,20.2 206.4,20.2 198.2,0 186.3,0 186.3,31 194.1,31 194.1,7.2 194.2,7.2 203.2,31 209.2,31 218.6,7.2 
                    218.7,7.2 218.7,31 226.6,31 226.6,0 214.7,0 	"/>
        <path d="M257.2,0l-15.4,31h8.7l3-6.6h13.8l3.1,6.6h8.9L263.7,0H257.2z M255.9,18.7l4.3-9.6l4.4,9.6H255.9z"/>
      </g>
    </svg>
  );
};

export default freemanLogoIcon;
