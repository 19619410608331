import React from "react";
import PropTypes from "prop-types";

const Icon = (props) => {
  return (
    <img src={props.src} className={props.classes} alt={props.alt || ""} {...props} />
  );
};

Icon.propTypes = {
  classes: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default Icon;