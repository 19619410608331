import React, { Component } from "react";

import emptyStateLight from "assets/images/graphicEmptyStateLight.svg";
import emptyStateDark from "assets/images/graphicEmptyStateDark.svg";
import "./empty_state.css";

class EmptyState extends Component {

  render() {
    return (
      <div className="container-empty-state">
        <img className="image-empty-state" src={this.props.theme === "light" ? emptyStateDark : emptyStateLight}
          alt={this.props.text || `No ${this.props.type} have been added`} />
        <p className={`text-empty-state ${this.props.theme}`}>
          {this.props.text || `No ${this.props.type} have been added.`}
        </p>
        {
          this.props.includeSupportEmail ?
            <p className="support-empty-state">
              <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className="no-permissions-link">{process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </p>
            : null
        }
      </div>
    );
  };
}

export default EmptyState;