import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import EventProfileList from "./profile_topbar/event_profile_list";
import ProfileHeader from "./header/profile_header";
import withAuth from "components/hoc/with_auth";
import EventDashboard from "./event_dashboard";
import ProfileDashboardTabs from "./header/profile_dashboard_tabs";
import { getEventById } from "actions/events";
import {
  getDefaultEventProfile,
  getEventProfileById,
  getEventProfiles
} from "actions/event_profiles";
import { setSideBarVisibility } from "actions/sidebar";
import { changeEventDashboardStatus } from "actions/event_dashboard";
import DefaultEventAvatar from "assets/images/avatarEvent.png";
import EmptyState from "components/empty_state";

import "./profile.css";

class Profile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      userEventAccess: false
    };
  }

  async componentDidMount() {
    if (this.isEventAccessible()) {
      await this.loadEvent();
      await this.loadProfiles();
      await this.loadProfile();
      this.setState({ userEventAccess: true });
      this.props.setSideBarVisibility(false);
    } else {
      this.setState({ userEventAccess: false });
      this.props.setSideBarVisibility(true);
    }
    this.setState({ loaded: true });
  }

  isEventAccessible() {
    const { match, activeUser } = this.props;
    const { fuzion_event_id } = match.params;
    if (activeUser &&
      ((Array.isArray(activeUser.events) && activeUser.events.includes(fuzion_event_id)) ||
        (activeUser.security_role_name === "QUANT_ADMIN"))) {
      return true;
    } else return false;
  }

  async loadEvent() {
    const { getEventById, match } = this.props;
    const { fuzion_event_id } = match.params;
    await getEventById(fuzion_event_id);
  }

  async loadProfiles() {
    const { fuzion_event_id } = this.props.match.params;
    await this.props.getEventProfiles(fuzion_event_id);
  }

  async loadProfile() {
    let { fuzion_event_id, quant_event_profile_id } = this.props.match.params;
    if (!quant_event_profile_id) {
      await this.getDefaultProfile();
    } else {
      await this.props.getEventProfileById(fuzion_event_id, quant_event_profile_id);
    }
    this.props.updateRoute();
  }

  async getDefaultProfile() {
    let { fuzion_event_id } = this.props.match.params;
    await this.props.getDefaultProfile(fuzion_event_id);
  }

  render() {
    const {
      fuzion_event_id,
      event_name,
      event_image_file,
      organization_name,
    } = this.props;

    const { userEventAccess } = this.state;

    if (!userEventAccess && this.state.loaded) {
      return (
        <div className="profile-container-noaccess-empty-state">
          <EmptyState text="Sorry, you do not have access to this event. Please contact support if you feel this is an error."
            includeSupportEmail={false}
            theme="light" />
        </div>);
    }
    if (!fuzion_event_id || !this.state.loaded) {
      return null;
    }
    return (
      <Fragment>
        <ProfileHeader eventId={fuzion_event_id} />
        <div className="profile-container">
          <div className="profile-subheader-container">
            <div className="profile-subheader">
              <img src={event_image_file || DefaultEventAvatar} className="profile-event-logo" alt="Event Logo" />
              <div className="profile-subheader-info">
                <h1 className="profile-title" >
                  {event_name}
                </h1>
                <div className="profile-sub-text" >
                  Organization: {organization_name}
                </div>
                <EventProfileList />
              </div>
            </div>
            <ProfileDashboardTabs />
          </div>

          <EventDashboard />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    fuzion_event_id,
    event_name,
    event_image_file,
    organization_name,
  } = state.event;

  return {
    fuzion_event_id,
    event_name,
    event_image_file,
    organization_name,
    eventProfiles: state.eventProfiles,
    quant_event_profile_id: state.eventProfile.quant_event_profile_id
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    getDefaultProfile: async (fuzion_event_id) => {
      if (!stateProps.eventProfiles || stateProps.eventProfiles.length === 0) {
        await dispatchProps.getDefaultEventProfile(fuzion_event_id);
        return;
      }
      const defaultProfile = stateProps.eventProfiles.find(x =>
        x.fuzion_event_id === fuzion_event_id &&
        x.is_default
      );
      if (defaultProfile) {
        await dispatchProps.getEventProfileById(fuzion_event_id, defaultProfile.quant_event_profile_id);
      } else {
        await dispatchProps.getDefaultEventProfile(fuzion_event_id);
      }
    },
    updateRoute: () => {
      ownProps.history.replace(`/events/profile/${stateProps.fuzion_event_id}/post-event/${stateProps.quant_event_profile_id}`);
    }
  };
};

export default connect(mapStateToProps, {
  getEventById,
  changeEventDashboardStatus,
  getDefaultEventProfile,
  getEventProfileById,
  getEventProfiles,
  setSideBarVisibility
},
mergeProps
)(withRouter(withAuth(Profile)));