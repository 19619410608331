import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import TabList from "components/tabs/tablist";
import TabEventProfiles from "components/tabs/tab_event_profiles";
import TabEventDocuments from "components/tabs/tab_event_documents";
import TabEventSettings from "components/tabs/tab_event_settings";
import { getEventById } from "actions/events";
import EventSettingsForm from "components/events/event_settings_form";
import Header from "components/header/header";
import withAuth from "components/hoc/with_auth";

class EventSettings extends Component {
  async componentDidMount() {
    const { fuzion_event_id } = this.props.match.params;
    await this.props.getEventById(fuzion_event_id);
  }
    
  render() {
    let { event } = this.props;
    const dashboardUrl  = `/events/profile/${event.fuzion_event_id}/pre-event`;
    return (
      <div>
        <Header detailView={true} to="/events" />
        <TabList>
          <TabEventProfiles event={event} />
          <TabEventDocuments event={event} />
          <TabEventSettings event={event} isActiveTab="true" />
        </TabList>
        <div className="container-fluid tabs-spacer">
          <h1 className="page-header">Event Settings</h1>
          <div className="sub-header">
            {event.organization_name + ": " + event.event_name}
          </div>
          <a href={dashboardUrl} className="btn btn-default-alt">VIEW EVENT</a>                   
          <div className="body-content-partial">
            {Object.keys(event).length > 0 && <EventSettingsForm key={event.fuzion_event_id}  event={event} />}
          </div>
        </div>
      </div>
    );
  }    
}

const mapStateToProps = state => {
  return {
    event: state.event
  };
};

export default connect(mapStateToProps, {
  getEventById
})(withRouter(withAuth(EventSettings)));