import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import renderClassNames from "lib/ui/render_class_names";

class AppContainer extends Component {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0,0);
    }
  }

  render() {
    const { sidebar, metricsMenuIsOpen, settingsMenuIsOpen } = this.props;
    const containerClass = renderClassNames({
      "container": true,
      "container-open-sidebar": (sidebar && sidebar.isVisible) || metricsMenuIsOpen,
      "container-open-metric-settings": settingsMenuIsOpen
    });

    return (
      <div className="main-container">
        <div className={containerClass}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    metricsMenuIsOpen: state.metricsMenu,
    settingsMenuIsOpen: state.metricSettingsMenu
  };
};

export default connect(mapStateToProps)(withRouter(AppContainer));
