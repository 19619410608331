import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updateDefaultEventProfile, getEventProfileById } from "actions/event_profiles";
import { getEventProfilesAndEventProfileDefault } from "actions/event_profile_details";
import withTheme from "components/hoc/with_theme";
import RadioButton from "components/form/radio_buttons";
import Toggle from "components/form/toggle";
import { updateProfile } from "actions/active_user";

export class DefaultProfileSelectionForm extends Component {
  constructor() {
    super();
    this.state = {
      insightsToggleChecked: false
    };
  }

  componentDidMount() {
    this.loadData();
    this.getInsightsToggleState();
  }

  getInsightsToggleState() {
    const { activeUser } = this.props;
    const insightsToggleChecked = activeUser.insights_menu_visible === null ? false : activeUser.insights_menu_visible;
    this.setState({ insightsToggleChecked });
  }

  loadData() {
    let { eventId } = this.props;
    this.props.getEventProfilesAndEventProfileDefault(eventId);
  }

  onProfileOptionChange = e => {
    let { eventId, updateDefaultEventProfile, getEventProfileById } = this.props;
    let { fuzion_event_id, quant_event_profile_id } = this.props.match.params;

    this.setState({ defaultProfileId: e.target.value });
    const data = {
      fuzion_event_id: eventId,
      quant_event_profile_id: e.target.value
    };
    updateDefaultEventProfile(data, () => {
      this.loadData();
      getEventProfileById(fuzion_event_id, quant_event_profile_id);
    });
  }

  createRadioButton = eventProfile => (
    <RadioButton
      key={eventProfile.quant_event_profile_id}
      name="is_default"
      value={eventProfile.quant_event_profile_id}
      checked={eventProfile.is_default}
      label={eventProfile.profile_name}
      onChange={this.onProfileOptionChange}
    />
  );

  renderInsightsToggle = () => {
    return <Toggle name="background_enabled"
      label="Display Insights drawer"
      checked={this.state.insightsToggleChecked}
      onChange={this.onInsightsToggleChange}
    />;
  }

  onInsightsToggleChange = () => {
    const { activeUser, updateProfile } = this.props;
    const { insightsToggleChecked } = this.state;
    activeUser.insights_menu_visible = !insightsToggleChecked;
    updateProfile(activeUser);
    this.setState({ insightsToggleChecked: !insightsToggleChecked });
  }

  renderRadioGroupProfile = eventProfileDetails => (
    eventProfileDetails.map(this.createRadioButton)
  )

  render() { // Removing radio buttons at the request of JB, due to incorrect implementation
    return (
      <div>
        {/* <div className="field-label">
                    Default Profile
                </div>
                {eventProfiles ?
                    this.renderRadioGroupProfile(eventProfiles)
                    :
                    <div>...</div>
                } */}
        {this.renderInsightsToggle()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultProfile: state.eventProfileDetails.defaultProfile,
    activeUser: state.activeUser
  };
};

export default connect(mapStateToProps, {
  getEventProfilesAndEventProfileDefault,
  updateDefaultEventProfile,
  getEventProfileById,
  updateProfile
})(withRouter(withTheme(DefaultProfileSelectionForm)));