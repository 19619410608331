import React from "react";

import "assets/css/radio_button.css";

const radioButton = field => {
  const convertTrueToChecked = checkedField => {
    switch (checkedField) {
      case true:
        return "checked";
      case false:
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="radio-button-container">
      <label>
        <input className="radio-button" type="radio" name={field.name} value={field.value} onChange={field.onChange} checked={convertTrueToChecked(field.checked)} />
        <span className="radio-button-label">{field.label}</span>
      </label>
    </div>
  );
};

export default radioButton;
