import React, { Component } from "react";
import PropTypes from "prop-types";
import { tippy } from "@tippy.js/react";

import EmptyState from "components/empty_state";
import EventText from "./event_text";
import EventControls from "./event_controls";

class EventList extends Component {
  render() {
    let { events, hideEventControls } = this.props;
    if (!events) return null;
    let orgs = Object.getOwnPropertyNames(events);
    if (orgs.length < 1) return this.getEmptyState();
    return (
      <div className="list-group">
        {orgs.map((org, i) => {
          let orgEvents = events[org];
          return (
            <div key={i}>
              <div className="event-sub-header">Organization: {org}</div>
              {orgEvents && orgEvents.map((event, j) => {
                return (
                  <div key={j}>
                    <div className="list-item" onMouseLeave={() => tippy.hideAll()}>
                      <EventText item={event} />
                      {!hideEventControls && <EventControls item={event} />}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  getEmptyState() {
    let { eventsFiltered } = this.props;
    return <EmptyState type="events" text={eventsFiltered ? "None of your events match the search criteria." : ""} theme="light" />;
  }
}

EventList.propTypes = {
  events: PropTypes.object,
  hideEventControls: PropTypes.bool
};

export default EventList;

