import React from "react";

import renderClassNames from "lib/ui/render_class_names";

const input = field => {
  let { touched, error } = field.meta;
  let  inputClasses = renderClassNames({
    "form-control": true,
    "form-control-error": touched && error !== undefined
  });
  if (field.theme) {
    inputClasses = field.theme.title === "dark" ?
      renderClassNames({
        "form-control": true,
        "form-control-error": touched && error !== undefined,
        "dark-text": true
      })
      :
      renderClassNames({
        "form-control": true,
        "form-control-error": touched && error !== undefined,
        "light-text": true
      });
  };
    
  let styles = field.theme ? { 
    ...field.theme.modalInputBackgroundColor,
    ...field.theme.inputBackgroundColor,
    ...field.theme,
    ...field.theme.subTextActive, 
    ...field.theme.inputBorderColor
  } 
    : 
    { ...field.bgColor, ...field.theme };
  let textStyle = field.theme ? { color: field.theme.subTextColor } : "";
  return (
    <div>
      <input {...field.input} type={field.type || "text"} 
        placeholder={field.placeholder} style={styles} 
        className={inputClasses} 
        readOnly={field.readOnly} disabled={field.disabled} />
      { touched && error !== undefined ? <span className="text-danger"> Error: {error} </span> : ""  }
      {field.displaycharlength === "true" ? 
        <div className="pull-right textAreaCharCount" style={textStyle}>
          {field.input.value.length}/{field.totalLength}
        </div>
        : ""
      }
    </div>
  );
};

export default input;